import { useContext } from "react";

import { XSRFContext } from "../contexts/XSRFContext";

const useXSRF = () => {
  const context = useContext(XSRFContext);

  if (!context)
    throw new Error("XSRFContext must be placed within XSRFProvider");

  return context;
};

export default useXSRF;

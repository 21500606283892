import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import Activities from "../../components/resources/Activities";
import GoodDeals from "../../components/resources/GoodDeals";
import Training from "../../components/resources/Training";

function ResourcesView(props) {
  const { section } = useParams();
  const navigate = useNavigate();

  const renderSection = () => {
    const components = {
      activities: <Activities />,
      "good-deals": <GoodDeals />,
      training: <Training />,
    };
    if (!section || !components[section]) {
      navigate("/auth/404");
      return null;
    }

    return components[section];
  };

  return renderSection();
}

export default ResourcesView;

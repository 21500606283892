import { JOB_OFFERS_STATUS, MAP_DEFAULT_CONFIG } from "../../constants";

const SEARCH_STYLES = {
  primaryColor: "#3379B7",
};

/**
 * Returns error message
 * @param {String} componentId
 * @param {String} error
 * @returns {HtmlNode}
 */
const showError = (componentId, error) => {
  return (
    <div>
      Something went wrong with {componentId}
      <br />
      Error details
      <br />
      {error}
    </div>
  );
};

/**
 * Returns JobOffer Using Haversine formula
 * @param {String} status
 * @return {Object}
 */
const getJobOfferStatusProps = (status) => {
  if (typeof JOB_OFFERS_STATUS[status] === "undefined") {
    throw new Error(`[getJobOfferStatusProps] undefined status ${status}`);
  }
  return JOB_OFFERS_STATUS[status];
};

/**
 * Returns distance Using Haversine formula
 * https://www.geodatasource.com/developers/javascript
 * @param {Float} lat1 Latitude of point 1
 * @param {Float} lon1 Longitude of point 1
 * @param {Float} lat2 Latitude of point 2
 * @param {Float} lon2 Longitude of point 2
 * @param {String} unit M: miles (default) | K: kilometers | N: nautical miles
 * @return {Object}
 */

const getDistance = (lat1, lon1, lat2, lon2, unit = "M") => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

/**
 * Returns Default user GeoLocation
 * @return {Object}
 */
const getDefaultGeoLocation = () => {
  return {
    lat: MAP_DEFAULT_CONFIG.center.lat,
    lon: MAP_DEFAULT_CONFIG.center.lng,
  };
};

export {
  showError,
  getJobOfferStatusProps,
  getDistance,
  getDefaultGeoLocation,
  SEARCH_STYLES,
};

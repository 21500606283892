import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Box, Card, CardContent, IconButton, Typography } from "@mui/material/";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

//import MobileDatePicker from "@mui/lab/MobileDatePicker";

import ScheduleValidator from "./ScheduleValidator";
import TravelTimeValidator from "./TravelTimeValidator";
import DisponibilityValidator from "./DisponibilityValidator";
import Applied from "./Applied";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

import { scrollAppContentToTop } from "../../helpers/common.helpers";
import { getGmapGeocodingResult } from "../../helpers/maps.helpers";

function Apply(props) {
  const { jobOffer, onCancel } = props;
  //console.log("[Apply] jobOffer", jobOffer);
  const alert = useAlert();
  const { t } = useTranslation();
  const { user, employeeConnector } = useAuth();
  const [hasViewScrolledToTop, setHasViewScrolledToTop] = useState(false);
  const [conditionsMet, setConditionsMet] = useState(false);
  const [conditions, setConditions] = useState({
    schedule: false,
    dates: false,
    travelTime: false,
  });
  const [dates, setDates] = useState({ start_date: null, end_date: null });
  const [applied, setApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userTravelInfos, setUserTravelInfos] = useState(null);

  /**
   * Handle Cancel
   * @param {Object} event
   * @returns {void}
   */
  const handleCancelClick = () => {
    onCancel();
  };

  /**
   * Handle Apply
   * @param {Object} event
   * @returns {void}
   */
  const handleApply = async (event) => {
    console.log("[Apply] handleApply", jobOffer.id);

    event.preventDefault();

    setLoading(true);

    try {
      // we fetch geocoding API to get origin location coords
      let response = await employeeConnector.getGmapGeocoding(
        userTravelInfos?.origin
      );
      const { location } = getGmapGeocodingResult(response);
      console.log("[Apply] handleApply - location:", location);
      const device_infos = {
        userAgent:
          typeof navigator !== "undefined" ? navigator?.userAgent : null,
        mobile:
          typeof navigator !== "undefined"
            ? navigator?.userAgentData?.mobile
            : null,
      };

      const data = {
        ...dates,
        travel_infos: { ...userTravelInfos, ...{ origin_location: location } },
        device_infos,
      };
      console.log("[Apply] handleApply - data:", data);

      response = await employeeConnector.applyJobOffer(jobOffer.id, data);

      if (response && response.data) {
        user.jobOffers.applications = response.data;

        alert.success({
          message: t("alert.success.job-offer-applied", { jobOffer }),
        });

        setApplied(true);
        setLoading(false);
      } else {
        alert.error({ message: "invalid response !" });
        setLoading(false);
        console.error("[Apply] handleApply", "invalid response !");
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[Apply] handleApply", error);
    }
  };

  /**
   * Validate a condition
   * @returns {void}
   */
  const conditionValidate = useCallback(
    (update, value) => {
      console.log("[Apply] conditionValidate:", update, value, conditions);
      setConditions({ ...conditions, [update]: value });
    },
    [conditions]
  );

  useEffect(() => {
    //console.log("[Apply] useEffect");

    if (!hasViewScrolledToTop) {
      // scroll JobOffer to top on 1st render
      scrollAppContentToTop();

      setHasViewScrolledToTop(true);
    }

    if (
      conditions.schedule === true &&
      conditions.dates === true &&
      conditions.travelTime === true
    ) {
      if (!conditionsMet) setConditionsMet(true);
    } else setConditionsMet(false);
  }, [conditions, conditionsMet, hasViewScrolledToTop, conditionValidate]);

  const cardSx = {
    marginBottom: "1em",
  };

  return !applied ? (
    <>
      <header>
        <Box mb="1em" display="flex" className="view-title">
          <IconButton onClick={handleCancelClick}>
            <ArrowBackIcon />
          </IconButton>
          <Typography width="100%" variant="h3">
            {t("job-offer.validationStep.validation")}
          </Typography>
        </Box>
      </header>
      <Card sx={cardSx}>
        <CardContent>
          <ScheduleValidator
            offer={jobOffer}
            conditionValidate={conditionValidate}
          />
        </CardContent>
      </Card>
      <Card sx={cardSx}>
        <CardContent>
          <DisponibilityValidator
            offer={jobOffer}
            conditionValidate={conditionValidate}
            setDates={setDates}
          />
        </CardContent>
      </Card>
      <Card sx={cardSx}>
        <CardContent>
          <TravelTimeValidator
            offer={jobOffer}
            conditionValidate={conditionValidate}
            setUserTravelInfos={setUserTravelInfos}
          />
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="center">
        <LoadingButton
          // fullWidth
          variant="contained"
          color="primary"
          size="large"
          disabled={!conditionsMet || loading}
          loading={loading}
          // className="auth-button"
          onClick={handleApply}
        >
          {t("job-offer.validationStep.validateApply")}
        </LoadingButton>
      </Box>
    </>
  ) : (
    <Applied />
  );
}

export default Apply;

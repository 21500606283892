import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { useTranslation } from "react-i18next";

const boxStyles = {
  display: "flex",
  position: "absolute",
  zIndex: 999,
  background: "#fff",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  opacity: "0.7",
};

const loaderStyles = {
  textAlign: "center",
  position: "absolute",
  background: "#eee",
  padding: "20px",
  borderRadius: "5px",
  fontSize: "14px",
};

/**
 * Returns Loader Params depending client document height
 * cf: https://javascript.info/size-and-scroll
 * @param {String} scrollTargetEltId
 * @returns {Number}
 */
const getLoaderParams = (scrollTargetEltId = null) => {
  const elt = document.getElementById(scrollTargetEltId)
    ? document.getElementById(scrollTargetEltId)
    : document.body;
  const html = document.documentElement;

  const height = Math.max(
    elt.scrollHeight,
    elt.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  const top = document.getElementById(scrollTargetEltId)
    ? document.getElementById(scrollTargetEltId).scrollTop
    : document.documentElement.scrollTop;

  return {
    height,
    top: top + 200,
  };
};

/**
 * Returns Loader
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function Loader(props) {
  const { t } = useTranslation();
  const { show, scrollTargetEltId, loadingText } = props;

  const [documentHeight, setDocumentHeight] = useState("100%");
  const [loaderTopPosition, setLoaderTopPosition] = useState("200px");

  useEffect(() => {
    if (show) {
      const loaderParams = getLoaderParams(scrollTargetEltId);
      const newDocumentHeight = loaderParams.height;
      const newLoaderTopPosition = loaderParams.top;
      // console.log("[Loader] - newDocumentHeight:", newDocumentHeight);
      // console.log("[Loader] - newLoaderTopPosition:", newLoaderTopPosition);

      setDocumentHeight(newDocumentHeight);
      setLoaderTopPosition(newLoaderTopPosition);
    }
  }, [scrollTargetEltId, show]);

  if (!show) {
    return null;
  }
  return (
    <Box sx={{ ...boxStyles, ...{ height: documentHeight } }}>
      <div
        style={{
          ...loaderStyles,
          ...{ top: `${loaderTopPosition}px` },
        }}
      >
        <CircularProgress />
        <p>{loadingText || t("search-job.search-data-loading")}</p>
      </div>
    </Box>
  );
}

const detectMask = async (iban, mask, setMask, codeList) => {
  if (iban.length < 2) return false;
  const code = iban.substring(0, 2);

  for (const country of codeList) {
    if (code === country.code) {
      if (!mask || mask !== country.mask) {
        await setMask(country.mask);
      }
      return true;
    }
  }
  return false;
};

const clearIban = (iban) => {
  if (!iban) {
    return "";
  }
  return iban
    .replace(/\s\s+/g, " ")
    .replace(/[^0-9a-zA-Z]/gi, "")
    .toUpperCase();
};

const formatIban = (iban, mask) => {
  if (!iban) {
    return "";
  }
  const cleanIban = clearIban(iban);

  const parts = [];
  parts.push(cleanIban.substring(0, 2));
  let previous = 2;

  for (const num of mask) {
    if (cleanIban.length > previous) {
      parts.push(cleanIban.substring(previous, previous + parseInt(num)));
      previous += parseInt(num);
    }
  }

  return parts.join(" ");
};

export { detectMask, clearIban, formatIban };

import { MenuItem } from "@mui/material";

const getYearsFrom = (start) => {
  const currentYear = new Date().getFullYear();
  const result = [];

  for (let year = start; year < currentYear + 1; year++) {
    result.push(
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    );
  }

  return result;
};

const getMonthsList = (t) => {
  return [
    <MenuItem key={1} value={1}>
      {t("user-profile.experience.January")}
    </MenuItem>,
    <MenuItem key={2} value={2}>
      {t("user-profile.experience.February")}
    </MenuItem>,
    <MenuItem key={3} value={3}>
      {t("user-profile.experience.March")}
    </MenuItem>,
    <MenuItem key={4} value={4}>
      {t("user-profile.experience.April")}
    </MenuItem>,
    <MenuItem key={5} value={5}>
      {t("user-profile.experience.May")}
    </MenuItem>,
    <MenuItem key={6} value={6}>
      {t("user-profile.experience.June")}
    </MenuItem>,
    <MenuItem key={7} value={7}>
      {t("user-profile.experience.July")}
    </MenuItem>,
    <MenuItem key={8} value={8}>
      {t("user-profile.experience.August")}
    </MenuItem>,
    <MenuItem key={9} value={9}>
      {t("user-profile.experience.September")}
    </MenuItem>,
    <MenuItem key={10} value={10}>
      {t("user-profile.experience.October")}
    </MenuItem>,
    <MenuItem key={11} value={11}>
      {t("user-profile.experience.November")}
    </MenuItem>,
    <MenuItem key={12} value={12}>
      {t("user-profile.experience.December")}
    </MenuItem>,
  ];
};

export { getMonthsList, getYearsFrom };

import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { spacing } from "@mui/system";
// import { green } from "@mui/material/colors";

import {
  Box as MuiBox,
  // Chip,
  Drawer as MuiDrawer,
  ListItemButton,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

// import { ReactComponent as Logo } from "../../vendor/momji_logo_837x551.svg";
import { ReactComponent as Logo } from "../../vendor/momji_logo_white.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";

import { appConfig } from "../../config";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.brand.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: 0px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  background-color: ${(props) => props.theme.sidebar.header.brand.background};
  width: 52px;
  height: 39px;
`;

// const BrandChip = styled(Chip)`
//   background-color: ${green[700]};
//   border-radius: 5px;
//   color: ${(props) => props.theme.palette.common.white};
//   font-size: 55%;
//   height: 18px;
//   margin-left: 2px;
//   margin-top: -16px;
//   padding: 3px 0;

//   span {
//     padding-left: ${(props) => props.theme.spacing(1.375)};
//     padding-right: ${(props) => props.theme.spacing(1.375)};
//   }
// `;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  // Set mobileDrawerToggle (only on mobile)
  const mobileDrawerToggleElt = document.getElementById(
    appConfig.mobileDrawerToggleEltId
  );

  /**
   * Trigger toggle menu
   * @param {Event} e
   * @returns {void}
   */
  const handleCloseMenu = () => {
    if (mobileDrawerToggleElt) {
      mobileDrawerToggleElt.click();
    }
  };

  const MobileCloseButton = () => {
    return (
      <IconButton onClick={handleCloseMenu} className="mobile-close-btn">
        <CloseIcon className="close-icon" />
      </IconButton>
    );
  };

  return (
    <Drawer variant="permanent" {...rest}>
      {mobileDrawerToggleElt && <MobileCloseButton />}
      <Brand component={NavLink} to="/" className="brand-icon">
        <BrandIcon /> <Box ml={1}>{}</Box>
      </Brand>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;

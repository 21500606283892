import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTranslation, Trans } from "react-i18next";

import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import TrainingSection from "./TraningSection";

import ImageCreativeEN from "../../statics/Creative_EN.png";
import ImageCreativeFR from "../../statics/Creative_FR.png";
import ImageImmersionEN from "../../statics/Immersion_EN.png";
import ImageImmersionFR from "../../statics/Immersion_FR.png";
import ImageInitiationEN from "../../statics/Initiation_EN.png";
import ImageInitiationFR from "../../statics/Initiation_FR.png";

const CustomButton = styled(Button)({
  backgroundColor: "#FED24C",
  color: "#2B4C96",
  "&:hover": {
    backgroundColor: "#EEC23C",
  },
  float: "right",
});

const sections = {
  CREATIVE: "creative",
  INITIATION: "initiation",
  IMMERSION: "immersion",
};

const cardSx = {
  marginBottom: "1em",
  paddingBottom: "1em",
  width: { xs: "100%", md: "33%" },
};

const Training = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [section, setSection] = useState(null);

  const handleGoToSection = (section) => {
    setSection(section);
  };

  const handleReturn = () => {
    setSection(null);
  };

  const renderSectionSelection = () => (
    <Box>
      <Box mb="2em">
        <Typography variant="h4">
          {t("resources.training.main.title")}
        </Typography>
      </Box>
      <Card>
        <CardContent>
          <Box>
            <Trans
              i18nKey="resources.training.main.text.1"
              components={{ bold: <strong /> }}
            />
          </Box>
          <p>
            <Trans
              i18nKey="resources.training.main.text.2"
              components={{ bold: <strong /> }}
            />
          </p>
          <Typography>{t("resources.training.main.text.3")}</Typography>
        </CardContent>
      </Card>
      <Box sx={{ display: { xs: "block", md: "flex" }, marginTop: "1em" }}>
        <Card sx={{ ...cardSx, marginRight: "1em" }}>
          <CardContent>
            <Box
              sx={{
                minHeight: "330px",
              }}
            >
              <Typography variant="h4">
                {t("resources.training.creative.title")}
              </Typography>
              <img
                src={language === "en" ? ImageCreativeEN : ImageCreativeFR}
                alt="Good-deals-visual"
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                onClick={() => handleGoToSection(sections.CREATIVE)}
              />
              <Typography>{t("resources.training.creative.text")}</Typography>
            </Box>

            <Box mt="1em">
              <CustomButton
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() => handleGoToSection(sections.CREATIVE)}
              >
                {t("resources.training.button")}
              </CustomButton>
            </Box>
          </CardContent>
        </Card>
        <Card sx={{ ...cardSx, marginRight: "1em" }}>
          <CardContent>
            <Box
              sx={{
                minHeight: "330px",
              }}
            >
              <Typography variant="h4">
                {t("resources.training.initiation.title")}
              </Typography>
              <img
                src={language === "en" ? ImageInitiationEN : ImageInitiationFR}
                alt="Good-deals-visual"
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                onClick={() => handleGoToSection(sections.INITIATION)}
              />
              <Typography>{t("resources.training.initiation.text")}</Typography>
            </Box>
            <Box mt="1em">
              <CustomButton
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() => handleGoToSection(sections.INITIATION)}
              >
                {t("resources.training.button")}
              </CustomButton>
            </Box>
          </CardContent>
        </Card>
        <Card sx={cardSx}>
          <CardContent>
            <Box
              sx={{
                minHeight: "330px",
              }}
            >
              <Typography variant="h4">
                {t("resources.training.immersion.title")}
              </Typography>
              <img
                src={language === "en" ? ImageImmersionEN : ImageImmersionFR}
                alt="Good-deals-visual"
                style={{ width: "100%", height: "100%", cursor: "pointer" }}
                onClick={() => handleGoToSection(sections.IMMERSION)}
              />
              <Typography>{t("resources.training.immersion.text")}</Typography>
            </Box>
            <Box mt="1em">
              <CustomButton
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() => handleGoToSection(sections.IMMERSION)}
              >
                {t("resources.training.button")}
              </CustomButton>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );

  return section ? (
    <TrainingSection section={section} onReturn={handleReturn} />
  ) : (
    renderSectionSelection()
  );
};

export default Training;

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import useSearchJob from "../../hooks/useSearchJob";

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);

const Applied = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentJobOfferQueryParams } = useSearchJob();

  /**
   * Handle return to job-offer list
   * @returns {void}
   */
  const handleReturnClick = () => {
    let parentView = "job-offers";

    // inject currentJobOfferQueryParams if not empty
    if (currentJobOfferQueryParams) {
      parentView = `${parentView}${currentJobOfferQueryParams}`;
    }

    navigate(`/${parentView}`);
  };

  return (
    <Box>
      <header>
        <Box mb="1em">
          <Typography width="100%" variant="h3" className="view-title">
            {t("job-offer.applied.title")}
          </Typography>
        </Box>
      </header>
      <Card>
        <CardContent>
          <Typography variant="h4">
            {t("job-offer.applied.congratulation")}
          </Typography>
          <Divider my={6} />
          <Box display="grid" justifyContent="center">
            <Typography mb="1em">
              {t("job-offer.applied.description")}
            </Typography>
            <Typography mb="2em">{t("job-offer.applied.tips")}</Typography>
            <Button onClick={handleReturnClick} variant="contained">
              {t("job-offer.applied.return")}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Applied;

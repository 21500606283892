import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import DownloadIcon from "@mui/icons-material/Download";

import useAuth from "../../hooks/useAuth";
import { appConfig } from "../../config.js";

import { getSamAuthHeaders } from "../../utils/sam.api.js";

import { getSamProxyXHRConfig } from "../../connectors/connector.helpers";

import { statuses, statusDisplay } from "./constants";
import Uploader from "../Uploader";

const dzStyle = {
  backgroundColor: "#376fd0",
  color: "#fff",
  height: "fit-content",
  width: "fit-content",
  padding: "0.5em 1em 0.5em 1em",
  borderRadius: "4px",
  display: "flex",
  cursor: "pointer",
  justifyContent: "center",
};

const Document = (props) => {
  const {
    document,
    onDocumentUpload,
    onDocumentUploadSuccess,
    onDocumentUploadFailed,
    onDocumentDownload,
    onDocumentDelete,
  } = props;
  const { t, i18n } = useTranslation();
  const { user, employeeConnector } = useAuth();
  const [loading, setLoading] = useState(false);

  const samAuthHeaders = getSamAuthHeaders(user);

  const xhrConfig = getSamProxyXHRConfig(samAuthHeaders);

  const language = i18n.language;
  const url = employeeConnector.getDocumentUploadUrl(document.id);

  const handleDocumentUpload = () => {
    setLoading("add");
    onDocumentUpload();
  };

  const handleDocumentUploadSuccess = async () => {
    await onDocumentUploadSuccess();
    setLoading(false);
  };

  const handleDocumentUploadFailed = async () => {
    await onDocumentUploadFailed();
    setLoading(false);
  };

  const handleDocumentDownload = async (document) => {
    setLoading("downloading");
    await onDocumentDownload(document);
    setLoading(false);
  };

  const handleDocumentDelete = async (document) => {
    setLoading("delete");
    await onDocumentDelete(document);
    setLoading(false);
  };

  const renderStatus = () => {
    return (
      <Box mb="1em">
        {statuses[document.status] === statuses.INIT ? (
          <Uploader
            url={url}
            id={document.id || ""}
            dzStyle={dzStyle}
            onInit={() => null}
            onDocumentUpload={handleDocumentUpload}
            onDocumentUploadSuccess={handleDocumentUploadSuccess}
            onDocumentUploadFailed={handleDocumentUploadFailed}
            xhrConfig={xhrConfig}
          />
        ) : (
          <Box display="flex" justifyContent="space-between">
            <Typography color={statusDisplay[statuses[document.status]].color}>
              {document.status_libelle}
            </Typography>
            <Box>
              <IconButton onClick={() => handleDocumentDownload(document)}>
                <DownloadIcon />
              </IconButton>
              {document.permissions.can_delete ? (
                <IconButton onClick={() => handleDocumentDelete(document)}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <IconButton disableRipple sx={{ cursor: "default" }}>
                  <LockIcon color="error" />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box mb="3em">
      <Box>
        <Box mb="1em">
          <Typography variant="h6">
            {document.document_type[`libelle_${language}`]}
          </Typography>
        </Box>
        <Box mb="1em">
          <Typography>{`${t(
            "career.documents.acceptedFormat"
          )}: ${appConfig.upload.allowedMimeTypes.join(", ")}`}</Typography>
          <Typography>{`${t("career.documents.maxSize")}: ${
            appConfig.upload.maxFileSize
          }Mo`}</Typography>
        </Box>
      </Box>

      {renderStatus()}
      {loading ? (
        <Box mt="1em" display="flex">
          <Box mr="2em">
            <CircularProgress />
          </Box>
          <Typography pt="0.5em">{t(`loader.${loading}`)}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default Document;

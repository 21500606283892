import { LOCALSTORAGE_ITEM_KEYS } from "../constants";

export const useAuthenticatedUser = () => {
  const user = readUser();

  return user;
};

const readUser = () => {
  try {
    const userAsString = window.localStorage.getItem(
      LOCALSTORAGE_ITEM_KEYS.USER
    );

    if (userAsString === null) return undefined;

    return JSON.parse(userAsString);
  } catch (error) {
    return undefined;
  }
};

import { appConfig } from "../config";

/**
 * Returns error logs
 * @param {String} error
 * @param {String} method
 * @returns {String}
 */
const logError = (error, method) => {
  if (error.response) {
    console.error(`[${method}] Error response data:`, error.response.data);
    console.error(`[${method}] Error response status:`, error.response.status);
    console.error(
      `[${method}] Error response headers:`,
      error.response.headers
    );
  } else if (error.request) {
    console.error(`[${method}] Error request:`, error.request);
  } else {
    console.error(`[${method}] Error message:`, error.message);
  }
  console.error(`[${method}] Error config:`, error.config);
};

/**
 * Returns XHR Config headers, credentials ...
 * @param {Object} token
 * @param {String} language
 * @returns {Object}
 */
// const getApiXHRConfig = (token, language = appConfig.defaultLanguage) => {
//   if (!token || !token.type || !token.value) {
//     throw new Error("[getApiXHRConfig] undefined token ...");
//   }
//   const config = {
//     headers: {
//       "Content-type": "application/json",
//       "x-language": language,
//     },
//     withCredentials: true,
//     crossDomain: true,
//   };
//   config.headers[token.type] = token.value;

//   return config;
// };

/**
 * Returns XHR Config headers
 * @param {Object} additionalHeaders
 * @param {String} language
 * @returns {Object}
 */
const getSamProxyXHRConfig = (
  additionalHeaders = {},
  language = appConfig.defaultLanguage
) => {
  const config = {
    headers: {
      ...additionalHeaders,
      "Content-type": "application/json",
      language: language,
      cachePolicy: "no-cache",
    },
    withCredentials: true,
    crossDomain: true,
  };

  return config;
};

/**
 * Transforms Y-m-d date format to API format
 * @param {String} dateStr
 * @param {String} format (d/m/Y, Ymd, Y-m-d)
 * @returns {String}
 */
function dateToSamAPI(dateStr, format = "d/m/Y") {
  if (!dateStr) {
    return undefined;
  }
  const date = new Date(dateStr);

  switch (format) {
    case "Ymd":
    case "Y-m-d": {
      const month = date.getMonth() + 1;
      const m = month > 9 ? month : `0${month}`;

      const day = date.getDate();
      const d = day > 9 ? day : `0${day}`;

      if (format === "Y-m-d") {
        return `${date.getFullYear()}-${m}-${d}`;
      }
      return `${date.getFullYear()}${m}${d}`;
    }

    default:
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }
}

export {
  logError,
  // getApiXHRConfig,
  getSamProxyXHRConfig,
  dateToSamAPI,
};

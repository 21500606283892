import * as React from "react";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { appConfig } from "../../config";

import useAuth from "../../hooks/useAuth";

import { isActionAuthorized, getStoredUser } from "../../helpers/auth.helpers";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  console.log("[AuthGuard] isAuthenticated:", isAuthenticated);

  // NB: AuthGuard is called before useAuth init - isAuthenticated provider property will always returns false ....
  const user = getStoredUser(); // TODO : use state.user ...
  // console.log("[AuthGuard] - getStoredUser:", user);

  const location = useLocation();
  console.log("[AuthGuard] location:", location);

  let isUserAuthenticated = isAuthenticated; // default 'SPA' navigation
  if (user && user.rememberMe) {
    // if user tries to access direct page, we test if auth is still ok
    isUserAuthenticated = user.isAuthenticated;
  }
  // console.log("[AuthGuard] isUserAuthenticated:", isUserAuthenticated);

  if (!isUserAuthenticated || !user) {
    console.log("[AuthGuard] not authenticated > redirect to sign-out ...");

    // redirect to sign-out
    return <Navigate to={`/auth/sign-out`} />;
  } else {
    // We check if user has imperative actions to do
    console.log(
      "[AuthGuard] authenticated > checking imperative actions ...",
      location.pathname
    );

    if (
      Array.isArray(user?.blockingActions) &&
      user?.blockingActions.length &&
      location.pathname !== "/mandatory-action" &&
      location.pathname !== "/500" &&
      !appConfig.userBlockingActionsByPass
    ) {
      return <Navigate to={`/mandatory-action`} />;
    } else {
      // We check the user permissions

      let isReadActionAuthorized = false;

      if (location.pathname === "/") {
        // home authorized
        isReadActionAuthorized = true;
      } else {
        isReadActionAuthorized = isActionAuthorized(
          `${location.pathname}`,
          "GET",
          user.permissions,
          appConfig.jwtByPass
        );
        console.log(
          "[AuthGuard] isReadActionAuthorized:",
          isReadActionAuthorized
        );

        if (!isReadActionAuthorized) {
          // redirect to home
          return <Navigate to={`/`} />;
        }
      }
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;

import React from "react";
import { useTranslation } from "react-i18next";

import ImageEN from "../../statics/RAF_EN.png";
import ImageFR from "../../statics/RAF_FR.png";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

import { Box, Button, Card, CardContent, Typography } from "@mui/material";

const RAF = (props) => {
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const language = i18n.language;

  const bold = { fontWeight: "bold" };

  const handleCopyLink = async () => {
    console.log("[RAF] handleCopyLink");
    try {
      await navigator.clipboard.writeText(user.refer);
      alert.success({ message: t("alert.success.to-clipboard") });
    } catch (error) {
      alert.error({ message: error });
      console.error("[RAF] handleCopyLink", error);
    }
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Box>
            <Typography mb="1em">
              <span style={bold}>{t("refer-a-friend.description.1.bold")}</span>
              {t("refer-a-friend.description.1.normal")}
              <span style={bold}>{t("refer-a-friend.description.2.bold")}</span>
              {t("refer-a-friend.description.2.normal")}
            </Typography>
            <Typography>{t("refer-a-friend.description.3.normal")}</Typography>
            <Box mb="1em">
              <img
                src={language === "en" ? ImageEN : ImageFR}
                alt="RAF-visual"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Typography mb="1em">{t("refer-a-friend.share")}</Typography>
            <Box display="grid" justifyContent="center">
              <Button onClick={handleCopyLink} variant="contained">
                {t("refer-a-friend.copy-link")}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RAF;

import React, { useState, forwardRef } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { rgba, darken } from "polished";

import { Chip, Collapse, ListItemButton, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";

import { appConfig } from "../../config";

// import { isMobileDevice } from "../../helpers/common.helpers";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const Item = styled(ListItemButton)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.8;
  }
  &:hover {
    background: ${(props) => props.theme.sidebar.hover};
    color: ${(props) => props.theme.sidebar.color};
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) => darken(0.03, props.theme.sidebar.hover)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarNavListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props;

  const [open, setOpen] = useState(openProp);

  // quick fix bug state open !== openProp when useNavigate()
  if (openProp && !open) {
    setOpen(openProp);
  }

  const mobileDrawerToggleElt = document.getElementById(
    appConfig.mobileDrawerToggleEltId
  );

  const navigate = useNavigate();

  /**
   * Sub menu toggle
   */
  const handleToggle = () => {
    setOpen((state) => !state);
  };

  /**
   * Override native navigation (mobile / desktop)
   * @param {Event} e
   * @returns {void}
   */
  const handleSidebarNavListItemClick = (e) => {
    // console.log(
    //   "[SidebarNavListItem] handleSidebarNavListItemClick",
    //   e,
    //   mobileDrawerToggleElt
    // );

    // Desktop
    if (!mobileDrawerToggleElt) {
      // native behaviour > OK
    } else {
      // Mobile: we redirect manually and close the menu drawer (NB : sometimes href not found appears ...)
      e.preventDefault();

      setTimeout(() => {
        // get current href
        const targetElt = e.target;
        const linkElt = targetElt.parentElement.parentElement;
        const href = linkElt?.href;

        if (!href) {
          console.error(
            "[SidebarNavListItem] handleSidebarNavListItemClick - href not found",
            linkElt,
            href
          );
          return;
        }
        const url = new URL(href);

        // we remove basePath from url.pathname
        const pathname = url.pathname;
        const path = pathname.replace(`/${appConfig.basePath}`, "");

        // force toggle menu
        mobileDrawerToggleElt.click();

        // redirect
        navigate(path);
      }, 300);
    }
  };

  if (children) {
    return (
      <React.Fragment>
        <Item depth={depth} onClick={handleToggle}>
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
        onClick={(e) => handleSidebarNavListItemClick(e)}
      >
        {Icon && <Icon />}
        <Title depth={depth}>
          {title}
          {badge && <Badge label={badge} />}
        </Title>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;

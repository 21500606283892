import React from "react";
import styled from "styled-components/macro";

import {
  ReactiveGoogleMap,
  // GeoDistanceSlider,
  // ReactiveOpenStreetMap
} from "@appbaseio/reactivemaps";
import { Typography, Button as MuiButton, Box } from "@mui/material/";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { MAP_DEFAULT_CONFIG } from "../../constants";
import { getFilters } from "./filters/filters.helpers";
import { getOfferTranslatedProp } from "../../helpers/translations.helpers";

import { apiConfig } from "../../config";

import useSearchJob from "../../hooks/useSearchJob";

const Button = styled(MuiButton)(spacing);

// To render the given text in the marker
// const handleRenderData = (result) => {
//   // console.log('[handleRenderData]', result);
//   return { label: result.titre_1_fr };
// };

// To render a marker image
// const renderData=(result) => {
//     return {icon: 'https://i.imgur.com/NHR2tYL.png'}
// };

// // To render a custom markup (as label) in the marker position
// const renderData=(result) => {
//     return {custom: (<div>{result.mag}</div>)}
// };

/**
 * Returns SearchMap
 * https://docs.appbase.io/docs/reactivesearch/v3/map/reactivegooglemap/
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function SearchMap(props) {
  const { center, zoom } = props;
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();
  const { updateCurrentJobOffer } = useSearchJob();
  console.log("[SearchMap] center:", center, "zoom:", zoom);

  /**
   * Redirect to jobOffer view
   * @param {Event} e
   * @param {Object} item
   * @return {void}
   */
  const handleRedirect = (e, item) => {
    const jobOfferId = item[apiConfig.samApi.jobOfferPublicKey];
    if (!jobOfferId) {
      throw new Error("[SearchMap] handleRedirect - jobOfferId not found !");
    }

    // update local JobOffer
    updateCurrentJobOffer(item);

    // redirect
    navigate(`/job-offers/${jobOfferId}`);
  };

  /**
   * Get center coords
   * @param {Object} coords
   * @return {Object}
   */
  const getCenter = (coords) => {
    if (!coords) {
      return MAP_DEFAULT_CONFIG.center;
    }
    if (!coords.lat || !coords.lng) {
      return MAP_DEFAULT_CONFIG.center;
    }
    if (Number.isNaN(coords.lat) || Number.isNaN(coords.lng)) {
      return MAP_DEFAULT_CONFIG.center;
    }
    return coords;
  };

  /**
   * Handle OnPopoverClick
   * @param {Object} item
   * @return {void}
   */
  const handleOnPopoverClick = (item) => {
    item.location = { ...item.location, lon: item.location.lng };
    //console.log("[SearchMap] handleOnPopoverClick", item);

    return (
      <div className="map-popover">
        <Typography variant="h5">
          {getOfferTranslatedProp(item, "title", language)} -{" "}
          {item[apiConfig.samApi.jobOfferPublicKey]}
        </Typography>

        <Typography variant="h6" mt="0.5em">
          {t("job-offer.location")}
        </Typography>
        <Typography>{item.city}</Typography>
        <Box textAlign="center" mt="1em">
          <Button variant="contained" onClick={(e) => handleRedirect(e, item)}>
            {t("job-offer.see-detail")}
          </Button>
        </Box>
      </div>
    );
  };

  const mapProps = {
    componentId: "map",
    dataField: "location",
    title: "Location Map",
    size: 500,
    // defaultZoom: MAP_DEFAULT_CONFIG.zoom,
    zoom: { zoom },
    //defaultCenter: { lat: 48.864716, lng: 2.349014 }, // Paris, FR
    center: getCenter(center),
    showMapStyles: false,
    defaultMapStyle: "Standard",
    showMarkerClusters: true,
    showSearchAsMove: false,
    searchAsMove: false, // NB : if true : will scroll back to origin location  but will load locations ... !
    searchAsMoveLabel: t("job-offer.map.load-data"),
    autoCenter: false,
    // defaultRadius: 100,
    unit: "km",

    // 'react' defines when and how the map component should update
    react: {
      and: getFilters([]),
    },

    // -- Map Events

    // marker onclick
    onPopoverClick: handleOnPopoverClick,

    // override default markers renderData
    // renderData: handleRenderData
  };

  return (
    <>
      <ReactiveGoogleMap style={{ height: "50vh" }} {...mapProps} />
      {/* <ReactiveOpenStreetMap style={{ height: '90vh' }} {...mapProps} /> */}
    </>
  );
}

import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import useAuth from "../../hooks/useAuth";
import YoutubeEmbed from "./YoutubeEmbed";

const videos = {
  en: "0BOjiT0YI-s",
  fr: "aY3oFxcfvyE",
};

const PrepareInterviewMessage = (props) => {
  const { employeeConnector } = useAuth();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Get interview date of user
   * @returns {void}
   */
  const getDate = useCallback(async () => {
    try {
      setLoading(true);
      const response = await employeeConnector.getInterviewDate();

      if (response) {
        setInfo(response.data.results);
        setLoading(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("[PrepareInterviewMessage] getDate", error);
    }
  }, [employeeConnector]);

  useEffect(() => {
    if (info === null && employeeConnector && !loading) {
      getDate();
    }
  }, [info, employeeConnector, loading, getDate]);

  return (
    <Box>
      <Typography
        sx={{ minWidth: { xs: "60vw", lg: "550px" }, minHeight: "3em" }}
      >
        {info?.date ? t(`messages.PREP_ENT.text`, info) : null}
      </Typography>
      <Box mt="1em">
        <YoutubeEmbed key="video" embedId={videos[language]} />
      </Box>
    </Box>
  );
};

export default PrepareInterviewMessage;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";
import { getGmapDistanceResult } from "../../helpers/maps.helpers";

import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from "@mui/material/";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsSubwayFilledIcon from "@mui/icons-material/DirectionsSubwayFilled";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

const TRAVEL_MODES = {
  DRIVE: "driving",
  PUBLIC_TRANSPORT: "transit ",
  WALK: "walking",
  BIKE: "bicycling",
};

function TravelTime(props) {
  const { offer, calculationDone, onTravelInfosChange } = props;
  const { t } = useTranslation();
  const { employeeConnector } = useAuth();
  const alert = useAlert();
  const [travelMode, setTravelMode] = useState(TRAVEL_MODES.PUBLIC_TRANSPORT);
  const [userLocation, setUserLocation] = useState(null);
  const [travelInfos, setTravelInfos] = useState(null);
  const [loadingPrediction, setLoadingPrediction] = useState(false);
  const [predictionList, setPredictionList] = useState([]);

  /**
   * Handle input value changing
   * @param {Object} e
   * @param {string} value
   * @returns {void}
   */
  const handleInputChange = async (e, value) => {
    console.log(
      "[TravelTime] handleInputChange",
      value,
      loadingPrediction,
      offer.location
    );

    if (value && value !== "" && !loadingPrediction) {
      await setLoadingPrediction(true);

      try {
        const response = await employeeConnector.getGmapAutocompletion(
          value,
          offer.location
        );
        if (response) {
          setPredictionList(
            response.predictions.map((prediction) => {
              return {
                label: prediction.description,
                place_id: prediction.place_id,
              };
            })
          );
          setLoadingPrediction(false);
        }
      } catch (error) {
        alert.error({ message: parseResponseErrorMessage(error) });
        console.error("[TravelTime] handleInputChange", error);
      }
    }
  };

  /**
   * Handle autocomplete selected value changing
   * @param {Object} e
   * @param {string} value
   * @returns {void}
   */
  const handleAutocompleteChange = async (e, value) => {
    if (value && value.place_id) {
      try {
        const response = await employeeConnector.getGmapDistance(
          `place_id:${value.place_id}`,
          offer.location,
          travelMode
        );
        if (response) {
          const { travel, origin, destination } =
            getGmapDistanceResult(response);
          console.log(
            "[TravelTime] handleAutocompleteChange",
            travel,
            origin,
            destination
          );

          const newInfos = { ...travel, origin, destination, travelMode };
          setTravelInfos(newInfos);
          onTravelInfosChange(newInfos);
          if (calculationDone) calculationDone(true);
        }
      } catch (error) {
        alert.error({ message: parseResponseErrorMessage(error) });
        console.error("[TravelTime] handleAutocompleteChange", error);
      }
      setUserLocation(value);
    }
  };

  /**
   * Handle travel mode value changing
   * @param {string} value
   * @returns {void}
   */
  const handleTravelModeChange = async (value) => {
    const newTravelMode =
      travelMode === value ? TRAVEL_MODES.PUBLIC_TRANSPORT : value;
    if (userLocation) {
      try {
        const response = await employeeConnector.getGmapDistance(
          `place_id:${userLocation.place_id}`,
          offer.location,
          newTravelMode
        );
        const { travel, origin, destination } = getGmapDistanceResult(response);
        console.log(
          "[TravelTime] handleTravelModeChange",
          travel,
          origin,
          destination
        );

        const newInfos = {
          ...travel,
          origin,
          destination,
          travelMode: newTravelMode,
        };

        setTravelInfos(newInfos);
        onTravelInfosChange(newInfos);
      } catch (error) {
        alert.error({ message: parseResponseErrorMessage(error) });
        console.error("[TravelTime] handleTravelModeChange", error);
      }
    }
    setTravelMode(newTravelMode);
  };

  return (
    <>
      <Box mt="2em" display="flex" justifyContent="center">
        <IconButton
          color={
            travelMode === TRAVEL_MODES.PUBLIC_TRANSPORT ? "primary" : "default"
          }
          aria-label={t("travelTime.public_transport")}
          onClick={() => handleTravelModeChange(TRAVEL_MODES.PUBLIC_TRANSPORT)}
        >
          <DirectionsSubwayFilledIcon />
        </IconButton>
        <IconButton
          color={travelMode === TRAVEL_MODES.DRIVE ? "primary" : "default"}
          aria-label={t("travelTime.car")}
          onClick={() => handleTravelModeChange(TRAVEL_MODES.DRIVE)}
        >
          <DirectionsCarIcon />
        </IconButton>

        <IconButton
          color={travelMode === TRAVEL_MODES.WALK ? "primary" : "default"}
          aria-label={t("travelTime.walk")}
          onClick={() => handleTravelModeChange(TRAVEL_MODES.WALK)}
        >
          <DirectionsWalkIcon />
        </IconButton>
        <IconButton
          color={travelMode === TRAVEL_MODES.BIKE ? "primary" : "default"}
          aria-label={t("travelTime.bike")}
          onClick={() => handleTravelModeChange(TRAVEL_MODES.BIKE)}
        >
          <DirectionsBikeIcon />
        </IconButton>
      </Box>
      <Box mt="1em" display="grid" justifyContent="center">
        <Box display="grid" justifyContent="center">
          <Autocomplete
            sx={{ width: "20em" }}
            options={predictionList}
            autoSelect
            onInputChange={handleInputChange}
            onChange={handleAutocompleteChange}
            isOptionEqualToValue={(option, value) =>
              typeof value === "string"
                ? option.label.includes(value)
                : option.label === value.label
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("travelTime.startingPointQuestion")}
              />
            )}
          />
        </Box>

        {travelInfos?.duration?.text ? (
          <Box mb="3em" mt="1em">
            <Typography>
              {t("travelTime.duration")} : {travelInfos.duration.text}
            </Typography>
            <Typography>
              {t("travelTime.distance")} : {travelInfos.distance.text}
            </Typography>
            <Typography mt="1em">
              <a
                href={`https://www.google.com/maps/dir/?api=1&origin=${encodeURI(
                  travelInfos.origin
                )}&destination=${offer.location.lat},${
                  offer.location.lon
                }&travelmode=${travelMode}`}
                target="_blank"
                rel="noreferrer"
              >
                {t("travelTime.seeDirection")}
              </a>
            </Typography>
          </Box>
        ) : (
          <Typography mb="1em" mt="1.5em">
            {t("travelTime.enterStartingPoint")}
          </Typography>
        )}
      </Box>
    </>
  );
}

export default TravelTime;

import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { Box, List, ListItem, Typography } from "@mui/material";

const EngagementText = (props) => {
  const { t } = useTranslation();
  const bold = { fontWeight: "bold" };

  return (
    <>
      <Typography mb="1em">{t("career.engagement.text.intro")}</Typography>
      <Box ml="1em">
        <p>
          <Trans
            i18nKey="career.engagement.text.1"
            components={{ bold: <strong /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="career.engagement.text.2"
            components={{ bold: <strong /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="career.engagement.text.3"
            components={{ bold: <strong /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="career.engagement.text.4"
            components={{ bold: <strong /> }}
          />
        </p>
        <p style={{ marginBottom: "0" }}>
          <Trans
            i18nKey="career.engagement.text.5"
            components={{ bold: <strong /> }}
          />
        </p>
        <List dense mb="1em">
          <ListItem>{t("career.engagement.text.lists.1")}</ListItem>
          <ListItem>{t("career.engagement.text.lists.2")}</ListItem>
          <ListItem>{t("career.engagement.text.lists.3")}</ListItem>
        </List>
        <p style={{ marginBottom: "0" }}>
          <Trans
            i18nKey="career.engagement.text.6"
            components={{ bold: <strong /> }}
          />
        </p>
        <List dense mb="1em">
          <ListItem>{t("career.engagement.text.lists.4")}</ListItem>
          <ListItem>{t("career.engagement.text.lists.5")}</ListItem>
          <ListItem sx={{ display: "block" }}>
            <Trans
              i18nKey="career.engagement.text.lists.6"
              components={{ bold: <strong /> }}
            />
          </ListItem>
        </List>
        <p>
          <Trans
            i18nKey="career.engagement.text.7"
            components={{ bold: <strong /> }}
          />
        </p>
        <div>
          <Trans
            i18nKey="career.engagement.text.8"
            components={{ bold: <strong />, p: <p /> }}
          />
        </div>
        <p>
          <Trans
            i18nKey="career.engagement.text.9"
            components={{ bold: <strong /> }}
          />
        </p>
      </Box>
      <Typography>
        <span style={bold}>{t("career.engagement.text.confirm")}</span>
      </Typography>
    </>
  );
};

export default EngagementText;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import { Hidden, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";

import { useTranslation } from "react-i18next";

import { mapTranslations } from "../helpers/translations.helpers";

import { appConfig } from "../config";

import useAuth from "../hooks/useAuth";

import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
// import Footer from "../components/Footer";
import Settings from "../components/Settings";

import Notifications from "./Notifications";

// import { isMobileDevice } from "../helpers/common.helpers";
import { getUILanguage } from "../helpers/translations.helpers";

const drawerWidth = "258px";

// client height
const html = document.documentElement;

const height = Math.max(
  html.clientHeight,
  html.scrollHeight,
  html.offsetHeight
);

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth};
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: ${height - 20}px;
  overflow-y: auto;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up("md"));
  console.log("[Dashboard] isLgUp", isLgUp, "isDesktopScreen", isDesktopScreen);

  // Translations (NB: dashboardItems can't use useTranslation hook)
  const { t, i18n } = useTranslation();
  const { user } = useAuth();

  const dashboardTranslations = {
    "menu.home": t("menu.home"),
    "menu.career": t("menu.career"),
    "menu.job-offers": t("menu.job-offers"),

    "menu.my-offers": t("menu.my-offers.my-offers"),
    "menu.my-offers.applications": t("menu.my-offers.applications"),
    "menu.my-offers.bookmarks": t("menu.my-offers.bookmarks"),
    "menu.my-offers.pushes": t("menu.my-offers.pushes"),

    "menu.contracts.contracts": t("menu.contracts.contracts"),
    "menu.contracts.due": t("menu.contracts.due"),
    "menu.contracts.healthcare": t("menu.contracts.healthcare"),

    "menu.planning": t("menu.planning"),
    "menu.payslips": t("menu.payslips"),
    "menu.refer": t("menu.refer"),

    "menu.resources.resources": t("menu.resources.resources"),
    "menu.resources.activities": t("menu.resources.activities"),
    "menu.resources.good-deals": t("menu.resources.good-deals"),
    "menu.resources.training": t("menu.resources.training"),
  };
  const translatedDashboardItems = mapTranslations(
    dashboardItems,
    dashboardTranslations
  );
  // console.info(
  //   "[Dashboard] - translatedDashboardItems:",
  //   translatedDashboardItems
  // );

  // we set the default UI language (NB: called on every page reload)
  useEffect(() => {
    console.log("[Dashboard] useEffect - user:", user);

    const handleSetUILanguage = () => {
      if (user && user.language) {
        const language = getUILanguage(user.language);
        i18n.changeLanguage(language);
      }
    };
    handleSetUILanguage();
  }, [i18n, user]);

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden lgUp implementation="js">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={translatedDashboardItems}
            showFooter={false}
            className="mobile-sidebar"
          />
        </Hidden>
        <Hidden mdDown implementation="css">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={translatedDashboardItems}
            showFooter={false}
          />
        </Hidden>
      </Drawer>
      <AppContent
        id={appConfig.reactiveListScrollTargetEltId}
        className={`app-content-${isDesktopScreen ? "desktop" : "mobile"}`}
      >
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 12 : 5} className="main-content">
          {children}
          <Outlet />
        </MainContent>
        {/* <Footer /> */}
      </AppContent>
      <Settings />
      <Notifications />
    </Root>
  );
};

export default Dashboard;

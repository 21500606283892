import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LoadingButton } from "@mui/lab";

import { getHoursList, getMinutesList } from "./helper-planning";

const codeRegex = /^[A-Z0-9]*$/;

const Coupon = (props) => {
  const { onClose } = props;
  const { t, i18n } = useTranslation();
  const { employeeConnector } = useAuth();
  const alert = useAlert();
  const { language } = i18n;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const formik = useFormik({
    initialValues: {
      date: "",
      hour: "00",
      minute: "00",
      code: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validate: (values) => {
      const errors = {};

      if (!values.date) {
        errors.date = t("form.errors.required.field");
      }
      if (!values.hour) {
        errors.hour = t("form.errors.required.field");
      }
      if (!values.minute) {
        errors.minute = t("form.errors.required.field");
      }
      if (!values.code) {
        errors.code = t("form.errors.required.field");
      }

      if (
        values.code &&
        (values.code.length < 8 ||
          values.code.length > 8 ||
          !codeRegex.test(values.code))
      ) {
        errors.code = t("form.errors.invalid.code");
      }

      return errors;
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);

  const selectSx = {
    minWidth: fullScreen ? "5em" : "8em",
  };

  const today = new Date();
  const maxDate = today;
  maxDate.setDate(today.getDate() - 1);
  const minDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  //console.log("[Coupon] min and max dates:", minDate, maxDate);

  /**
   * Handle datepicker value changing
   * @param {Date} value
   */
  const handleDateChange = (value) => {
    formik.setFieldValue("date", value);
    setDate(value);
  };

  const handleSubmit = async (values) => {
    const data = {
      start_hour: `${values.hour}${values.minute}`,
      start_date: values.date,
      code: values.code,
    };
    console.log("[Coupon] handleSubmit", values, data);

    setLoading(true);
    try {
      const result = await employeeConnector.putCoupon(data);
      if (result) {
        setLoading(false);
        const success = result.data.results.status;
        if (!success) {
          const { message } = result.data.results;
          console.log("[Coupon] putCoupon", message);
          alert.error({ message });
        } else {
          alert.success({ message: t("alert.success.add") });
          onClose();
        }
      }
    } catch (error) {
      setLoading(false);
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[Coupon] putCoupon", error);
    }
  };

  const renderHoursList = () => {
    const list = getHoursList(0, 23);

    return list.map((hour) => (
      <MenuItem value={hour} key={hour}>
        {hour}
      </MenuItem>
    ));
  };

  const renderMinutesList = () => {
    const list = getMinutesList(15);

    return list.map((minute) => (
      <MenuItem value={minute} key={minute}>
        {minute}
      </MenuItem>
    ));
  };

  return (
    <Box>
      <DialogTitle display="flex" justifyContent="space-between">
        <Typography variant="h4">{t("planning.coupon.title")}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box mb="1em">
            <Typography mb="0.5em" variant="h6">
              {t("planning.coupon.date")}
            </Typography>
            <FormControl error={Boolean(formik.errors && formik.errors.date)}>
              <DatePicker
                id="date"
                name="date"
                value={date}
                maxDate={maxDate}
                minDate={minDate}
                inputFormat={language === "fr" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />

              <FormHelperText>{formik.errors.date}</FormHelperText>
            </FormControl>
          </Box>
          <Box mb="1em">
            <Typography mb="0.5em" variant="h6">
              {t("planning.coupon.time")}
            </Typography>

            <Box sx={{ display: "flex", marginBottom: "1em" }}>
              <Box>
                <FormControl
                  error={Boolean(formik.errors && formik.errors.hour)}
                >
                  <InputLabel id="coupon-hour-select-label">
                    {t("planning.coupon.hour")}
                  </InputLabel>
                  <Select
                    sx={{ ...selectSx, marginRight: "2em" }}
                    labelId="coupon-hour-select-label"
                    id="hour"
                    name="hour"
                    label={t("planning.coupon.hour")}
                    value={formik.values.hour}
                    onChange={formik.handleChange}
                  >
                    {renderHoursList()}
                  </Select>
                </FormControl>
                <FormHelperText error>{formik.errors.hour}</FormHelperText>
              </Box>
              <Box>
                <FormControl
                  error={Boolean(formik.errors && formik.errors.minute)}
                >
                  <InputLabel id="coupon-minute-select-label">
                    {t("planning.coupon.minute")}
                  </InputLabel>
                  <Select
                    sx={{ ...selectSx, marginRight: "2em" }}
                    labelId="coupon-minute-select-label"
                    id="minute"
                    name="minute"
                    label={t("planning.coupon.minute")}
                    value={formik.values.minute}
                    onChange={formik.handleChange}
                  >
                    {renderMinutesList()}
                  </Select>
                </FormControl>
                <FormHelperText error>{formik.errors.minute}</FormHelperText>
              </Box>
            </Box>
            <Typography mb="0.5em" variant="h6">
              {t("planning.coupon.code")}
            </Typography>
            <TextField
              id="code"
              name="code"
              defaultValue={formik.values.code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur("code")}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Box>
          <LoadingButton loading={loading} variant="contained" type="submit">
            {t("planning.coupon.submit")}
          </LoadingButton>
        </form>
      </DialogContent>
    </Box>
  );
};

export default Coupon;

import axios from "axios";
import { apiConfig } from "../../../config.js";
import { logError, dateToSamAPI } from "../../connector.helpers.js";

import { SEARCH_FILTER_COMPONENT_IDS } from "../../../components/SearchJob/filters/filters.helpers.js";

/**
 * EmployeeConnector
 * @param {Number} id
 * @param {Number} id
 * @returns Promise
 */
class EmployeeConnector {
  constructor(id, config) {
    id = Number(id);
    if (!id || isNaN(id)) {
      throw new Error(
        "[EmployeeConnector] id not found or wrong type (number required)!"
      );
    }
    this.id = id;
    this.config = config;
    this.url = apiConfig.samApi.url;

    this.entity = "employee";

    // console.log(`[EmployeeConnector] id: ${this.id}, config:`, this.config);
  }

  /**
   * Get employee id
   * @returns {Number}
   */
  getId = () => {
    return this.id;
  };

  /**
   * Get config
   * @returns {Object}
   */
  getConfig = () => {
    return this.config;
  };

  /**
   * PROFILE
   */

  /**
   * Get profile
   * @returns Promise
   */
  getProfile = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/profile`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getProfile]");
        throw error;
      });

    return result;
  };

  /**
   * Update profile
   * @param {Object} data
   * @returns {String}
   */
  updateProfile = async (data) => {
    const result = await axios({
      method: "put",
      url: `${this.url}/${this.entity}/${this.id}/profile`,
      data: { ...data },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - updateProfile]");
      throw error;
    });

    return result.data;
  };

  /**
   * Update OnBoarding
   * @param {Object} data
   * @returns {String}
   */
  updateOnBoarding = async (data) => {
    const result = await axios({
      method: "put",
      url: `${this.url}/${this.entity}/${this.id}/onboarding`,
      data: { ...data },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - updateOnBoarding]");
      throw error;
    });

    return result.data;
  };

  /**
   * Update Language
   * @param {Object} data
   * @returns {String}
   */
  updateLanguage = async (data) => {
    const result = await axios({
      method: "put",
      url: `${this.url}/${this.entity}/${this.id}/language`,
      data: { ...data },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - updateLanguage]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get PlanningInfo
   * @returns Promise
   */
  getPlanningInfo = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/planning-info`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getPlanningInfo]");
        throw error;
      });

    return result;
  };

  /**
   * Get Planning
   * @returns Promise
   */
  getPlanning = async ({ startDate, endDate }) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/interventions?startDate=${startDate}&endDate=${endDate}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getPlanning]");
        throw error;
      });

    return result.data;
  };

  /**
   * DOCUMENTS
   */

  /**
   * Get Documents
   * @returns {String}
   */
  getDocuments = async () => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/documents/exclude-archived`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getDocuments]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get Documents by group
   * @returns {String}
   */
  getDocumentsByGroup = async (group) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/documents/group/${group}/exclude-archived`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getDocuments]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get Document
   * @param {Number} documentId
   * @returns {String}
   */
  getDocument = async (documentId) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/documents/${documentId}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getDocument]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get S3Document
   * @param {Number} documentId
   * @returns {String}
   */
  getS3Document = async (documentId) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/documents/${documentId}/s3`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getS3Document]");
        throw error;
      });

    return result.data;
  };

  /**
   * Download Document
   * @param {Number} documentId
   * @returns {String}
   */
  downloadDocument = async (documentId) => {
    const params = { ...this.config, ...{ responseType: "blob" } };
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/documents/${documentId}/download`,
        params
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - downloadDocument]");
        throw error;
      });

    return result;
  };

  /**
   * Redirect to url for direct download
   * @param {Number} documentId
   * @returns {String}
   */
  directDownLoadDocument = (documentId) => {
    return `${this.url}/${this.entity}/${this.id}/documents/${documentId}/download`;
  };

  /**
   * Get Upload Url for a given document
   * @param {Number} documentId
   * @returns {String}
   */
  getDocumentUploadUrl = (documentId) => {
    if (!documentId) {
      throw new Error(`Upload documentId not found !`);
    }
    return `${this.url}/${this.entity}/${this.id}/documents/${documentId}`;
  };

  /**
   * Get Upload Url for rib file
   * @returns {String}
   */
  getRibUploadUrl = () => {
    return `${this.url}/${this.entity}/${this.id}/rib/file`;
  };

  /**
   * Update Document
   * @param {Number} documentId
   * @param {Object} data
   * @returns {String}
   */
  updateDocument = async (documentId, data) => {
    const result = await axios({
      method: "put",
      url: `${this.url}/${this.entity}/${this.id}/documents/${documentId}`,
      data: { ...data },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - updateDocument]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get documents status synthesis
   * @returns {Object}
   */
  getDocumentsStatus = async () => {
    const result = await axios({
      method: "get",
      url: `${this.url}/${this.entity}/${this.id}/documents-status/`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - getDocumentsStatus]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get all justificatif types
   * @returns {Array}
   */
  getJustificatifTypes = async () => {
    const result = await axios({
      method: "get",
      url: `${this.url}/${this.entity}/justificatif-types/`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - getJustificatifTypes]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get justificatif type
   * @returns {Array}
   */
  getJustificatifType = async () => {
    const result = await axios({
      method: "get",
      url: `${this.url}/${this.entity}/${this.id}/justificatif-type/`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - getJustificatifType]");
      throw error;
    });

    return result.data;
  };

  /**
   * Set justificatif Type
   * @param {Object} data
   * @returns {String}
   */
  setJustificatifType = async (payload) => {
    const data = { excludeArchived: true, ...payload };
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/justificatif-type/`,
      data: { ...data },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - setJustificatifType]");
      throw error;
    });

    return result.data;
  };

  /**
   * Update justificatif Type
   * @param {Object} data
   * @returns {String}
   */
  updateJustificatifType = async (payload) => {
    const data = { excludeArchived: true, ...payload };
    const result = await axios({
      method: "put",
      url: `${this.url}/${this.entity}/${this.id}/justificatif-type/`,
      data: { ...data },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - updateJustificatifType]");
      throw error;
    });

    return result.data;
  };

  /**
   * Delete Document
   * @param {Number} documentId
   * @returns {String}
   */
  deleteDocument = async (documentId) => {
    const result = await axios({
      method: "delete",
      url: `${this.url}/${this.entity}/${this.id}/documents/${documentId}`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - deleteDocument]");
      throw error;
    });

    return result.data;
  };

  /**
   * JOB OFFERS
   */

  /**
   * Get JobOffers
   * @returns Promise
   */
  getJobOffers = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/job-offers`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getJobOffers]");
        throw error;
      });

    return result.data;
  };

  /**
   * Bookmark JobOffer
   * @param {Number} jobOfferId
   * @param {Number} position
   * @returns Promise
   */
  bookmarkJobOffer = async (jobOfferId, position = 1) => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/job-offers/bookmark`,
      data: {
        requestId: jobOfferId,
        position: position,
      },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - bookmarkJobOffer]");
      throw error;
    });

    return result.data;
  };

  /**
   * UnBookmark JobOffer
   * @param {Number} jobOfferId
   * @returns Promise
   */
  unBookmarkJobOffer = async (jobOfferId) => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/job-offers/unbookmark`,
      data: {
        requestId: jobOfferId,
      },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - unBookmarkJobOffer]");
      throw error;
    });

    return result.data;
  };

  /**
   * Delete a pushed JobOffer
   * @param {Number} jobOfferId
   * @param {Number} position
   * @returns Promise
   */
  deleteJobOffer = async (jobOfferId, position = 1) => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/job-offers/deletion`,
      data: {
        requestId: jobOfferId,
        position: position,
      },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - deleteJobOffer]");
      throw error;
    });

    return result.data;
  };

  /**
   * Returns JobOffer Status
   * @param {Number} jobOfferId
   * @returns Promise
   */
  getJobOfferStatus = async (jobOfferId) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/job-offers/${jobOfferId}/status`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getJobOfferStatus]");
        throw error;
      });

    return result.data;
  };

  /**
   * Apply JobOffer
   * @param {Number} jobOfferId
   * @param {Object} data
   * @returns Promise
   */
  applyJobOffer = async (jobOfferId, data) => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/job-offers/${jobOfferId}/apply`,
      data: { ...data, position: 1 },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - applyJobOffer]");
      throw error;
    });

    return result.data;
  };

  /**
   * Unapply JobOffer
   * @param {Number} jobOfferId
   * @returns Promise
   */
  unapplyJobOffer = async (jobOfferId) => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/job-offers/${jobOfferId}/unapply`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - unapplyJobOffer]");
      throw error;
    });

    return result.data;
  };

  /**
   * EmailAlert
   */

  /**
   * Add EmailAlert
   * @param {Object} data
   * @returns Promise
   */
  addEmailAlert = async (data) => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/job-offers/email-alert`,
      data: { ...data },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - addEmailAlert]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get EmailAlert
   * @returns Promise
   */
  getEmailAlert = async () => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/job-offers/email-alert`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getEmailAlert]");
        throw error;
      });

    return result.data;
  };

  /**
   * GMAP
   */

  /**
   * Get GoogleMap Autocompletion for input
   * @param {string} Input
   * @param {object} location
   * @returns Promise
   */
  getGmapAutocompletion = async (input, location) => {
    const country = "fr";
    const result = await axios({
      method: "get",
      url: `${
        this.url
      }/maps/api/place/autocomplete/json?components=country:${country}&input=${encodeURI(
        input
      )}&lat=${location.lat}&lon=${location.lon}`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - getGmapAutocompletion]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get GoogleMap distance between origin and destination
   * @param {string} origin
   * @param {object} destination
   * @param {string} mode
   * @returns Promise
   */
  getGmapDistance = async (origins, destinations, mode) => {
    console.log("[EmployeeConnector.getGmapDistance] origins:", origins);
    const result = await axios({
      method: "get",
      url: `${this.url}/maps/api/distancematrix/json?origins=${origins}&destinations=${destinations.lat}%2C${destinations.lon}&mode=${mode}`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - getGmapDistance]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get GoogleMap Geocoding (geoCoords from address)
   * @param {string} address
   * @returns Promise
   */
  getGmapGeocoding = async (address) => {
    const result = await axios({
      method: "get",
      url: `${this.url}/maps/api/geocode/json?address=${encodeURI(address)}`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - getGmapGeocoding]");
      throw error;
    });

    return result.data;
  };

  /**
   * FILTERS
   */

  /**
   * Returns computed filters from interviews data
   * @param {Object} interviews
   * @returns {Object}
   */
  getFilters = (interviews) => {
    const filters = {
      "job-offer-types": ["G", "C"], // TODO : get type after prescreen step ?
      "language-1-levels": [],
      "language-2-levels": [],
      // "languages-levels": [],
      "childcare-skills": [],
      "language-teaching-skills": [],
      skills: [],
      "job-offer-status": ["created", "updated"], // NB: we exclude "deleted" job-offer status
    };

    // childcare
    const skills = interviews?.childcare?.skills;
    const spokenLanguages = interviews?.childcare?.spokenLanguages;

    if (!skills) {
      throw new Error(
        "[EmployeeConnector.getFilters] childcare skills not found !"
      );
    }
    if (!spokenLanguages) {
      throw new Error(
        "[EmployeeConnector.getFilters] childcare spokenLanguages not found !"
      );
    }

    for (const skill of skills) {
      const { code, level, status } = skill;

      if (["validated", "to_validate"].includes(status)) {
        filters["childcare-skills"].push(`${code}${level}`);
      }
    }

    for (const spokenLanguage of spokenLanguages) {
      const { code, level, status } = spokenLanguage;

      if (["validated", "to_validate"].includes(status)) {
        filters["language-1-levels"].push(`${code}-${level}`);
        filters["language-2-levels"].push(`${code}-${level}`);
        // filters["languages-levels"].push(`${code}-${level}`);
      }
    }

    // language-teaching
    const languageTeachingSkills = interviews["language-teaching"]?.skills;

    if (!languageTeachingSkills) {
      throw new Error(
        "[EmployeeConnector.getFilters] language teaching skills not found !"
      );
    }

    for (const languageTeachingSkill of languageTeachingSkills) {
      const { code, level, status } = languageTeachingSkill;

      if (
        ["validated", "to_validate"].includes(status) &&
        ["HPT"].includes(code)
      ) {
        filters["language-teaching-skills"].push(`${code}${level}`);
      }
    }

    filters["skills"] = [
      ...filters["childcare-skills"],
      ...filters["language-teaching-skills"],
    ];

    // console.log("[EmployeeConnector.getFilters] filters:", filters);

    // security
    for (const key of Object.keys(filters)) {
      if (!SEARCH_FILTER_COMPONENT_IDS.includes(key)) {
        delete filters[key];
      }
    }

    return filters;
  };

  /**
   * Get AllChildcareSkillsRefused
   * @param {Object} interviews
   * @returns {Boolean}
   */
  getAllChildcareSkillsRefused = (interviews) => {
    const skills = interviews?.childcare?.skills;

    return skills.every((skill) => {
      const { status } = skill;

      return status === "refused";
    });
  };

  /**
   * Get AllLanguageTeachingSkillsRefused
   * @param {Object} interviews
   * @returns {Boolean}
   */
  getAllLanguageTeachingSkillsRefused = (interviews) => {
    const skills = interviews["language-teaching"]?.skills;

    return skills.every((skill) => {
      const { status } = skill;

      return status === "refused";
    });
  };

  /**
   * Get quiz state
   * @returns {Object}
   */
  getQuiz = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/quiz/`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getQuiz]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get engagement state
   * @returns {Object}
   */
  getEngagement = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/engagement/`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getEngagement]");
        throw error;
      });

    return result.data;
  };

  /**
   * Set engagement state
   * @returns {Object}
   */
  postEngagement = async () => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/engagement/`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - postEngagement]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get progress state
   * @returns {Object}
   */
  getProgress = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/career/`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getProgress]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get experiences of user
   * @returns {Object}
   */
  getExperiences = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/experiences/`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getExperiences]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get one experience of user
   * @param {Number} experience_id
   * @returns {Object}
   */
  getExperience = async (experience_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/experiences/${experience_id}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getExperience]");
        throw error;
      });

    return result.data;
  };

  /**
   * Add an experience to user
   * @param {Object} data
   * @returns {Object}
   */
  addExperience = async (data) => {
    const result = await axios({
      method: "put",
      data: { ...data },
      url: `${this.url}/${this.entity}/${this.id}/experiences/`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - addExperience]");
      throw error;
    });

    return result.data;
  };

  /**
   * Update an experience of user
   * @param {Number} experience_id
   * @param {Object} data
   * @returns {Object}
   */
  updateExperience = async (experience_id, data) => {
    const result = await axios({
      method: "post",
      data: { ...data },
      url: `${this.url}/${this.entity}/${this.id}/experiences/${experience_id}`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - updateExperience]");
      throw error;
    });

    return result.data;
  };

  /**
   * Delete an experiences of user
   * @param {Number} experience_id
   * @returns {Object}
   */
  deleteExperience = async (experience_id) => {
    const result = await axios
      .delete(
        `${this.url}/${this.entity}/${this.id}/experiences/${experience_id}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getExperience]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get contracts of user
   * @returns {Object}
   */
  getContracts = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/contracts/`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getContracts]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get a contract details of user
   * @param {Number} contract_id
   * @returns {Object}
   */
  getContract = async (contract_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/contracts/${contract_id}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getContract]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get a contract file of user
   * @param {Number} contract_id
   * @returns {Object}
   */
  getContractFile = async (contract_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/contracts/${contract_id}/file`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getContractFile]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get a contract signature of user
   * @param {Number} contract_id
   * @returns {Object}
   */
  getContractSign = async (contract_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/contracts/${contract_id}/sign`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getContractSign]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get a contract details of user
   * @param {Number} contract_id
   * @returns {Object}
   */
  getContractMandataire = async (contract_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/contracts/mandataire/${contract_id}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getContract]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get a contract file of user
   * @param {Number} contract_id
   * @returns {Object}
   */
  getContractMandataireFile = async (contract_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/contracts/mandataire/${contract_id}/file`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getContractFile]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get a contract signature of user
   * @param {Number} contract_id
   * @returns {Object}
   */
  getContractMandataireSign = async (contract_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/contracts/mandataire/${contract_id}/sign`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getContractSign]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get an offer status relative to the user
   * @param {Number} offer_id
   * @returns {Object}
   */
  getOfferInfo = async (offer_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/offers/${offer_id}/status`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getOfferInfo]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get dues of user
   * @returns {Object}
   */
  getDues = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/declarations/`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getDues]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get a due file of the user
   * @param {Number} due_id
   * @returns {Object}
   */
  getDueFile = async (due_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/declarations/${due_id}/file`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getDueFile]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get healthcares of user
   * @returns {Object}
   */
  getHealthcares = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/healthcares/`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getHealthcares]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get an healthcare detail of the user
   * @param {Number} healthcare_id
   * @returns {Object}
   */
  getHealthcare = async (healthcare_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/healthcares/${healthcare_id}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getHealthcare]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get an healthcare file of the user
   * @param {Number} healthcare_id
   * @returns {Object}
   */
  getHealthcareFile = async (healthcare_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/healthcares/${healthcare_id}/file`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getHealthcareFile]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get an healthcare signature of the user
   * @param {Number} healthcare_id
   * @returns {Object}
   */
  getHealthcareSignature = async (healthcare_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/healthcares/${healthcare_id}/sign`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getHealthcareSignature]");
        throw error;
      });

    return result.data;
  };

  /**
   * Set healthcare choice for user
   * @param {Number} healthcare_id
   * @param {Object} data
   * @returns Promise
   */
  postHealthcareChoice = async (healthcare_id, data) => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/healthcares/${healthcare_id}/`,
      data: { ...data },
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - postHealthcareChoice]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get payslips of user
   * @returns {Object}
   */
  getPayslips = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/payslips`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getPayslips]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get a payslip file of user
   * @param {Number} payslip_id
   * @returns {Object}
   */
  getPayslipFile = async (payslip_id) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/payslips/${payslip_id}/file`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getPayslipFile]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get RIB of user
   * @returns {Object}
   */
  getRib = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/rib`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getRib]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get RIB file of user
   * @param {Number} file_id
   * @returns {Object}
   */
  getRibFile = async (file_id) => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/rib/${file_id}`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getRibFile]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get dashboard status of user
   * @returns {Object}
   */
  getEmployeeStatus = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/status`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getEmployeeStatus]");
        throw error;
      });

    return result.data;
  };

  /**
   * Set RIB of user
   * @param {Object} data
   * @returns {Object}
   */
  setRib = async (data) => {
    const result = await axios({
      method: "post",
      data: { ...data },
      url: `${this.url}/${this.entity}/${this.id}/rib`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - setRib]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get country iban masks
   * @returns {Object}
   */
  getIbanMasks = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/iban-masks`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getIbanMasks]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get possible reasons for end date change
   * @param {Number} offer_id
   * @returns {Object}
   */
  getOffersDateChange = async (offer_id) => {
    const unit = offer_id ? `${offer_id}/` : "";
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/offers/${unit}confirm-end-date`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getOffersDateChange]");
        throw error;
      });

    return result.data;
  };

  /**
   * Set end date for an offer
   * @param {Number} offer_id
   * @returns {Object}
   */
  setOfferEndDate = async (offer_id, data) => {
    const result = await axios({
      method: "post",
      data: { ...data },
      url: `${this.url}/${this.entity}/${this.id}/offers/${offer_id}/change-end-date`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - setOfferEndDate]");
      throw error;
    });

    return result.data;
  };

  /**
   * Set end date for an offer
   * @param {Number} offer_id
   * @returns {Object}
   */
  confirmOfferEndDate = async (offer_id) => {
    const result = await axios({
      method: "post",
      url: `${this.url}/${this.entity}/${this.id}/offers/${offer_id}/confirm-end-date`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - confirmOfferEndDate]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get possible reasons for end date change
   * @returns {Object}
   */
  getBeforeModules = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/before`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getBeforeModules]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get current disponibility end date of user
   * @returns {Object}
   */
  getEndDate = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/end-date`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getEndDate]");
        throw error;
      });

    return result.data;
  };

  /**
   * Set end date of user
   * @param {Object} data
   * @returns {Object}
   */
  setEndDate = async (data) => {
    const result = await axios({
      method: "post",
      data: { ...data },
      url: `${this.url}/${this.entity}/${this.id}/end-date`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - setEndDate]");
      throw error;
    });

    return result.data;
  };

  /**
   * Put coupon
   * @param {Object} data
   * @returns {Object}
   */
  putCoupon = async (data) => {
    const result = await axios({
      method: "put",
      data: { ...data },
      url: `${this.url}/${this.entity}/${this.id}/coupon`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - putCoupon]");
      throw error;
    });

    return result.data;
  };

  /**
   * Put coupon
   * @param {Object} data
   * @returns {Object}
   */
  putIntervention = async (data) => {
    const result = await axios({
      method: "put",
      data: { ...data },
      url: `${this.url}/${this.entity}/${this.id}/intervention`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - putIntervention]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get current disponibility end date of user
   * @returns {Object}
   */
  getFamiliesForDate = async (date) => {
    const samDate = dateToSamAPI(date, "Ymd");
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/families/${samDate}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getEndDate]");
        throw error;
      });

    return result.data;
  };

  /**
   * Get detail of an intervention
   * @returns {Object}
   */
  getIntervention = async (interventionId) => {
    const result = await axios
      .get(
        `${this.url}/${this.entity}/${this.id}/intervention/${interventionId}`,
        this.config
      )
      .catch((error) => {
        logError(error, "[EmployeeConnector - getIntervention]");
        throw error;
      });

    return result.data;
  };

  /**
   * Post message seen
   * @param {Object} data
   * @returns {Object}
   */
  postMessageSeen = async (data) => {
    const result = await axios({
      method: "post",
      data: { ...data },
      url: `${this.url}/${this.entity}/${this.id}/message-seen`,
      ...this.config,
    }).catch((error) => {
      logError(error, "[EmployeeConnector - postMessageSeen]");
      throw error;
    });

    return result.data;
  };

  /**
   * Get detail of an intervention
   * @returns {Object}
   */
  getInterviewDate = async () => {
    const result = await axios
      .get(`${this.url}/${this.entity}/${this.id}/interview-date`, this.config)
      .catch((error) => {
        logError(error, "[EmployeeConnector - getInterviewDate]");
        throw error;
      });

    return result.data;
  };
}

export default EmployeeConnector;

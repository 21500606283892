import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Helmet } from "react-helmet-async";

import { Box, Grid, Chip, Tabs, Tab, Typography } from "@mui/material";

import useAuth from "../../hooks/useAuth";
import useSearchJob from "../../hooks/useSearchJob";

import SearchJob from "../../components/SearchJob/SearchJob";
import Loader from "../../components/SearchJob/Loader";
import { useNavigate } from "react-router-dom";

import { isMobileDevice } from "../../helpers/common.helpers";
import { pagesMargin } from "../../constants";

const DEFAULT_TAB_VALUE = "applications";

function MyOffers() {
  const { t } = useTranslation();
  const { filter } = useParams();
  const { user, setAppUserJobOffers } = useAuth();
  const [jobOffersEntry, setJobOffersEntry] = useState(
    filter || DEFAULT_TAB_VALUE
  );
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { setCurrentView } = useSearchJob();
  const theme = useTheme();
  const noMargin = useMediaQuery(theme.breakpoints.down("lg"));

  const marginSx = noMargin ? null : pagesMargin;

  // wet set current view
  setCurrentView(`my-offers/${filter}`);

  /**
   * Handle Tab Change
   * NB : to avoid SearchJob non rendering on tab changing, we move page and back and forth to force rendering ...
   * @param {Event} e
   * @param {String} value
   * @returns {void}
   */
  const handleTabChange = (e, value) => {
    console.log("[MyOffers] handleTabChange", value);
    setJobOffersEntry(value);

    navigate(`/my-blank-offers`); // NB : dummy page
    setTimeout(() => {
      navigate(`/my-offers/${value}`);
    }, 100);
  };

  // hack to avoid previous state when navigate from menu
  if (filter !== jobOffersEntry) {
    console.log("[MyOffers] url has changed ...");
    handleTabChange(new Event("click"), filter);
    //setJobOffersEntry(filter);
  }

  /**
   * Returns Tab Label Count
   * @param {String} value
   * @returns {Number}
   */
  const getTabLabelCount = (value) => {
    if (!user || !user.jobOffers) {
      return "?";
    } else if (!user.jobOffers[value]) {
      return 0;
    } else {
      return user.jobOffers[value].length;
    }
  };

  const TabLabel = (props) => {
    const { value } = props;
    return (
      <Box display="flex">
        <Typography paddingTop="5px">{t(`my-offers.${value}`)}</Typography>
        <Chip
          sx={{
            marginLeft: "0.5em",
            borderRadius: "25px",
          }}
          label={getTabLabelCount(value)}
        />
      </Box>
    );
  };

  /**
   * Returns current view title
   * @param {String} currentFilter
   * @returns {String}
   */
  const getViewTitle = (currentFilter) => {
    const viewTitle = currentFilter
      ? t(`menu.my-offers.${currentFilter}`)
      : t(`menu.my-offers.my-offers`);
    return viewTitle;
  };

  /**
   * Returns customFilters
   * @param {String} jobOffersEntry
   * @returns {Object}
   */
  const getCustomFilters = (jobOffersEntry) => {
    // console.log("[MyOffers] getCustomFilters", jobOffersEntry);
    if (
      jobOffersEntry &&
      user?.jobOffers &&
      typeof user?.jobOffers[jobOffersEntry] !== "undefined"
    ) {
      let customFilters = {
        "job-offer-ids": user?.jobOffers[jobOffersEntry].map((jobOffer) => {
          return jobOffer.requestId;
        }),
      };

      if (!customFilters["job-offer-ids"].length) {
        customFilters["job-offer-ids"] = ["-1"]; // NB : add unknown offer id to avoid to return all offers ...
      }
      return customFilters;
    }
    return null;
  };

  // we complete data allready loaded by setAppUser()
  useEffect(() => {
    const initJobOffers = async () => {
      if (user) {
        if (!user.jobOffers) {
          console.log("[MyOffers] useEffect - initJobOffers");
          setIsLoading(true);

          await setAppUserJobOffers();
          setIsUserDataLoaded(true);
        } else {
          setIsUserDataLoaded(true);
          setIsLoading(false);
        }
      }
    };
    initJobOffers();
  }, [setAppUserJobOffers, user]);

  return (
    <React.Fragment>
      <Helmet title={t("menu.my-offers.my-offers")} />
      <Box sx={marginSx}>
        <Typography
          variant="h3"
          gutterBottom
          display="grid"
          className="view-title"
        >
          {getViewTitle(filter)}
        </Typography>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            {isMobileDevice() && (
              <section className="job-offers-tabs">
                <Tabs
                  value={jobOffersEntry}
                  onChange={handleTabChange}
                  variant="fullWidth"
                >
                  <Tab
                    label={<TabLabel value="applications" />}
                    value="applications"
                  />
                  <Tab
                    label={<TabLabel value="bookmarks" />}
                    value="bookmarks"
                  />
                  <Tab label={<TabLabel value="pushes" />} value="pushes" />
                </Tabs>
              </section>
            )}

            <section className="search-job">
              <Loader
                show={isLoading}
                loadingText={t("search-job.my-offers-loading")}
              />

              {isUserDataLoaded && (
                <SearchJob
                  customFilters={getCustomFilters(jobOffersEntry)}
                  isMyOffersParentComponent={true}
                  className="my-offers-wrapper"
                />
              )}
            </section>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default MyOffers;

const statuses = {
  INIT: 0,
  EMPTY: -1,
  INVALIDATED: 1,
  TO_VALIDATE: 2,
  VALIDATED: 3,
  UNDEFINED: 100,
};

const statusDisplay = {
  "-1": {
    color: "error.main",
    label: "init",
  },
  0: {
    color: "error.main",
    label: "init",
  },
  1: {
    color: "error.main",
    label: "invalidated",
  },
  2: {
    color: "primary",
    label: "to-validate",
  },
  3: {
    color: "success.light",
    label: "validated",
  },
};

const documentGroupTypes = {
  JUSTIFICATIF: "JUSTIFICATIF",
  IDENTITE: "IDENTITE",
  OTHER: "OTHER",
};

const identiteTypes = [
  {
    value: 2,
    label: {
      fr: "Carte d'identité",
      en: "ID Card",
    },
    ids: [1, 2],
  },
  {
    value: 1,
    label: {
      fr: "Passeport",
      en: "Passeport",
    },
    ids: [12],
  },
];

const requiresNoDocuments = ["666666"];

export {
  statuses,
  statusDisplay,
  documentGroupTypes,
  identiteTypes,
  requiresNoDocuments,
};

/**
 * Returns true if date within Range
 * @param {Date} date
 * @param {Date} startRange
 * @param {Date} endRange
 * @returns {boolean}
 */
export const isDateWithinRange = (date, startRange, endRange) => {
  // console.log(
  //   "[isDateWithinRange]:",
  //   startRange,
  //   date,
  //   endRange,
  //   new Date(startRange).getTime(),
  //   new Date(date).getTime(),
  //   new Date(endRange).getTime()
  // );
  return (
    new Date(date).getTime() >= new Date(startRange).getTime() &&
    new Date(date).getTime() <= new Date(endRange).getTime()
  );
};

/**
 * Format date
 * @param {String} date
 * @param {String} language
 * @returns {void}
 */
export const formatDate = (date, language = "fr") => {
  if (!date) {
    return null;
  }
  const [Y, m, d] = date.split("-");
  return `${d} ${shortMonths[m][language]} ${Y}`;
};

export const shortMonths = {
  "01": {
    en: "jan",
    fr: "jan",
  },
  "02": {
    en: "feb",
    fr: "fev",
  },
  "03": {
    en: "mar",
    fr: "mar",
  },
  "04": {
    en: "apr",
    fr: "avr",
  },
  "05": {
    en: "may",
    fr: "mai",
  },
  "06": {
    en: "jun",
    fr: "juin",
  },
  "07": {
    en: "jul",
    fr: "juil",
  },
  "08": {
    en: "aug",
    fr: "août",
  },
  "09": {
    en: "sept",
    fr: "sept",
  },
  10: {
    en: "oct",
    fr: "oct",
  },
  11: {
    en: "nov",
    fr: "nov",
  },
  12: {
    en: "dec",
    fr: "déc",
  },
};

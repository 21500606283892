import React from "react";
import {
  // GeoDistanceSlider,
  GeoDistanceDropdown,
  // ReactiveOpenStreetMap
} from "@appbaseio/reactivemaps";

import { useTranslation } from "react-i18next";

import { getUserFilter } from "../user.helpers";
import useSearchJob from "../../../hooks/useSearchJob";

/**
 * Returns GeoDistanceFilter
 * @param {Object} props
 * @returns {ReactComponent}
 */
function GeoDistanceFilter(props) {
  const {
    // range = {
    //   start: 0,
    //   end: 50,
    // },
    className = "filter",
    showTitle,
    mergedFilters,
  } = props;

  const { t } = useTranslation();
  const { currentGeoLocation } = useSearchJob();

  const currentFilter = getUserFilter(
    mergedFilters,
    "geo-distance",
    "GeoDistance"
  );

  console.log(
    "[GeoDistanceFilter] props:",
    props,
    "currentFilter",
    currentFilter,
    currentGeoLocation
  );

  // NB : the distance slider selection is mandatory to launch a request !
  // return (
  //   <GeoDistanceSlider
  //     componentId="geo-distance"
  //     dataField="location"
  //     className={className}
  //     title={showTitle ? t("search-job.starting-address") : null}
  //     range={range}
  //     rangeLabels={{
  //       start: `${range.start} km`,
  //       end: `${range.end} km`,
  //     }}
  //     // defaultValue={{
  //     //   location: "Paris, FR",
  //     //   distance: 10,
  //     // }}
  //     countries={["fr"]}
  //     placeholder={t("search-job.starting-address-input")}
  //     unit="km"
  //     autoLocation={true}
  //     showFilter={true}
  //     filterLabel={t("search-job.distance")}
  //     showIcon={true}
  //     iconPosition="left"
  //     URLParams={false}
  //     // value={0}
  //     onChange={(value) => {
  //       console.log("[GeoDistanceFilter] onChange:", value);
  //     }}
  //     onValueChange={(value) => {
  //       console.log("[GeoDistanceFilter] onValueChange:", value);
  //     }}
  //     onFocus={(value) => {
  //       console.log("[GeoDistanceFilter] onFocus:", value);
  //     }}
  //     onKeyDown={(value) => {
  //       console.log("[GeoDistanceFilter] onKeyDown:", value);
  //     }}
  //   />
  // );

  /**
   * Auto Select Distance in hidden select field
   * Pb: select li elt appears and disappears instantly ...
   * @returns {ReactComponent}
   */
  // const handleAutoSelectDistance = () => {
  //   const btn = document.querySelector(
  //     ".geo-distance-no-distance-select button"
  //   );
  //   console.log("[GeoDistanceFilter] handleAutoSelectDistance - btn", btn);

  //   if (btn) {
  //     const handleClick = () => {
  //       console.log("[GeoDistanceFilter] btn click");

  //       const timeOut = setTimeout(() => {
  //         console.log("[GeoDistanceFilter] setTimeout");

  //         const ul = btn.nextSibling;
  //         // const ul = btn.querySelector("ul.css-avkrty li");
  //         console.log("[GeoDistanceFilter] setTimeout", ul);
  //         if (ul && ul.hasChildNodes()) {
  //           const li = ul.childNodes;
  //           console.log("[GeoDistanceFilter] btn click: elts", ul, li[0]);
  //           if (li.length) {
  //             li[0].dispatchEvent(new Event("click"));
  //             console.log("[GeoDistanceFilter] setTimeout li clicked");

  //             clearTimeout(timeOut);
  //           }
  //         }
  //       }, 500);
  //     };

  //     // remove previous click event (KO)
  //     btn.removeEventListener("click", handleClick);

  //     // add event
  //     btn.addEventListener("click", handleClick);

  //     // trigger event
  //     const event = new Event("click", {
  //       view: window,
  //       bubbles: true,
  //       cancelable: true,
  //     });
  //     btn.dispatchEvent(event);
  //   }
  // };

  // NB : always set a default distance (after filters init ...)
  return (
    <GeoDistanceDropdown
      componentId="geo-distance"
      dataField="location"
      className={className}
      title={showTitle ? t("search-job.starting-address") : null}
      countries={["fr"]}
      placeholder={t("search-job.starting-address-input")}
      unit="km"
      autoLocation={true}
      showFilter={true}
      filterLabel={t("search-job.starting-location")}
      showIcon={true}
      iconPosition="left"
      URLParams={true}
      data={[
        { distance: 5, label: "< 5 kms" },
        { distance: 10, label: "< 10 kms" },
        { distance: 15, label: "< 15 kms" },
        { distance: 20, label: "< 20 kms" },
        { distance: 25, label: "< 25 kms" },
      ]}
      defaultValue={{
        label: "< 10 kms",

        // NB: bugged when distance value has changed ...
        // label: currentFilter?.label ? currentFilter.label : "< 10 kms",

        // NB: bad values sent by geo-distance filter {label, location } instead of {distance, location, unit} !!!
        // fixed by passing geo-distance by get params
        // location: currentFilter?.location ? currentFilter.location : "",
      }}

      // selectedValue={{ >> KO
      //   label: currentFilter?.label ? currentFilter.label : "< 10 kms",
      //   location: currentFilter?.location ? currentFilter.location : "",
      // }}

      // value + onChange >> KO
      // value={{
      //   label: currentFilter?.label ? currentFilter.label : "< 10 kms",
      //   location: currentFilter?.location ? currentFilter.location : "",
      // }}
      // onChange={(value) => { >> KO
      //   console.log("[GeoDistanceFilter] onChange:", value);
      //   return value;
      // }}
      // get distance in km in search result >> KO
      // customQuery={(location, distance, props) => {
      //   if (location && distance && props) {
      //     const { unit } = props;
      //     console.log(
      //       "[GeoDistanceFilter] customQuery:",
      //       location,
      //       distance,
      //       unit
      //     );

      //     const locations = location.split(",");

      //     return {
      //       // query in the format of Elasticsearch Query DSL
      //       // geo_distance: {
      //       //   distance: distance + unit,
      //       //   location_dataField: location,
      //       // },
      //       // Elastic
      //       // query: {
      //       //   geo_distance: {
      //       //     distance: distance + props.unit,
      //       //     "location.lat_long": location,
      //       //   },
      //       // },
      //       // sort: [
      //       //   {
      //       //     _geo_distance: {
      //       //       location: {
      //       //         lat: locations[0].trim(),
      //       //         lon: locations[1].trim(),
      //       //       },
      //       //       order: "asc",
      //       //       unit: "km",
      //       //       mode: "min",
      //       //       distance_type: "arc",
      //       //       ignore_unmapped: true,
      //       //     },
      //       //   },
      //       // ],
      //       // script_fields: {
      //       //   geo_distance: {
      //       //     script: {
      //       //       params: {
      //       //         lat: locations[0].trim(),
      //       //         lon: locations[1].trim(),
      //       //       },
      //       //       inline: "doc['location'].arcDistance(params.lat, params.lon)",
      //       //     },
      //       //   },
      //       // },
      //     };
      //   }
      // }}
      // onData={(prop) => {
      //   const { value, error } = prop;
      //   console.log("[GeoDistanceFilter] onData:", value, error);
      // }}
      // onValueChange={(value) => {
      //   console.log("[GeoDistanceFilter] onValueChange:", value);
      // }}
      // onFocus={(value) => {
      //   console.log("[GeoDistanceFilter] onFocus:", value);

      //   // handleAutoSelectDistance();
      // }}
      // onBlur={(value) => {
      //   console.log("[GeoDistanceFilter] onBlur:", value);
      // }}
      // onKeyPress={(value) => {
      //   console.log("[GeoDistanceFilter] onKeyPress:", value);
      // }}
      // onKeyUp={(value) => {
      //   console.log("[GeoDistanceFilter] onKeyUp:", value);
      //   btn.dispatchEvent(new Event("click"));
      // }}
    />
  );
}

// GeoDistanceFilter.defaultProps = { // >> KO
//   range: {
//     start: 0,
//     end: 50,
//   },
// };

export default GeoDistanceFilter;

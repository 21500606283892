import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { Alert as MuiAlert, Paper, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import { ReactComponent as Logo } from "../../vendor/momji_logo_837x551.svg";

import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useXSRF from "../../hooks/useXSRF";

const Alert = styled(MuiAlert)(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 180px;
  height: 120px;
  margin-bottom: 20px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignOut() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const { removeApiTokens } = useXSRF();
  const [hasSignedOut, setHasSignedOut] = useState(false);

  useEffect(() => {
    // console.log("[SignOut] useEffect");

    const handleSignOut = async () => {
      try {
        if (!hasSignedOut) {
          // logout from SAM / clear local session
          await signOut();

          // remove api xsrf tokens
          await removeApiTokens();

          setHasSignedOut(true);
        }
      } catch (error) {
        console.error("[SignOut.handleSignOut]", error);
        setError(error.toString());
      }

      // redirect to sign-in
      navigate(`/auth/sign-in`);
    };
    handleSignOut();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSignedOut]);

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title={t("auth.welcome")} />

        {error && (
          <Alert mt={2} mb={3} severity="warning">
            {error}
          </Alert>
        )}
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("auth.sign-out-progress")}
        </Typography>
      </Wrapper>
    </React.Fragment>
  );
}

export default SignOut;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "intl-tel-input/build/css/intlTelInput.css";

import useAuth from "../../hooks/useAuth";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import UserInformationsForm from "./UserInformationsForm";
import { isMobileDevice } from "../../helpers/common.helpers";

const UserInformations = () => {
  const { user } = useAuth();
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  /**
   * Cancel edition mode and reset form
   * @returns {void}
   */
  const handleCloseForm = () => {
    setEdit(false);
  };

  /**
   * Open edition mode
   * @returns {void}
   */
  const handleEdit = () => {
    setEdit(true);
  };

  return edit && isMobileDevice() ? (
    <Box>
      <Typography variant="h4">{t("user-profile.informations")}</Typography>
      <UserInformationsForm handleCloseForm={handleCloseForm} />
    </Box>
  ) : (
    <>
      <Box display="flex" justifyContent="space-between" mb="5px">
        <Typography variant="h4">{t("user-profile.informations")}</Typography>
        <IconButton
          className="icon"
          pt={0}
          color="primary"
          onClick={handleEdit}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <Typography mb="0.5em">{`${user.firstname} ${user.lastname}`}</Typography>
      <Typography mb="0.5em">{user.nationalityObj.label}</Typography>
      <Typography mb="0.5em">{user.mobile.phone}</Typography>
      <Typography mb="0.5em">{user.email}</Typography>
      <Typography mb="0.5em">{user.address.line}</Typography>
      <Typography mb="0.5em">{`${user.address.zipcode} ${user.address.city}`}</Typography>
      <Dialog maxWidth="md" fullWidth open={edit} onClose={handleCloseForm}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">
              {t("user-profile.informations")}
            </Typography>
            <IconButton onClick={handleCloseForm}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <UserInformationsForm handleCloseForm={handleCloseForm} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserInformations;

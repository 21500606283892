import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import useAuth from "../../hooks/useAuth";

import DisponibilityModule from "../../components/mandatory/DisponibilityModule";
import ContractDisponibilityModule from "../../components/mandatory/ContractDisponibilityModule";

const MandatoryView = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const mandatoryAction = user?.blockingActions[0];

  const mandatoryModules = useMemo(() => {
    return {
      EMPLOYEECONFIRMENDDATE: <DisponibilityModule />,
      REQUESTCONFIRMENDDATE: <ContractDisponibilityModule />,
    };
  }, []);

  useEffect(() => {
    if (user && (!user.blockingActions || !user.blockingActions.length)) {
      navigate(`/`);
    }

    if (mandatoryAction && !mandatoryModules[mandatoryAction]) {
      console.error(
        `[MandatoryView] mandatoryAction ${mandatoryAction} not found !`
      );
      navigate(`/auth/500`);
    }
  }, [user, navigate, mandatoryModules, mandatoryAction]);

  return (
    <Box>
      <Box mt="1em">{mandatoryModules[mandatoryAction]}</Box>
    </Box>
  );
};

export default MandatoryView;

import React from "react";
import { useTranslation, Trans } from "react-i18next";
import styled from "@emotion/styled";

import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import ImageEN from "../../statics/Activities_EN.png";
import ImageFR from "../../statics/Activities_FR.png";

const CustomButton = styled(Button)({
  backgroundColor: "#FED24C",
  color: "#2B4C96",
  "&:hover": {
    backgroundColor: "#EEC23C",
  },
});

const activitiesUrl = "https://www.momji.fr/pedagogie-momji";

const Activities = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const bold = { fontWeight: "bold" };

  const handleOpenLink = () => {
    window.open(activitiesUrl, "_blank");
  };

  return (
    <Box>
      <Box mb="2em">
        <Typography variant="h4">{t("resources.activities.title")}</Typography>
      </Box>
      <Card sx={{ maxWidth: { xs: "100%", md: "75%" } }}>
        <CardContent>
          <Box sx={{ display: { xs: "block", lg: "flex" } }}>
            <img
              src={language === "en" ? ImageEN : ImageFR}
              alt="Activities-visual"
              style={{
                width: "100%",
                height: "100%",
                maxWidth: "400px",
                maxHeight: "400px",
                cursor: "pointer",
              }}
              onClick={handleOpenLink}
            />
            <Box ml={{ md: "1em" }} p="1em">
              <Box sx={{ height: "80%" }}>
                <Box mb="2em" mt="2em">
                  <Typography style={bold}>
                    {t("resources.activities.text.1")}
                  </Typography>
                </Box>

                <Box mb="2em">
                  <Trans
                    i18nKey="resources.activities.text.2"
                    components={{ bold: <strong /> }}
                  />
                </Box>

                <Box mb="2em">
                  <Trans
                    i18nKey="resources.activities.text.3"
                    components={{ bold: <strong /> }}
                  />
                </Box>
              </Box>
              <Box sx={{ textAlign: "right", height: "65%" }}>
                <CustomButton
                  variant="contained"
                  endIcon={<ArrowForward />}
                  onClick={handleOpenLink}
                >
                  {t("resources.activities.button")}
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Activities;

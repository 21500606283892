import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { ReactComponent as Logo } from "../../vendor/momji_logo_837x551.svg";
import ResetPasswordComponent from "../../components/auth/ResetPassword";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 180px;
  height: 120px;
  margin-bottom: 20px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title={t("auth.reset-password")} />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("auth.reset-password")}
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          {t("auth.reset-password-email")}
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;

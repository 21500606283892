import { format } from "date-fns";
import { enGB, fr } from "date-fns/locale";
import { appConfig } from "../config";
import { CHILDCARE_COURSE_TYPES_REGEX } from "../components/SearchJob/filters/filters.helpers";

/**
 * Maps dashboardItems <> dashboardTranslations
 * @param {Object} dashboardItems
 * @param {Object} dashboardTranslations
 * @returns {Object}
 */
const mapTranslations = (dashboardItems, dashboardTranslations) => {
  // console.log("[mapTranslations]", dashboardItems, dashboardTranslations);

  const mappedTranslations = [...dashboardItems].map((dashboardItem) => {
    // page title
    if (
      dashboardItem.id &&
      typeof dashboardTranslations[dashboardItem.id] !== "undefined"
    ) {
      dashboardItem.title = dashboardTranslations[dashboardItem.id];
    }

    // pages title
    if (dashboardItem.pages) {
      dashboardItem.pages.forEach((page, index) => {
        if (page.id && typeof dashboardTranslations[page.id] !== "undefined") {
          dashboardItem.pages[index].title = dashboardTranslations[page.id];
        }

        // children pages title
        if (page.children) {
          page.children.forEach((child, childIndex) => {
            if (
              child.id &&
              typeof dashboardTranslations[child.id] !== "undefined"
            ) {
              dashboardItem.pages[index].children[childIndex].title =
                dashboardTranslations[child.id];
            }
          });
        }
      });
    }

    return dashboardItem;
  });

  return mappedTranslations;
};

/**
 * Returns translated Date
 * @param {Date} date
 * @param {String} locale
 * @param {String} formatStr
 * @returns {String}
 */
const translateDate = (date, locale, formatStr = "PP kk:mm") => {
  const locales = { enGB, fr };
  return format(date, formatStr, {
    locale: locales[locale],
  });
};

/**
 * Returns Offer Translated Prop
 * @param {Object} offer
 * @param {String} property
 * @param {String} language
 * @param {Object} repositories
 * @param {Object} t
 * @param {Boolean} isOfferDetail
 * @returns {String}
 */
const getOfferTranslatedProp = (
  offer,
  property,
  language,
  repositories,
  t,
  isOfferDetail = false
) => {
  const { sub_type, childcare_type, course_type } = offer;

  let title;
  let matches;

  // NB : we use same sub_type / language parsing as in job-offer-childcare-types filter
  if (sub_type === "course") {
    matches = course_type
      ? course_type.match(CHILDCARE_COURSE_TYPES_REGEX)
      : [];
  } else {
    matches = childcare_type
      ? childcare_type.match(CHILDCARE_COURSE_TYPES_REGEX)
      : [];
  }

  if (matches && matches.length) {
    // eslint-disable-next-line no-unused-vars
    const [_label, code_type, code_language] = matches;

    let translatedLanguage;

    if (
      typeof repositories["languages"][code_language] !== "undefined" &&
      typeof repositories["languages"][code_language][language] !== "undefined"
    ) {
      translatedLanguage = repositories["languages"][code_language][language];
    }

    switch (code_type) {
      case "immersion-":
        title = isOfferDetail
          ? t("job-offer.sub-types.details.immersion", {
              language: translatedLanguage,
            })
          : t("job-offer.sub-types.titles.immersion", {
              language: translatedLanguage,
            });
        break;
      case "course-":
        title = isOfferDetail
          ? t("job-offer.sub-types.details.course")
          : t("job-offer.sub-types.titles.course", {
              language: translatedLanguage,
            });
        break;
      case "initiation-":
        title = isOfferDetail
          ? t("job-offer.sub-types.details.initiation")
          : t("job-offer.sub-types.titles.initiation");
        break;
      case "creative-":
        title = isOfferDetail
          ? t("job-offer.sub-types.details.creative")
          : t("job-offer.sub-types.titles.creative");
        break;
      default:
        title = "";
    }
  }
  return title;

  // -- v1
  // return typeof offer[`${property}_${language}`] !== "undefined"
  //   ? offer[`${property}_${language}`]
  //   : null;
};

/**
 * Returns UI Language from user language
 * @param {String} userLanguage
 * @returns {String}
 */
const getUILanguage = (userLanguage = appConfig.defaultLanguage) => {
  if (!appConfig.allowedLanguages.includes(userLanguage)) {
    return appConfig.UIDefaultLanguage;
  }

  const tmps = userLanguage.split("-");
  if (typeof tmps[0] === "undefined") {
    return appConfig.UIDefaultLanguage;
  }
  return tmps[0];
};

const detailedLanguage = {
  fr: "fr-FR",
  en: "en-GB",
};

export {
  mapTranslations,
  translateDate,
  getOfferTranslatedProp,
  getUILanguage,
  detailedLanguage,
};

/* eslint-disable import/first */
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { appConfig } from "./config";

import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  environment: appConfig.env,
  release: appConfig.version,
  dsn: "https://342cebdc5fce4f19982f6e2efed1ac03@o661703.ingest.sentry.io/4504015043821568",
  integrations: (defaultIntegrations) => [
    ...defaultIntegrations,
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 0.05, // @TODO use env variable once thanos has been merged
  autoSessionTracking: true,
});

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

import "./mocks";

import { ThemeProvider } from "./contexts/ThemeContext";

ReactDOM.render(
  <BrowserRouter basename={appConfig.basePath}>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

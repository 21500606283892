import React from "react";

import RAF from "../../components/raf/RAF";

const RAFView = (props) => {
  return (
    <>
      <RAF />
    </>
  );
};

export default RAFView;

import React, { useState, useMemo, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";

import EmployeeConnector from "../../connectors/sam-api/employee/EmployeeConnector";
import { getSamAuthHeaders } from "../../utils/sam.api.js";
import { getSamProxyXHRConfig } from "../../connectors/connector.helpers";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import DashboardModule from "../../components/dashboard/DashboardModule";
import Todo from "../../components/dashboard/Todo";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";

const DashBoardView = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);

  const samAuthHeaders = getSamAuthHeaders(user);
  const employeeConnector = useMemo(
    () =>
      user
        ? new EmployeeConnector(user.id, getSamProxyXHRConfig(samAuthHeaders))
        : null,
    [samAuthHeaders, user]
  );

  const getState = useCallback(async () => {
    setLoading(true);
    console.log("[DashboardView] getState");
    try {
      const result = await employeeConnector.getEmployeeStatus();

      if (result) {
        setState(result.data.results);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[DashboardView] getState", error);
      setLoading(false);
    }
  }, [employeeConnector]);

  const renderModules = () => {
    if (!state || state.dashboard_modules.length === 0) {
      return null;
    }
    return state.dashboard_modules.map((module) => (
      <Box mb="1em" key={module}>
        <Card>
          <CardContent>
            <DashboardModule module={module} />
          </CardContent>
        </Card>
      </Box>
    ));
  };

  /**
   * Returns Dashboard Message
   * @param {String} message
   * @returns {DOM Elt}
   */
  const getDashboardMessage = (message) => {
    return (
      <div
        className="dashboard-message"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    );
  };

  useEffect(() => {
    if (state === null && !loading && employeeConnector) {
      getState();
    }
  }, [state, getState, loading, employeeConnector]);

  return (
    <Box>
      <Box mb="1em">
        <Card>
          <CardContent>
            <Typography variant="h4">{t("dashboard.welcome")}</Typography>
            <Box>
              {state && state.dashboard_message
                ? getDashboardMessage(state.dashboard_message)
                : t("dashboard.generic-message")}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" mt="1em">
          <CircularProgress />
          <Typography pt="0.8em" ml="1em">
            {t("loader.loading")}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box mb="1em">
            <Card>
              <CardContent>
                <Typography variant="h4">{t("dashboard.your-todo")}</Typography>
                <Todo step={state?.dashboard_parcours} />
              </CardContent>
            </Card>
          </Box>
          {state && state.dashboard_modules ? renderModules() : null}
        </Box>
      )}
    </Box>
  );
};

export default DashBoardView;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translations as en_GB } from "./locales/en-GB";
import { translations as fr_FR } from "./locales/fr-FR";

const resources = {
  en: {
    translation: en_GB,
  },
  fr: {
    translation: fr_FR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "fr", // fr,en
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

// i18n events
if (i18n.isInitialized) {
  // console.info("[i18n] - initialized !");
}
i18n.on("languageChanged", (lng) => {
  // console.info("[i18n] - languageChanged:", lng);
});
i18n.on("loaded", (lng) => {
  // console.info("[i18n] - loaded:", lng);
});

import React, { createContext, useState, forwardRef } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ERROR = "error";
const SUCCESS = "success";
const INFO = "information";
const WARNING = "warning";

const DEFAULT_DURATION = 3000; // ms
const ERROR_DURATION = 5000; // ms

const AlertContext = createContext({});

function AlertProvider({ children }) {
  const [alert, setAlert] = useState({
    message: "Alert message",
    severity: INFO,
    open: false,
    duration: DEFAULT_DURATION,
  });

  const success = (response) => {
    setAlert({
      severity: SUCCESS,
      message: response.message,
      open: true,
      duration: DEFAULT_DURATION,
    });
  };

  const error = (response) => {
    setAlert({
      severity: ERROR,
      message: response.message,
      open: true,
      duration: ERROR_DURATION,
    });
  };

  const info = (response) => {
    setAlert({
      severity: INFO,
      message: response.message,
      open: true,
      duration: DEFAULT_DURATION,
    });
  };

  const warning = (response) => {
    setAlert({
      severity: WARNING,
      message: response.message,
      open: true,
      duration: ERROR_DURATION,
    });
  };

  const close = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  return (
    <AlertContext.Provider
      value={{
        alert,
        success,
        error,
        info,
        warning,
      }}
    >
      {children}
      <Snackbar
        open={alert.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={close}
        autoHideDuration={alert.duration}
      >
        <Alert onClose={close} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
}

export { AlertContext, AlertProvider };

import { useContext } from "react";

import { SearchJobContext } from "../contexts/SearchJobContext";

const useSearchJob = () => {
  const context = useContext(SearchJobContext);
  // console.log("[useSearchJob] context", context);

  if (!context)
    throw new Error("SearchJobContext must be placed within SearchJobProvider");

  return context;
};

export default useSearchJob;

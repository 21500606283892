import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Divider,
  Typography,
} from "@mui/material";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

import { colorStatus, statusMap, contractType } from "./constants";
import { parseDate } from "./helper-contract";

const ContractList = (props) => {
  const { employeeConnector } = useAuth();
  const alert = useAlert();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);

  const language = i18n.language;

  /**
   * Handle click on a contract
   * @param {Object} contract
   * @returns {void}
   */
  const handleContractClick = (contract) => {
    console.log("[ContractList] handleContractClick", contract);
    navigate(
      `/contracts/${contractType[contract.type_contrat]}/${contract.mission_id}`
    );
  };

  /**
   * Get contract list of user
   * @returns {void}
   */
  const getContractList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getContracts();
      if (result) {
        setList(result.data.results.missions);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[ContractList] getContractList", error);
    }
  }, [employeeConnector, alert]);

  /**
   * Render contract list
   * @returns {Array}
   */
  const renderContractList = () => {
    if (loading) {
      return (
        <Box mt="5em" display="grid" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }
    if (list && list.length === 0)
      return (
        <Box mt="1em" display="grid" justifyContent="center">
          <Typography variant="h4">{t("contract.no-result")}</Typography>
          <Typography mt="1em">
            {t("contract.no-result-description")}
          </Typography>
        </Box>
      );

    if (!list) return null;
    return list.map((contract) => {
      return (
        <Card
          key={contract.mission_id}
          sx={{
            cursor: "pointer",
            marginBottom: "1em",
          }}
        >
          <CardContent onClick={() => handleContractClick(contract)}>
            <Box display="flex" justifyContent="space-between" mb="1em">
              <Typography pt="0.5em">{contract.customer_name}</Typography>
              <Chip
                label={t(
                  `contract.status.${statusMap.get(contract.contract_status)}`
                )}
                sx={{
                  borderRadius: "2em",
                  color:
                    colorStatus[statusMap.get(contract.contract_status)].text,
                  backgroundColor:
                    colorStatus[statusMap.get(contract.contract_status)]
                      .background,
                  cursor: "pointer",
                }}
              />
            </Box>
            <Divider my={6} />
            <Box mt="1em">
              <Typography variant="h6">{contract.contrat_type}</Typography>
              <Typography mt="1em">
                {t("contract.begins-on")}{" "}
                {parseDate(contract.date_start, language)}
              </Typography>
              <Typography mt="1em">
                {t("contract.ends-on")} {parseDate(contract.date_end, language)}
              </Typography>
              <Typography mt="1em">
                {t("contract.linked-to-offer")}
                {contract.request_num}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      );
    });
  };

  useEffect(() => {
    if (list === null && employeeConnector && !loading) {
      getContractList();
    }
  }, [list, employeeConnector, getContractList, loading]);

  return (
    <>
      <Box>{renderContractList()}</Box>
    </>
  );
};

export default ContractList;

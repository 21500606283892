import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Link,
  Typography,
  Drawer,
} from "@mui/material";
import { spacing } from "@mui/system";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

import DateEndChangeFrom from "./DateEndChangeForm";
import { appConfig } from "../../config";
import { colorStatus, actions, statusMap } from "./constants";
import { isMobileDevice } from "../../helpers/common.helpers";

const Button = styled(MuiButton)(spacing);
const cardSx = {
  marginTop: "1em",
};

const MandataireContractDetail = (props) => {
  const { id } = props;
  const { employeeConnector } = useAuth();
  const alert = useAlert();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [contract, setContract] = useState(props.contract || null);
  const [dateChangeReasons, setDateChangeReasons] = useState(null);
  const [signUrl, setSignUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Handle return to contracts list
   * @returns {void}
   */
  const handleReturnClick = () => {
    console.log("[MandataireContractDetail] handleReturnClick");
    navigate(`/contracts/contracts`);
  };

  /**
   * Handle action on contract, either sign or see, depending on status
   * @returns {void}
   */
  const handleActionClick = async (action) => {
    const connectorCall =
      action === actions.to_read
        ? employeeConnector.getContractMandataireFile
        : employeeConnector.getContractMandataireSign;
    try {
      const result = await connectorCall(id);
      if (result) {
        const { url } = result.data.results;
        if (url) {
          if (action === actions.to_sign && !isMobileDevice()) setSignUrl(url);
          else window.open(url, "_blank").focus();
        }

        console.log(
          "[MandataireContractDetail] handleActionClick",
          action,
          url
        );
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[MandataireContractDetail] handleActionClick", error);
    }
  };

  /**
   * Handle date change form opening
   * @returns {void}
   */
  const handleChangeEndDateClick = () => {
    setOpen(!open);
  };

  /**
   * Handle date change form submiting
   * @returns {void}
   */
  const handleChangeEndDateSubmit = async (values) => {
    console.log("[MandataireContractDetail] handleChangeEndDateSubmit", values);
    try {
      await employeeConnector.setOfferEndDate(contract.offer_id, values);

      setOpen(!open);
      getContract();
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error(
        "[MandataireContractDetail] handleChangeEndDateSubmit",
        error
      );
    }
  };

  /**
   * Get contract details
   * @returns {void}
   */
  const getContract = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getContractMandataire(id);
      if (result) {
        const contract = result.data.results;
        const offerStatus = await employeeConnector.getOfferInfo(
          contract.offer_id
        );
        const { offer } = offerStatus.data.results;
        setContract({
          ...contract,
          to_change_end_date: offer.to_change_end_date,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[MandataireContractDetail] getContract", error);
    }
  }, [employeeConnector, alert, id]);

  /**
   * Refresh contract status
   * @returns {void}
   */
  const contractRefresh = useCallback(async () => {
    try {
      const result = await employeeConnector.getContractMandataire(id);
      if (result) {
        const newStatus = result.data.results;
        if (
          newStatus.to_read !== contract.to_read ||
          newStatus.to_sign !== contract.to_sign
        ) {
          setContract({ ...contract, ...newStatus });
          setSignUrl(null);
        } else {
          setTimeout(
            () => setContract({ ...contract, ...newStatus }),
            appConfig.webHookRefreshFrequency
          );
        }
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[MandataireContractDetail] contractRefresh", error);
    }
  }, [employeeConnector, alert, id, contract]);

  /**
   * Get date change reasons list
   * @returns {void}
   */
  const getDateChangeReasons = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getOffersDateChange(
        contract.offer_id
      );
      if (result) {
        setDateChangeReasons(result.data.results.reasons);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[MandataireContractDetail] getDateChangeReasons", error);
    }
  }, [employeeConnector, alert, contract]);

  /**
   * Render action button or iframe if YouSign url is ready and in desktop mode
   * @returns {Object}
   */
  const renderContractAction = () => {
    if (!contract.to_read && !contract.to_sign) return null;

    const action = contract.to_read ? actions["to_read"] : actions["to_sign"];

    return (
      <Box display="grid" justifyContent="center">
        {signUrl && !isMobileDevice() ? (
          <Box mt="1em">
            <iframe
              style={{ width: "70em", height: "70em" }}
              title="sign-contract"
              src={signUrl}
            />
          </Box>
        ) : (
          <Button variant="contained" onClick={() => handleActionClick(action)}>
            {t(`contract.action.${action}`)}
          </Button>
        )}
      </Box>
    );
  };

  /**
   * Render date change dialog for desktop mode
   * @returns {Object}
   */
  const renderDateChangeDialog = () => {
    return (
      <Dialog open={open} onClose={() => setOpen(!open)}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">
              {t("contract.date-change.title")}
            </Typography>
            <IconButton pt="0" onClick={() => setOpen(!open)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DateEndChangeFrom
            onSubmit={handleChangeEndDateSubmit}
            reasons={dateChangeReasons}
          />
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * Render date change dialog for mobile mode
   * @returns {Object}
   */
  const renderDateChangeDrawer = () => {
    return (
      <Drawer anchor="bottom" open={open} onClose={() => setOpen(!open)}>
        <Box m="1em">
          <Box display="flex" justifyContent="space-between">
            <Typography ml="20%" variant="h4">
              {t("contract.date-change.title")}
            </Typography>
            <IconButton pt="0" onClick={() => setOpen(!open)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <DateEndChangeFrom
              onSubmit={handleChangeEndDateSubmit}
              reasons={dateChangeReasons}
            />
          </Box>
        </Box>
      </Drawer>
    );
  };

  useEffect(() => {
    if (contract === null && employeeConnector && !loading) {
      getContract();
    } /*
    if (signUrl !== null && employeeConnector) {
      contractRefresh();
    }*/

    if (
      dateChangeReasons === null &&
      employeeConnector &&
      contract &&
      !loading
    ) {
      getDateChangeReasons();
    }
  }, [
    contract,
    getContract,
    signUrl,
    contractRefresh,
    dateChangeReasons,
    getDateChangeReasons,
    employeeConnector,
    loading,
  ]);

  return (
    <Box>
      {loading ? (
        <Box mt="5em" display="grid" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      {contract ? (
        <Box>
          <Box display="flex" className="view-title">
            <IconButton onClick={handleReturnClick}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h3">
              {t("contract.offer-num")}
              {contract.request_num}
            </Typography>
          </Box>
          <Box>
            <Card sx={cardSx}>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography pt="0.5em" variant="h4">
                    {t("contract.status.title")}
                  </Typography>
                  <Chip
                    label={t(
                      `contract.status.${statusMap.get(
                        contract.contract_status
                      )}`
                    )}
                    sx={{
                      borderRadius: "2em",
                      color:
                        colorStatus[statusMap.get(contract.contract_status)]
                          .text,
                      backgroundColor:
                        colorStatus[statusMap.get(contract.contract_status)]
                          .background,
                    }}
                  />
                </Box>
                <Typography mt="1em">{contract.header_msg_1}</Typography>
                <Typography mt="1em">{contract.header_msg_2}</Typography>
                {contract.to_sign || contract.to_read
                  ? renderContractAction()
                  : null}
              </CardContent>
            </Card>
            <Card sx={cardSx}>
              <CardContent>
                <Typography variant="h4">
                  {t("contract.family-info")}
                </Typography>
                <Typography mt="1em">{contract.customer_name}</Typography>
                <Typography mt="1em">
                  {t("contract.email")} {contract.customer_email}
                </Typography>
                <Typography mt="1em">
                  {t("contract.phone")} {contract.customer_phone}
                </Typography>
                <Typography mt="1em">
                  {t("contract.address")} {contract.customer_address}
                </Typography>
                <Link></Link>
              </CardContent>
            </Card>
            {contract.to_change_end_date === true &&
            dateChangeReasons &&
            !signUrl ? (
              <>
                <Box mt="1em" display="grid" justifyContent="right">
                  <Button
                    disabled={contract.change_sent}
                    onClick={handleChangeEndDateClick}
                    variant="contained"
                    color="error"
                  >
                    {t("contract.date-change.action")}
                  </Button>
                </Box>
                {isMobileDevice()
                  ? renderDateChangeDrawer()
                  : renderDateChangeDialog()}
              </>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default MandataireContractDetail;

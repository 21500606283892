import * as React from "react";
import styled from "styled-components/macro";
// import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useTranslation } from "react-i18next";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
  svg.profile-icon {
    width: 28px;
    height: 28px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const goToProfile = () => {
    // redirect to profile page
    navigate(`/profile`);
    setAnchorMenu(!anchorMenu);
  };

  const handleSignOut = async () => {
    // redirect to sign-out
    navigate(`/auth/sign-out`);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Tooltip title={t("auth.account")}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <AccountCircleIcon fontSize="large" className="profile-icon" />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={goToProfile}>{t("menu.user-profile")}</MenuItem>
        <MenuItem onClick={handleSignOut}>{t("auth.sign-out")}</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;

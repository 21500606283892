export const navMap = {
  SEARCHJOB: "job-offers",
  MYJOBS: "my-offers/applications",
  PROFIL: "profile",
  CONTACT: "",
  PARCOURS: "career",
  PLANNING: "planning",
  CONTRACTS: "contracts/contracts",
  MUTUELLE: "contracts/healthcares",
  BULLETINS: "payslips",
};

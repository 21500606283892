import React from "react";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet-async";

import { Divider, Typography } from "@mui/material";

function MyBlankOffers() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("menu.my-offers.my-offers")} />
      <Typography
        variant="h3"
        gutterBottom
        display="grid"
        className="view-title"
      ></Typography>

      <Divider my={6} />
    </React.Fragment>
  );
}

export default MyBlankOffers;

import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  IconButton,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";

import useAuth from "../../hooks/useAuth";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);

const PayslipList = (props) => {
  const { onRibViewClick } = props;
  const { employeeConnector } = useAuth();
  const { t } = useTranslation();
  const [payslips, setPayslips] = useState(null);
  const [ribRequired, setRibRequired] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Handle payslip file download
   * @param {string} payslip
   * @returns {void}
   */
  const handleDownloadPayslip = async (payslip) => {
    console.log("[PayslipList] handleDownloadPayslip", payslip);
    try {
      const result = await employeeConnector.getPayslipFile(
        payslip.bulletin_id
      );
      if (result) {
        window.open(result.data.results.url, "_blank");
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[PayslipList] handleDownloadPayslip", error);
    }
  };

  /**
   * Handle rib button interaction (see/send)
   * @returns {void}
   */
  const handleRibInteract = () => {
    console.log("[PayslipList] handleRibInteract");
    onRibViewClick(ribRequired);
  };

  /**
   * Get payslip list of user
   * @returns {void}
   */
  const getPayslips = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getPayslips();
      if (result) {
        setPayslips(result.data.results.bulletins);
        setRibRequired(result.data.results.rib_required !== 0 ? true : false);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[PayslipList] getPayslips", error);
      setLoading(false);
    }
  }, [employeeConnector]);

  /**
   * Render paylsip list of user
   * @returns {Array}
   */
  const renderPayslips = () => {
    if (!payslips) return null;
    return payslips.map((payslip) => (
      <Box key={payslip.bulletin_id}>
        <Box display="flex" justifyContent="space-between">
          <Typography pt="0.5em">
            {payslip.period} - {payslip.company}
          </Typography>
          <IconButton onClick={() => handleDownloadPayslip(payslip)}>
            <DownloadIcon />
          </IconButton>
        </Box>
        <Divider my={2} />
      </Box>
    ));
  };

  useEffect(() => {
    if (payslips === null && employeeConnector && !loading) {
      getPayslips();
    }
  }, [payslips, getPayslips, employeeConnector, loading]);

  return (
    <Box>
      <Typography
        variant="h3"
        gutterBottom
        display="grid"
        className="view-title"
      >
        {t("menu.payslips")}
      </Typography>

      <Card>
        <CardContent className="card-content">
          <Box display="grid" justifyContent="center">
            <Box mb="2em" display="grid" justifyContent="center">
              <Typography mb="1em">
                {t("payslips.payslips.description")}
              </Typography>
              <Button variant="contained" onClick={handleRibInteract}>
                {ribRequired
                  ? t("payslips.payslips.enter-rib")
                  : t("payslips.payslips.see-rib")}
              </Button>
            </Box>
            {!ribRequired ? (
              <Box>
                {loading && !payslips ? (
                  <Box mt="1em" display="flex" justifyContent="center">
                    <CircularProgress />
                    <Typography pt="0.8em" ml="1em">
                      {t("loader.loading")}
                    </Typography>
                  </Box>
                ) : (
                  renderPayslips()
                )}
              </Box>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PayslipList;

import React, { useEffect, useState, useCallback } from "react";

import {
  Box,
  Divider,
  Tab,
  Tabs,
  Typography,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";

import ContractList from "../../components/contracts/ContractList";
import Due from "../../components/contracts/Due";
import HealthCareList from "../../components/contracts/HealthCareList";

import { isMobileDevice } from "../../helpers/common.helpers";
import { SECTIONS } from "./constants";

const Card = styled(MuiCard)(spacing);

function ContractsView(props) {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [section, setSection] = useState(SECTIONS[props.section]);

  console.log(props, section);

  const handleSectionChange = (e, value) => {
    setSection(value);
  };

  const initSection = useCallback(() => {
    setSection(SECTIONS[props.section]);
  }, [props.section]);

  const renderSection = () => {
    if (section === SECTIONS.healthcares)
      return (
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            display="grid"
            className="view-title"
          >
            {t("contract.section.healthcare")}
          </Typography>
          <HealthCareList />
        </Box>
      );
    else if (section === SECTIONS.due)
      return (
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            display="grid"
            className="view-title"
          >
            {t("contract.section.due")}
          </Typography>
          <Card>
            <CardContent className="card-content">
              <Due />
            </CardContent>
          </Card>
        </Box>
      );

    return (
      <Box>
        <Typography
          variant="h3"
          gutterBottom
          display="grid"
          className="view-title"
        >
          {t("menu.contracts.contracts")}
        </Typography>

        <ContractList />
      </Box>
    );
  };

  const renderMobileSection = () => {
    return (
      <Box>
        <Typography variant="h3" display="grid" className="view-title">
          {t("menu.contracts.contracts")}
        </Typography>
        <Box mt="1em">
          <Tabs value={section} onChange={handleSectionChange}>
            <Tab label={t("contract.section.contracts")} />
            <Tab label={t("contract.section.due")} />
            <Tab label={t("contract.section.healthcare")} />
          </Tabs>
        </Box>
        <Divider my={6} />
        <Box mt="1em">
          <Box hidden={section !== SECTIONS.contracts}>
            <ContractList />
          </Box>
          <Box hidden={section !== SECTIONS.due}>
            <Due />
          </Box>
          <Box hidden={section !== SECTIONS.healthcares}>
            <HealthCareList />
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    initSection();
  }, [initSection]);

  if (!user) {
    return null;
  }

  return <>{isMobileDevice() ? renderMobileSection() : renderSection()}</>;
}

export default ContractsView;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LoadingButton } from "@mui/lab";

import {
  getHoursList,
  getMinutesList,
  timeIntervalIsValid,
} from "./helper-planning";

const Intervention = (props) => {
  const { onClose, refreshPlanning } = props;
  const { t, i18n } = useTranslation();
  const { employeeConnector } = useAuth();
  const alert = useAlert();
  const { language } = i18n;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const formik = useFormik({
    initialValues: {
      date: "",
      start_hour: "00",
      start_minute: "00",
      end_hour: "00",
      end_minute: "00",
      family: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validate: (values) => {
      const errors = {};

      if (!values.date) {
        errors.date = t("form.errors.required.field");
      }
      if (!values.start_hour) {
        errors.hour = t("form.errors.required.field");
      }
      if (!values.start_minute) {
        errors.minute = t("form.errors.required.field");
      }
      if (!values.end_hour) {
        errors.hour = t("form.errors.required.field");
      }
      if (!values.end_minute) {
        errors.minute = t("form.errors.required.field");
      }
      if (families && families.length > 0 && !values.family) {
        errors.family = t("form.errors.required.field");
      }

      if (
        values.start_hour &&
        values.start_minute &&
        values.end_hour &&
        values.end_minute &&
        !timeIntervalIsValid(
          values.start_hour,
          values.start_minute,
          values.end_hour,
          values.end_minute,
          60,
          720
        )
      )
        errors.hours = t("form.invalid.duration");

      console.log("[Intervention] validation", values, errors);
      return errors;
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [families, setFamilies] = useState(null);

  const selectSx = {
    minWidth: fullScreen ? "5em" : "8em",
  };

  const today = new Date();
  const maxDate = today;
  maxDate.setDate(today.getDate() - 1);
  const minDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  //console.log("[Intervention] min and max dates:", minDate, maxDate);

  /**
   * Handle datepicker value changing
   * @param {Date} value
   */
  const handleDateChange = (value) => {
    formik.setFieldValue("date", value);
    setDate(value);
  };

  const handleSubmit = async (values) => {
    console.log("[Intervention] handleSubmit", values);
    const familiesFound =
      families && Array.isArray(families) && families.length > 0;

    const request = familiesFound
      ? employeeConnector.putIntervention
      : employeeConnector.getFamiliesForDate;

    const data = familiesFound
      ? {
          start_hour: `${values.start_hour}${values.start_minute}`,
          end_hour: `${values.end_hour}${values.end_minute}`,
          start_date: values.date,
          deal_id: values.family,
        }
      : values.date;

    setLoading(true);
    try {
      const result = await request(data);
      if (result) {
        setLoading(false);
        if (familiesFound) {
          alert.success({ message: t("alert.success.add") });
          minDate.setMonth(minDate.getMonth() - 1);
          maxDate.setMonth(maxDate.getMonth() + 1);
          refreshPlanning({ startDate: minDate, endDate: maxDate });
          onClose();
        } else {
          const familiesList = result.data.results;
          if (familiesList.length === 1) {
            formik.setFieldValue("family", familiesList[0].deal);
          }
          setFamilies(familiesList);
        }
      }
    } catch (error) {
      setLoading(false);
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[Intervention]", error);
    }
  };

  const renderHoursList = () => {
    const list = getHoursList(0, 23);

    return list.map((hour) => (
      <MenuItem value={hour} key={hour}>
        {hour}
      </MenuItem>
    ));
  };

  const renderMinutesList = () => {
    const list = getMinutesList(15);

    return list.map((minute) => (
      <MenuItem value={minute} key={minute}>
        {minute}
      </MenuItem>
    ));
  };

  const renderFamilySelect = () => {
    if (!families || !Array.isArray(families)) return null;

    if (families.length === 0)
      return (
        <Box mb="1em">
          <Typography>{t("planning.intervention.no-contract")}</Typography>
        </Box>
      );

    const list = families.map((family) => (
      <MenuItem value={family.deal} key={family.family_name}>
        {family.family_name}
      </MenuItem>
    ));

    return (
      <Box mb="1em">
        <Box mb="1em">
          <Typography>
            {t(
              `planning.intervention.${
                families.length > 1 ? "one" : "several"
              }-contract`
            )}
          </Typography>
        </Box>
        <FormControl error={Boolean(formik.errors && formik.errors.family)}>
          <InputLabel id="intervention-family-select-label">
            {t("planning.intervention.family")}
          </InputLabel>
          <Select
            sx={{ ...selectSx, marginRight: "2em" }}
            labelId="intervention-family-select-label"
            id="family"
            name="family"
            label={t("planning.intervention.family")}
            value={formik.values.family}
            onChange={formik.handleChange}
          >
            {list}
          </Select>
        </FormControl>
        <FormHelperText error>{formik.errors.family}</FormHelperText>
      </Box>
    );
  };

  return (
    <Box>
      <DialogTitle display="flex" justifyContent="space-between">
        <Typography variant="h4">{t("planning.intervention.title")}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box mb="1em">
            <Typography mb="0.5em" variant="h6">
              {t("planning.intervention.date")}
            </Typography>
            <FormControl error={Boolean(formik.errors && formik.errors.date)}>
              <DatePicker
                id="date"
                name="date"
                value={date}
                maxDate={maxDate}
                minDate={minDate}
                inputFormat={language === "fr" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
                disabled={Boolean(
                  families && Array.isArray(families) && families.length > 0
                )}
              />

              <FormHelperText>{formik.errors.date}</FormHelperText>
            </FormControl>
          </Box>
          <Box mb="1em">
            <Typography mb="0.5em" variant="h6">
              {t("planning.intervention.start-time")}
            </Typography>

            <Box sx={{ display: "flex", marginBottom: "1em" }}>
              <Box>
                <FormControl
                  error={Boolean(formik.errors && formik.errors.hours)}
                >
                  <InputLabel id="intervention-start-hour-select-label">
                    {t("planning.intervention.hour")}
                  </InputLabel>
                  <Select
                    sx={{ ...selectSx, marginRight: "2em" }}
                    labelId="intervention-start-hour-select-label"
                    id="start_hour"
                    name="start_hour"
                    label={t("planning.intervention.hour")}
                    value={formik.values.start_hour}
                    onChange={formik.handleChange}
                    disabled={Boolean(
                      families && Array.isArray(families) && families.length > 0
                    )}
                  >
                    {renderHoursList()}
                  </Select>
                </FormControl>
                <FormHelperText error>{formik.errors.hours}</FormHelperText>
              </Box>
              <Box>
                <FormControl
                  error={Boolean(formik.errors && formik.errors.hours)}
                >
                  <InputLabel id="intervention-start-minute-select-label">
                    {t("planning.intervention.minute")}
                  </InputLabel>
                  <Select
                    sx={{ ...selectSx, marginRight: "2em" }}
                    labelId="intervention-start-minute-select-label"
                    id="start_minute"
                    name="start_minute"
                    label={t("planning.intervention.minute")}
                    value={formik.values.start_minute}
                    onChange={formik.handleChange}
                    disabled={Boolean(
                      families && Array.isArray(families) && families.length > 0
                    )}
                  >
                    {renderMinutesList()}
                  </Select>
                </FormControl>
                <FormHelperText error>{formik.errors.hours}</FormHelperText>
              </Box>
            </Box>
          </Box>

          <Box mb="1em">
            <Typography mb="0.5em" variant="h6">
              {t("planning.intervention.end-time")}
            </Typography>

            <Box sx={{ display: "flex", marginBottom: "1em" }}>
              <Box>
                <FormControl
                  error={Boolean(formik.errors && formik.errors.hours)}
                >
                  <InputLabel id="intervention-end-hour-select-label">
                    {t("planning.intervention.hour")}
                  </InputLabel>
                  <Select
                    sx={{ ...selectSx, marginRight: "2em" }}
                    labelId="intervention-end-hour-select-label"
                    id="end_hour"
                    name="end_hour"
                    label={t("planning.intervention.hour")}
                    value={formik.values.end_hour}
                    onChange={formik.handleChange}
                    disabled={Boolean(
                      families && Array.isArray(families) && families.length > 0
                    )}
                  >
                    {renderHoursList()}
                  </Select>
                </FormControl>
                <FormHelperText error>{formik.errors.hours}</FormHelperText>
              </Box>
              <Box>
                <FormControl
                  error={Boolean(formik.errors && formik.errors.hours)}
                >
                  <InputLabel id="intervention-end-minute-select-label">
                    {t("planning.intervention.minute")}
                  </InputLabel>
                  <Select
                    sx={{ ...selectSx, marginRight: "2em" }}
                    labelId="intervention-end-minute-select-label"
                    id="end_minute"
                    name="end_minute"
                    label={t("planning.intervention.minute")}
                    value={formik.values.end_minute}
                    onChange={formik.handleChange}
                    disabled={Boolean(
                      families && Array.isArray(families) && families.length > 0
                    )}
                  >
                    {renderMinutesList()}
                  </Select>
                </FormControl>
                <FormHelperText error>{formik.errors.hours}</FormHelperText>
              </Box>
            </Box>
          </Box>
          {families ? renderFamilySelect() : null}
          <LoadingButton loading={loading} variant="contained" type="submit">
            {t(
              `planning.intervention.${
                families && Array.isArray(families) && families.length > 0
                  ? "submit"
                  : "validate"
              }`
            )}
          </LoadingButton>
        </form>
      </DialogContent>
    </Box>
  );
};

export default Intervention;

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { useTranslation } from "react-i18next";

import { Box, Button as MuiButton, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import { navMap } from "./constants";

const Button = styled(MuiButton)(spacing);

const DashBoardModule = (props) => {
  const { module } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  /**
   * Handle click on module link
   * @returns {void}
   */
  const handleModuleActionClick = () => {
    navigate(`/${navMap[module]}`);
  };

  return (
    <Box>
      <Typography variant="h4" mb="1em">
        {t(`dashboard.${module}.title`)}
      </Typography>
      <Typography mb="1em">{t(`dashboard.${module}.description`)}</Typography>
      <Box display="grid" justifyContent="center">
        <Button variant="contained" onClick={handleModuleActionClick}>
          {t(`dashboard.${module}.action`)}
        </Button>
      </Box>
    </Box>
  );
};

export default DashBoardModule;

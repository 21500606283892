export const parsePlanning = (planning) => {
  if (!planning || !Array.isArray(planning)) return [];
  const events = planning.map((e) => {
    return {
      ...e,
      start: `${e.start.slice(0, 4)}-${e.start.slice(4, 6)}-${e.start.slice(
        6,
        8
      )}T${e.start.slice(8, 10)}:${e.start.slice(10)}:00`,
      end: `${e.end.slice(0, 4)}-${e.end.slice(4, 6)}-${e.end.slice(
        6,
        8
      )}T${e.end.slice(8, 10)}:${e.end.slice(10)}:00`,
      title: e.customer,
      display: "block",
      backgroundColor: e.bgcolor,
    };
  });

  return events;
};

export const planningLocked = () => {
  const today = new Date();
  return today.getDate() <= 9 && today.getDate() !== 1;
};

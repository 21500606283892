import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import styled from "styled-components/macro";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import { Box, Button as MuiButton, TextField, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { isMobileDevice } from "../../helpers/common.helpers";

import { appConfig } from "../../config";

const intlUtilsScript = appConfig.scripts["intl-tel-input"].url;

const Button = styled(MuiButton)(spacing);

const UserInformationsForm = (props) => {
  const { handleCloseForm } = props;
  const { user, setAppUserProfile, employeeConnector } = useAuth();
  const { t } = useTranslation();
  const alert = useAlert();
  const [intlTel, setIntlTel] = useState(null);

  const handlePhoneNumbererror = (value) => {
    const { phonecountrycode } = formik.values;
    const phone = window.intlTelInputUtils.formatNumber(
      value,
      phonecountrycode,
      window.intlTelInputUtils.numberFormat.INTERNATIONAL
    );
    const phoneFormatError = window.intlTelInputUtils.getValidationError(
      phone,
      phonecountrycode
    );
    if (phoneFormatError !== 0) {
      return false;
    }

    return true;
  };

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    phone: yup
      .mixed("Enter your phone number")
      .required("Phone number is requried")
      .test(
        "invalid-length",
        "Phone number length is invalid for selected country",
        handlePhoneNumbererror
      ),
    line: yup.string("Enter your address").required("Address is required"),
    zipcode: yup.string("Enter your zipcode").required("Zipcode is required"),
    city: yup.string("Enter your city").required("City is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: user.email,
      phone: user.mobile.phone,
      phonecountrycode: user.mobile.countrycode,
      phoneidentificationcode: user.mobile.identificationcode,
      line: user.address.line,
      zipcode: user.address.zipcode,
      city: user.address.city,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const sxTextField = {
    width: "100%",
  };

  /**
   * Handle form submit
   * @param {Object} values
   * @returns {void}
   */
  const handleSubmit = async (values) => {
    const phone = window.intlTelInputUtils.formatNumber(
      values.phone,
      values.phonecountrycode,
      window.intlTelInputUtils.numberFormat.INTERNATIONAL
    );

    const data = {
      ...values,
      phone,
      firstname: user.firstname,
      lastname: user.lastname,
      language: user.language,
      onboarding: 1,
    };

    try {
      const response = await employeeConnector.updateProfile(data);
      if (response) {
        setAppUserProfile(response);
        setIntlTel(null);
        handleCancelEdit();
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[UserInformations] handleSubmit", error);
    }
  };

  /**
   * Handle cancel edit
   * @returns {void}
   */
  const handleCancelEdit = () => {
    formik.resetForm();
    setIntlTel(null);
    handleCloseForm();
  };

  /**
   * Handle phone field changes
   * @param {Object} event
   * @param {Object} intlTel object
   * @returns {void}
   */
  const handlePhoneNumberChange = useCallback(
    (e, intlTel) => {
      const phonecountrycode = intlTel.selectedCountryData.iso2;
      const phoneidentificationcode = intlTel.selectedCountryData.dialCode;

      formik.handleChange({
        ...e,
        target: {
          name: "phonecountrycode",
          value: phonecountrycode,
        },
      });

      formik.handleChange({
        ...e,
        target: {
          name: "phoneidentificationcode",
          value: phoneidentificationcode,
        },
      });
    },
    [formik]
  );

  useEffect(() => {
    if (intlTel === null) {
      const telInput = document.querySelector("#phone");
      const intlTelInputObject = intlTelInput(telInput, {
        preferredCountries: ["fr", "gb", "us"],
        utilsScript: intlUtilsScript,
        separateDialCode: true,
        initialCountry: user.mobile.countrycode
          ? user.mobile.countrycode
          : "FR",
      });

      intlTelInputObject.promise.then(() => {
        console.info("[DEBUG] intlTelInput utilsScript loaded !");

        if (typeof window.intlTelInputUtils === "undefined") {
          throw new Error("intlTelInputUtils not loaded !");
        }
      });

      telInput.addEventListener("change", (e) =>
        handlePhoneNumberChange(e, intlTelInputObject)
      );
      telInput.addEventListener("keyup", (e) =>
        handlePhoneNumberChange(e, intlTelInputObject)
      );
      telInput.addEventListener("countrychange", (e) => {
        intlTelInputObject.setNumber("");
        handlePhoneNumberChange(e, intlTelInputObject);
      });

      setIntlTel(intlTelInputObject);
    }
  }, [user.mobile.countrycode, handlePhoneNumberChange, intlTel]);

  return (
    <Box>
      <Box>
        <Box
          display={isMobileDevice() ? "block" : "flex"}
          justifyContent="space-between"
          mb="1em"
          mt={isMobileDevice() ? "2em" : 0}
        >
          <Box sx={sxTextField} mr="1em" mb="1em">
            <Typography mb="0.5em" variant="h6">
              {t("user-profile.firstname")}
            </Typography>
            <TextField
              sx={sxTextField}
              disabled
              defaultValue={user.firstname}
            />
          </Box>
          <Box sx={sxTextField} ml={isMobileDevice() ? 0 : "1em"}>
            <Typography mb="0.5em" variant="h6">
              {t("user-profile.lastname")}
            </Typography>
            <TextField sx={sxTextField} disabled defaultValue={user.lastname} />
          </Box>
        </Box>

        <Box mb="1em">
          <Typography mb="0.5em" variant="h6">
            {t("user-profile.nationality")}
          </Typography>
          <TextField
            sx={sxTextField}
            disabled
            defaultValue={user.nationalityObj.label}
          />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box
            display={isMobileDevice() ? "block" : "flex"}
            justifyContent="space-between"
            mb="1em"
          >
            <Box sx={sxTextField} mr="1em" mb="1em">
              <Typography mb="0.5em" variant="h6">
                {t("user-profile.phoneNumber")}
              </Typography>
              <TextField
                sx={sxTextField}
                inputProps={{ sx: { height: "4em" } }}
                id="phone"
                name="phone"
                defaultValue={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur("phone")}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Box>
            <Box sx={sxTextField} ml={isMobileDevice() ? 0 : "1em"}>
              <Typography mb="0.5em" variant="h6">
                {t("user-profile.email")}
              </Typography>
              <TextField
                sx={sxTextField}
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
          </Box>
          <Box mb="1em">
            <Typography mb="0.5em" variant="h6">
              {t("user-profile.address")}
            </Typography>
            <TextField
              sx={sxTextField}
              id="line"
              name="line"
              value={formik.values.line}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.line && Boolean(formik.errors.line)}
              helperText={formik.touched.line && formik.errors.line}
            />
          </Box>

          <Box
            display={isMobileDevice() ? "block" : "flex"}
            justifyContent="space-between"
            mb="1em"
          >
            <Box sx={sxTextField} mr="1em" mb="1em">
              <Typography mb="0.5em" variant="h6">
                {t("user-profile.zipcode")}
              </Typography>
              <TextField
                sx={sxTextField}
                id="zipcode"
                name="zipcode"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
                helperText={formik.touched.zipcode && formik.errors.zipcode}
              />
            </Box>
            <Box sx={sxTextField} ml={isMobileDevice() ? 0 : "1em"}>
              <Typography mb="0.5em" variant="h6">
                {t("user-profile.city")}
              </Typography>
              <TextField
                sx={sxTextField}
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" mt="1em">
            <Button mr="1em" onClick={handleCancelEdit}>
              {t("user-profile.cancel")}
            </Button>
            <Button variant="contained" type="submit">
              {t("user-profile.save")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default UserInformationsForm;

import { useContext } from "react";

import { AlertContext } from "../contexts/AlertContext";

const useAlert = () => {
  const context = useContext(AlertContext);

  if (!context)
    throw new Error("AlertContext must be placed within AuthProvider");

  return context;
};

export default useAlert;

import React from "react";
import { useTranslation } from "react-i18next";

import { appConfig } from "../../config";

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { ArrowBack, Assignment } from "@mui/icons-material";

import YoutubeEmbed from "../message/YoutubeEmbed";

const TrainingSection = ({ section, onReturn }) => {
  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const pdfUrl = (pdf) =>
    `${appConfig.samPublicUrl}training/pdf/${pdf}_${language}.pdf`;

  const resources = {
    creative: {
      videos: [
        { title: "creative", url: "_Q7pW0H4Pw8" },
        { title: "activities", url: "HUxa636Wixg" },
        { title: "pedagogy", url: "ALJcsgtfTVc" },
      ],
      documents: [
        {
          title: "good-start",
          url: pdfUrl(language === "fr" ? "Démarrage" : "Starting"),
        },
        {
          title: "safety",
          url: pdfUrl(language === "fr" ? "Sécurité" : "Security"),
        },
        {
          title: "authority",
          url: pdfUrl(language === "fr" ? "Autorité" : "Autority"),
        },
        {
          title: "activities",
          url: pdfUrl(language === "fr" ? "Activités" : "Activities"),
        },
      ],
    },
    initiation: {
      videos: [
        { title: "pedagogy", url: "ny0FWUB2aY0" },
        { title: "behaviour", url: "joKqI90H1eE" },
        { title: "vocabulary", url: "nvqiZgIiwds" },
        { title: "organization", url: "fdbH0To22Mo" },
        { title: "adaptability", url: "099bg9XavaY" },
      ],
      documents: [
        {
          title: "good-start",
          url: pdfUrl(language === "fr" ? "Démarrage" : "Starting"),
        },
        {
          title: "safety",
          url: pdfUrl(language === "fr" ? "Sécurité" : "Security"),
        },
        {
          title: "authority",
          url: pdfUrl(language === "fr" ? "Autorité" : "Autority"),
        },
        {
          title: "activities",
          url: pdfUrl(language === "fr" ? "Activités" : "Activities"),
        },
        {
          title: "teach",
          url: pdfUrl(language === "fr" ? "Langues" : "Languages"),
        },
      ],
    },
    immersion: {
      videos: [
        { title: "pedagogy", url: "rMK1b8cg3tg" },
        { title: "approach", url: "v945gbn__Sw" },
        { title: "resort", url: "0WnOuv_V2CI" },
        { title: "reassure", url: "UJqs50IK8ZI" },
        { title: "rituals", url: "u-VSSDm7TvQ" },
      ],
      documents: [
        {
          title: "good-start",
          url: pdfUrl(language === "fr" ? "Démarrage" : "Starting"),
        },
        {
          title: "safety",
          url: pdfUrl(language === "fr" ? "Sécurité" : "Security"),
        },
        {
          title: "authority",
          url: pdfUrl(language === "fr" ? "Autorité" : "Autority"),
        },
        {
          title: "activities",
          url: pdfUrl(language === "fr" ? "Activités" : "Activities"),
        },
        {
          title: "teach",
          url: pdfUrl(language === "fr" ? "Langues" : "Languages"),
        },
      ],
    },
  };

  const handleReturn = () => {
    onReturn();
  };

  const renderVideos = () => {
    return resources[section].videos.map((video) => (
      <Box
        sx={{ marginRight: "2em", marginBottom: "1em", textAlign: "center" }}
        key={`video-${video.url}`}
      >
        <Typography variant="h5">
          {t(`resources.training.${section}.section.videos.${video.title}`)}
        </Typography>
        <Box mt="0.5em">
          <YoutubeEmbed key="video" embedId={video.url} />
        </Box>
      </Box>
    ));
  };

  const renderDocuments = () => {
    return resources[section].documents.map((document) => (
      <Box
        sx={{
          marginRight: "2em",
          borderStyle: "solid",
          borderWidth: "1px",
          width: "280px",
        }}
        mr="2em"
        mb="1em"
        key={`video-${document.title}`}
      >
        <Box sx={{ backgroundColor: "primary.dark", display: "flex" }}>
          <Box
            sx={{
              margin: "auto",
              marginTop: "1em",
              marginBottom: "1em",
              maxHeight: "32.5px",
            }}
          >
            <Assignment fontSize="large" sx={{ color: "white" }} />
          </Box>
        </Box>
        <Box p="0.5em">
          <Box minHeight="40px">
            <Typography sx={{ fontWeight: "bold" }}>
              {t(`resources.training.${section}.section.${document.title}`)}
            </Typography>
          </Box>
          <Box mt="1em" textAlign="right">
            <Link
              href={document.url}
              target="_blank"
              sx={{ cursor: "pointer" }}
            >
              {t("resources.training.download")}
            </Link>
          </Box>
        </Box>
      </Box>
    ));
  };

  return (
    <Box>
      <Box mb="1em" display="flex">
        <IconButton onClick={handleReturn}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h4">
          {t(`resources.training.${section}.section.title`)}
        </Typography>
      </Box>
      <Box mb="1em">
        <Card>
          <CardContent>
            <Typography variant="h4">
              {t(`resources.training.videos`)}
            </Typography>
            <Box
              mt="1em"
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
            >
              {renderVideos()}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card>
          <CardContent>
            <Typography variant="h4">
              {t(`resources.training.documents`)}
            </Typography>
            <Box
              mt="1em"
              display="flex"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              {renderDocuments()}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default TrainingSection;

import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { spacing } from "@mui/system";

import { dateToString } from "./helper-contract";

const Button = styled(MuiButton)(spacing);

const DateEndChangeForm = (props) => {
  const { onSubmit, reasons } = props;
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const formik = useFormik({
    initialValues: {
      change_reason: "",
      change_date: dateToString(new Date()),
    },
    onSubmit,
  });
  const [date, setDate] = useState(new Date());

  /**
   * Handle datepicker value changing
   * @param {Date} value
   */
  const handleDateChange = (value) => {
    formik.setFieldValue("change_date", dateToString(value));
    setDate(value);
  };

  /**
   * Render date change reasons list
   * @returns {Array}
   */
  const renderReasonList = () => {
    return reasons.map((reason) => (
      <MenuItem key={reason.value} value={reason.value}>
        {reason.label}
      </MenuItem>
    ));
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Typography mb="0.5em">{t("contract.date-change.reason")}</Typography>
        <FormControl>
          <Select
            sx={{ minWidth: "16.5em" }}
            value={formik.values.change_reason}
            id="change_reason"
            name="change_reason"
            onChange={formik.handleChange}
          >
            {renderReasonList()}
          </Select>
        </FormControl>
        <Typography mb="0.5em" mt="2em">
          {t("contract.date-change.available-until")}
        </Typography>
        <FormControl>
          <DatePicker
            id="change_date"
            name="change_date"
            disablePast
            value={date}
            inputFormat={language === "fr-FR" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
        <Box display="grid" justifyContent="right" mt="1em">
          <Button variant="contained" type="submit">
            {t("contract.date-change.validate")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default DateEndChangeForm;

import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import { isMobileDevice } from "../../helpers/common.helpers";

import DocumentGroup from "./DocumentGroup";
import {
  statuses,
  statusDisplay,
  documentGroupTypes,
  identiteTypes,
} from "./constants";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";

const RequiredDocuments = (props) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const { employeeConnector } = useAuth();
  const [justificatifTypes, setJustificatifTypes] = useState(null);
  const [justificatif, setJustificatif] = useState(null);
  const [documentsStatus, setDocumentsStatus] = useState(null);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("none");
  const [loading, setLoading] = useState(false);

  console.log("[RequiredDocuments] status:", documentsStatus);

  /**
   * Handle document managing dialog or drawer opening
   * @param {String} target
   * @returns {void}
   */
  const handleOpenUploadDialog = (target) => {
    setOpenUploadDialog(!openUploadDialog);
    if (target !== "none") setDialogContent(target);
  };

  /**
   * Handle document upload success
   * @returns {void}
   */
  const handleDocumentUpload = async () => {
    getDocumentsStatus();
  };

  /**
   * Handle document upload success
   * @param {Object} String justificatifType
   * @returns {void}
   */
  const handleJustificatifTypeChange = (justificatifType) => {
    setJustificatif({
      value: justificatifType.employeeJustificatifType,
      show: justificatifType.to_show,
      canChange: justificatifType.to_change,
    });
  };

  /**
   * Get status of user's documents
   * @returns {void}
   */
  const getDocumentsStatus = useCallback(async () => {
    let result = statuses.UNDEFINED;
    if (!loading) setLoading(true);
    try {
      const response = await employeeConnector.getDocumentsStatus();
      if (response) {
        result = {
          idCard:
            statuses[response.employeeStatus.documentGroupe.identite.status],
          justificatif:
            statuses[
              response.employeeStatus.documentGroupe.justificatif.status
            ],
          other: statuses[response.employeeStatus.documentGroupe.other.status],
        };
        setDocumentsStatus(result);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[CareerView] getDocumentsStatus", error);
    }
  }, [alert, employeeConnector, loading]);

  /**
   * Get justificaif types list
   * @returns {void}
   */
  const getJustificatifTypes = useCallback(async () => {
    if (!loading) setLoading(true);
    try {
      const response = await employeeConnector.getJustificatifTypes();
      if (response) {
        const result = response["fr-FR"].map((value, index) => {
          return {
            value: value[0],
            label: { fr: value[1], en: response["en-GB"][index][1] },
          };
        });

        setJustificatifTypes(result);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[CareerView] getJustificatifTypes", error);
    }
  }, [alert, employeeConnector, loading]);

  /**
   * Get user current justificatif type
   * @returns {void}
   */
  const getJustificatifType = useCallback(async () => {
    if (!loading) setLoading(true);
    try {
      const response = await employeeConnector.getJustificatifType();
      if (response) {
        setJustificatif({
          value: response.employeeJustificatifType,
          show: response.to_show,
          canChange: response.to_change,
        });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[CareerView] getJustificatifType", error);
    }
  }, [alert, employeeConnector, loading]);

  const getGroups = () => {
    if (dialogContent === documentGroupTypes.OTHER) return [];
    else
      return dialogContent === documentGroupTypes.JUSTIFICATIF
        ? justificatifTypes
        : identiteTypes;
  };

  /**
   * Render user required documents status
   * @returns {Object}
   */
  const renderRequiredDocuments = () => {
    return (
      <Box mt="2em" display="flex" justifyContent="space-between">
        <Box>
          <Typography mb="1em" variant="h6">
            {t("career.documents.JUSTIFICATIF")}
          </Typography>
          <Typography
            color={statusDisplay[documentsStatus?.justificatif]?.color}
            variant="h6"
          >
            {loading && !statusDisplay ? (
              <CircularProgress size="1.125em" />
            ) : null}
            {statusDisplay[documentsStatus?.justificatif]?.label
              ? t(
                  `career.documents.status.${
                    statusDisplay[documentsStatus?.justificatif]?.label
                  }`
                )
              : null}
          </Typography>
        </Box>
        <Box>
          <IconButton
            disabled={loading}
            onClick={() =>
              handleOpenUploadDialog(documentGroupTypes.JUSTIFICATIF)
            }
          >
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  /**
   * Render user additionnal documents status
   * @returns {Object}
   */
  const renderAdditionnalDocuments = () => {
    return (
      <Box mt="2em" display="flex" justifyContent="space-between">
        <Box>
          <Typography mb="1em" variant="h6">
            {t("career.documents.OTHER")}
          </Typography>
          <Typography
            color={statusDisplay[documentsStatus?.other]?.color}
            variant="h6"
          >
            {loading && !statusDisplay ? (
              <CircularProgress size="1.125em" />
            ) : null}
            {statusDisplay[documentsStatus?.other]?.label
              ? t(
                  `career.documents.status.${
                    statusDisplay[documentsStatus?.other]?.label
                  }`
                )
              : null}
          </Typography>
        </Box>
        <IconButton
          disabled={loading}
          onClick={() => handleOpenUploadDialog(documentGroupTypes.OTHER)}
        >
          <EditIcon />
        </IconButton>
      </Box>
    );
  };

  /**
   * Render document managing drawer
   * @returns {Object}
   */
  const renderDrawer = () => {
    return (
      <Drawer
        anchor="bottom"
        open={openUploadDialog}
        onClose={() => handleOpenUploadDialog("none")}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography pt="0.5em" pl="25%" variant="h4">
            {dialogContent !== "none"
              ? t(`career.documents.${dialogContent}`)
              : null}
          </Typography>
          <IconButton onClick={() => handleOpenUploadDialog("none")}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box p="1em">
          <DocumentGroup
            groups={getGroups()}
            groupType={dialogContent}
            currentGroup={
              justificatif?.value !== "888888" &&
              dialogContent === documentGroupTypes.JUSTIFICATIF
                ? justificatif.value
                : ""
            }
            canChangeGroup={
              dialogContent === documentGroupTypes.JUSTIFICATIF
                ? justificatif?.canChange
                : true
            }
            onJustificatifValueChange={handleJustificatifTypeChange}
            onDocumentUpload={handleDocumentUpload}
          />
        </Box>
      </Drawer>
    );
  };

  /**
   * Render document managing dialog
   * @returns {Object}
   */
  const renderDialog = () => {
    return (
      <Dialog
        open={openUploadDialog}
        onClose={() => handleOpenUploadDialog("none")}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">
              {dialogContent !== "none"
                ? t(`career.documents.${dialogContent}`)
                : null}
            </Typography>
            <IconButton onClick={() => handleOpenUploadDialog("none")}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DocumentGroup
            groups={getGroups()}
            groupType={dialogContent}
            currentGroup={
              justificatif?.value !== "888888" &&
              dialogContent === documentGroupTypes.JUSTIFICATIF
                ? justificatif.value
                : ""
            }
            canChangeGroup={
              dialogContent === documentGroupTypes.JUSTIFICATIF
                ? justificatif?.canChange
                : true
            }
            onJustificatifValueChange={handleJustificatifTypeChange}
            onDocumentUpload={handleDocumentUpload}
          />
        </DialogContent>
      </Dialog>
    );
  };

  useEffect(() => {
    if (documentsStatus === null && !loading && employeeConnector) {
      getDocumentsStatus();
    }
    if (justificatifTypes === null && !loading && employeeConnector) {
      getJustificatifTypes();
    }

    if (justificatif === null && !loading && employeeConnector) {
      getJustificatifType();
    }
    if (documentsStatus && justificatifTypes && justificatif && loading) {
      setLoading(false);
    }
  }, [
    documentsStatus,
    getDocumentsStatus,
    justificatifTypes,
    getJustificatifTypes,
    justificatif,
    getJustificatifType,
    loading,
    employeeConnector,
  ]);

  return (
    <>
      <Typography mb="2em" variant="h4">
        {t("career.documents.documents")}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography mb="1em" variant="h6">
            {t("career.documents.IDENTITE")}
          </Typography>
          <Typography
            color={statusDisplay[documentsStatus?.idCard]?.color}
            variant="h6"
          >
            {loading && !statusDisplay ? (
              <CircularProgress size="1.125em" />
            ) : null}
            {statusDisplay[documentsStatus?.idCard]?.label
              ? t(
                  `career.documents.status.${
                    statusDisplay[documentsStatus?.idCard]?.label
                  }`
                )
              : null}
          </Typography>
        </Box>
        <IconButton
          disabled={loading}
          onClick={() => handleOpenUploadDialog(documentGroupTypes.IDENTITE)}
        >
          <EditIcon />
        </IconButton>
      </Box>
      {justificatif !== null && justificatif.show
        ? renderRequiredDocuments()
        : null}
      {justificatif !== null && documentsStatus?.other !== statuses.EMPTY
        ? renderAdditionnalDocuments()
        : null}
      {isMobileDevice() ? renderDrawer() : renderDialog()}
    </>
  );
};

export default RequiredDocuments;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";

import { Box, Link, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import { navMap } from "./constants";
import Message from "../message/Message";

const Todo = (props) => {
  const { step } = props;
  const { employeeConnector, setAppUserNotifications } = useAuth();
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);

  /**
   * Handle click on link
   * @param {string} action
   * @returns {void}
   */
  const handleActionClick = (action) => {
    console.log("[Todo] handleActionClick", action);

    switch (action.type) {
      case "internal":
        navigate(`/${navMap[action.module]}`);
        break;
      case "external":
        window.open(action.link, "_blank");
        break;
      case "message":
        setMessage({ code: action.code, valide: isValidated(action) });
        break;
      default:
    }
  };

  /**
   * Handle message modal closing
   * @returns {void}
   */
  const handleCloseMessage = () => {
    setMessage(null);
  };

  /**
   * Handle message validation
   * @param {string} code
   * @returns {void}
   */
  const handleMessageValidate = async (code) => {
    const data = {
      message_code: code,
    };
    console.log("[Todo] handleMessageValidate", data);
    try {
      const result = await employeeConnector.postMessageSeen(data);
      const seen = result.data.results.messages_seen;
      console.log("[Todo] handleMessageValidate seen", seen, message.code);
      if (seen.includes(message.code)) {
        const employeeState = {
          notifications: user.notifications.filter((m) => m !== message.code),
        };
        setAppUserNotifications(employeeState);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[Todo] handleMessageValidate", error);
    }
  };

  const isValidated = (action) => {
    if (action.type === "message") {
      return !user.notifications.toDisplay.includes(action.code);
    }

    return action.valide;
  };

  /**
   * Render list of steps
   * @returns {Array}
   */
  const renderStep = () => {
    if (!step) return null;

    return (
      <Box mt="1.5em" ml="2em">
        <Box display="flex">
          <CheckCircleIcon
            color={step.valide ? "success" : "disabled"}
            fontSize="large"
          />
          <Typography pt="0.5em" ml="0.5em" variant="h6">
            {step.text}
          </Typography>
        </Box>
        {step.parcours && step.open
          ? step.parcours.map((action, i) => {
              return (
                <Box mt="0.5em" ml="2em" key={i}>
                  <Link
                    sx={{ cursor: "pointer" }}
                    display="flex"
                    onClick={() => handleActionClick(action)}
                  >
                    <CheckCircleIcon
                      color={isValidated(action) ? "success" : "disabled"}
                      fontSize="large"
                    />
                    <Typography pt="0.5em" ml="0.5em">
                      {action.text}
                    </Typography>
                  </Link>
                </Box>
              );
            })
          : null}
      </Box>
    );
  };

  const renderMessageDisplay = () => {
    return (
      <Box>
        <Message
          message={message?.code || null}
          showValidation={!message.valide}
          onValidate={handleMessageValidate}
          onClose={handleCloseMessage}
          autoOpen={message ? true : false}
        />
      </Box>
    );
  };

  return (
    <Box>
      {step && renderStep()}
      {message && renderMessageDisplay()}
    </Box>
  );
};

export default Todo;

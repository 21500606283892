import React from "react";

// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import ContractsView from "./ContractsView";
import ContractDetail from "../../components/contracts/ContractDetail";
import ContractMandataireDetail from "../../components/contracts/ContractMandataireDetail";
import HealthCare from "../../components/contracts/HealthCare";

import { SECTIONS } from "./constants";
// import { pagesMargin } from "../../constants";

function Contracts() {
  const { t } = useTranslation();
  const { id, section } = useParams();
  // const theme = useTheme();
  // const noMargin = useMediaQuery(theme.breakpoints.down("lg"));

  const marginSx = null;
  // const marginSx = noMargin ? null : pagesMargin; // NB: YouSign iframe issue if too much margin !

  const renderDetailView = () => {
    switch (SECTIONS[section]) {
      case SECTIONS.healthcares:
        return <HealthCare id={id} />;
      case SECTIONS.contracts:
        return <ContractDetail id={id} />;
      case SECTIONS.mandataires:
        return <ContractMandataireDetail id={id} />;
      default:
        return <ContractDetail id={id} />;
    }
  };

  return (
    <React.Fragment>
      <Helmet title={t("menu.contracts.contracts")} />
      <Box sx={marginSx}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {id ? renderDetailView() : <ContractsView section={section} />}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Contracts;

import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

import { Grid, Typography } from "@mui/material";

import PlanningView from "./PlanningView.js";

function Planning() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title={t("menu.planning")} />
      <Typography
        variant="h3"
        gutterBottom
        display="grid"
        className="view-title"
      >
        {t("menu.planning")}
      </Typography>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PlanningView />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Planning;

import React, { useRef, useState, useCallback, useEffect } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { spacing } from "@mui/system";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import Coupon from "../../components/planning/Coupon";
import Intervention from "../../components/planning/Intervention";

import calendarStyle from "./Calendar.style";

import { planningLocked, parsePlanning } from "./helper";
import InterventionDetail from "../../components/planning/InterventionDetail";

const FullCalendarWrapper = styled.div`
  ${calendarStyle}
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const actions = {
  COUPON: "COUPON",
  INTERVENTION: "INTERVENTION",
};

const dialogComponents = { COUPON: Coupon, INTERVENTION: Intervention };

const PlanningView = (props) => {
  const { t, i18n } = useTranslation();
  const { employeeConnector } = useAuth();
  const alert = useAlert();
  const calendarRef = useRef(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchor, setAnchor] = useState(null);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [employeePlanning, setEmployeePlanning] = useState(null);
  const [loading, setLoading] = useState(false);
  const [intervention, setIntervention] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);
  const open = Boolean(anchor);

  const language = i18n.language;

  const locales = {
    fr: frLocale,
  };

  const handleMonthChange = (payload) => {
    console.log("[PlanningView] handleMonthChange", payload);
    getPlanning({ startDate: payload.start, endDate: payload.end });
  };

  const handleActionsOpen = (e) => {
    setAnchor(e.currentTarget);
  };

  const handleActionsClose = () => {
    setAnchor(null);
  };

  const handleEventClick = (e) => {
    const interventionId = e.event._def.publicId;
    console.log("EVENT:", e, interventionId);
    getIntervention(interventionId);
    setDetailOpen(true);
    //e.event.remove();
  };

  const handleDetailClose = () => {
    console.log("[PlanningView] handleDetailClose");
    setDetailOpen(false);
    setIntervention(null);
  };

  const handleActionCoupon = () => {
    console.log("[PlanningView] handleActionCoupon");
    handleActionsClose();
    setDialogContent(actions.COUPON);
    setDialogOpen(true);
  };

  /*const handleActionIntervention = () => {
    console.log("[PlanningView] handleActionIntervention");
    handleActionsClose();
    setDialogContent(actions.INTERVENTION);
    setDialogOpen(true);
  };*/

  const handleDialogClose = () => {
    console.log("[PlanningView] handleDialogClose");
    setDialogOpen(false);
    setDialogContent(null);
  };

  /* const handleDateClick = (e) => {
    console.log("DATE:", e);
    const event = {
      id: e.date,
      title: "Event Test",
      start: e.dateStr,
    };
    addEvent(event);
  };/*

  /*const addEvent = (event) => {
    const api = calendarRef.current.getApi();
    api.addEvent(event);
  };*/

  const getPlanning = useCallback(
    async (dates) => {
      setLoading(true);
      try {
        const response = await employeeConnector.getPlanning(dates);
        if (response) {
          alert.success({ message: t("alert.success.loading") });
          const planning = response.data.results;
          console.log("[Planning] getPlanning:", planning);
          setEmployeePlanning(parsePlanning(planning));
          setLoading(false);
        }
      } catch (error) {
        console.error("[Planning] getPlanning", error);
        setLoading(false);
        alert.error({ message: parseResponseErrorMessage(error) });
      }
    },
    [employeeConnector, alert, t]
  );

  const getIntervention = useCallback(
    async (interventionId) => {
      try {
        const response = await employeeConnector.getIntervention(
          interventionId
        );
        if (response) {
          setIntervention(response.data.results);
        }
      } catch (error) {
        console.error("[Planning] getPlanning", error);
        alert.error({ message: parseResponseErrorMessage(error) });
      }
    },
    [employeeConnector, alert]
  );

  const renderActionsMenu = () => {
    return (
      <Box>
        <Menu
          id="basic-menu"
          anchorEl={anchor}
          open={open}
          onClose={handleActionsClose}
        >
          <MenuItem onClick={handleActionCoupon}>
            {t("planning.actions.coupon")}
          </MenuItem>
          {/*
            <MenuItem onClick={handleActionIntervention}>
              {t("planning.actions.intervention")}
            </MenuItem>*/}
        </Menu>
      </Box>
    );
  };

  const renderDialogContent = () => {
    if (!dialogContent) return null;
    const Content = dialogComponents[dialogContent];
    return (
      <Content onClose={handleDialogClose} refreshPlanning={getPlanning} />
    );
  };

  useEffect(() => {
    if (employeePlanning === null && employeeConnector && !loading) {
      const startDate = new Date();
      startDate.setDate(1);
      let endDate;
      if (startDate.getMonth() === 11) {
        endDate = new Date(startDate.getFullYear() + 1, 0, 1);
      } else {
        endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          1
        );
      }
      getPlanning({ startDate, endDate });
      console.log(
        "[Planning] getPlanning:",
        employeePlanning,
        employeeConnector
      );
    }
  }, [employeePlanning, getPlanning, employeeConnector, loading]);

  return (
    <Box>
      {planningLocked() ? (
        <Box
          sx={{
            bgcolor: "#ffcdd2",
            marginBottom: "1em",
            padding: "0.5em",
            borderRadius: "0.3em",
            textAlign: "center",
          }}
        >
          <Typography color="error">{t("planning.locked")}</Typography>
        </Box>
      ) : null}
      <Card mb={6}>
        <CardContent p={6}>
          <FullCalendarWrapper>
            <FullCalendar
              ref={calendarRef}
              titleFormat={{
                year: "numeric",
                month: fullScreen ? "short" : "long",
              }}
              initialView="dayGridMonth"
              initialDate={new Date()}
              plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
              events={employeePlanning}
              datesSet={handleMonthChange}
              slotMinTime="06:00:00"
              eventTimeFormat={{
                hour: "2-digit",
                minute: "2-digit",
                meridiem: false,
                hour12: false,
              }}
              editable={true}
              //dateClick={handleDateClick}
              eventClick={handleEventClick}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: planningLocked() ? "" : "actionsButton",
              }}
              customButtons={{
                actionsButton: {
                  text: t("planning.actions.button"),
                  click: handleActionsOpen,
                },
              }}
              locale={language === "en" ? null : locales[language]}
              contentHeight="auto"
            />
          </FullCalendarWrapper>
        </CardContent>
      </Card>
      {renderActionsMenu()}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        {renderDialogContent()}
      </Dialog>
      <Dialog open={detailOpen} onClose={handleDetailClose}>
        <DialogTitle display="flex" justifyContent="space-between">
          <Typography variant="h4">{t("planning.detail.title")}</Typography>
          <IconButton onClick={handleDetailClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InterventionDetail
            intervention={intervention}
            locked={planningLocked()}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PlanningView;

import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import YoutubeEmbed from "./YoutubeEmbed";

const videos = {
  en: "NOdFvW_MJqs",
  fr: "whcNNy1S43A",
};

const PrepareFamilyMeetingMessage = (props) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  return (
    <Box>
      <Typography>{t(`messages.PREP_MEET.text`)}</Typography>
      <Box mt="1em">
        <YoutubeEmbed key="video" embedId={videos[language]} />
      </Box>
    </Box>
  );
};

export default PrepareFamilyMeetingMessage;

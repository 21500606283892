import React, { useState, useEffect } from "react";
import { ReactiveBase, StateProvider } from "@appbaseio/reactivesearch";

import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FilterListIcon from "@mui/icons-material/FilterList";

import { useTranslation } from "react-i18next";

import GeoDistanceFilter from "./filters/GeoDistanceFilter";
import SortAndSortBySelector, {
  sortAndSortByOptions,
} from "./SortAndSortBySelector";
// import SortSelector, { sortOptions } from "./SortSelector"; // deprecated
// import SortBySelector, { sortByOptions } from "./SortBySelector"; // deprecated
import Loader from "./Loader";
import SearchFilters from "./filters/SearchFilters";
import SelectedFiltersSection from "./filters/SelectedFiltersSection";

import SearchResults from "./SearchResults";
import SearchMap from "./SearchMap";
// import GlobalSearchFilter from "./GlobalSearchFilter";
import SearchFiltersEmailAlert from "./filters/SearchFiltersEmailAlert";
import { MAP_DEFAULT_CONFIG } from "../../constants";
import {
  getFiltersValues,
  getFiltersValue,
  getSelectedDayTimePeriods,
} from "./filters/filters.helpers";
import { getReactiveBaseUserFiltersQueries } from "./user.helpers";
import { isFloat } from "../../helpers/common.helpers";

import "./styles.scss";

import { appConfig } from "../../config";
import useAuth from "../../hooks/useAuth";
import useSearchJob from "../../hooks/useSearchJob";

import { SEARCH_STYLES } from "./search.helpers";
import { isMobileDevice, isDesktopDevice } from "../../helpers/common.helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Returns SearchJob
 * @param {Object} props
 * @returns {ReactComponent}
 */
const SearchJob = (props) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  // current selected filters
  const {
    currentFilters,
    initCurrentFilters,
    // updateCurrentFilters,
    currentSearchResults,
    setCurrentGeoLocation,
    currentGeoLocation,
    initCurrentGeoLocation,
  } = useSearchJob();
  console.log(
    "[SearchJob] currentFilters:",
    currentFilters,
    "currentGeoLocation:",
    currentGeoLocation
  );

  // sort / sortBy states
  const [sortAndSortBy, setSortAndSortBy] = useState(sortAndSortByOptions[0]);
  // const [sort, setSort] = useState(sortOptions[0]);
  // const [sortBy, setSortBy] = useState(sortByOptions[0]);

  // loading state
  const [isLoading, setIsLoading] = useState(false);

  // dialog filters state
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [panelClassName, setPanelClassName] = useState("filters-panel");

  // filters passed from MyOffers
  const { customFilters, isMyOffersParentComponent, className } = props;
  const hideFilters = Boolean(isMyOffersParentComponent);
  const showMap = false;
  // const showMap = !Boolean(isMyOffersParentComponent);

  console.log(
    "[SearchJob] customFilters",
    customFilters,
    "isMyOffersParentComponent:",
    isMyOffersParentComponent,
    "hideFilters:",
    hideFilters,
    "showMap:",
    showMap
  );

  /**
   * Init filters
   * @returns {void}
   */
  const handleInitFilters = () => {
    console.log("[SearchJob] handleInitFilters");
    const selectors = document.querySelectorAll(".selected-filters a");
    selectors.forEach((selector) => {
      selector.click();
    });
    initCurrentFilters();
    initCurrentGeoLocation();

    if (isMobileDevice()) {
      handleCloseFilters();
    }
  };

  const showSearchResultNumber = (currentSearchResults) => {
    return currentSearchResults?.numberOfResults;
  };

  const handleSearch = () => {
    // search allready done : close modal & see results ...
    handleCloseFilters();
  };

  const handleClickOpenFilters = () => {
    setIsOpenFilters(true);
  };
  const handleCloseFilters = () => {
    setIsOpenFilters(false);
  };

  /**
   * Open / Close Filters panel [MOBILE]
   * @returns {void}
   */
  const openCloseFilters = () => {
    setPanelClassName("filters-panel hidden");
    handleClickOpenFilters();
    setTimeout(() => {
      handleCloseFilters();
      setPanelClassName("filters-panel");
    }, 500);
  };

  useEffect(() => {
    console.log("[SearchJob] useEffect");

    const initSearchWithUserFilters = () => {
      // [MOBILE] hack : we open / close the filters panels to include user default filters
      if (
        isMobileDevice() &&
        typeof user.filters !== "undefined" &&
        !isOpenFilters
      ) {
        console.log("[SearchJob] initSearchWithUserFilters");
        openCloseFilters();
      }
    };
    initSearchWithUserFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.filters]);

  /**
   * Render Search Components
   * @returns {ReactComponent}
   */
  const getSearchComponents = () => {
    if (hideFilters) {
      return null;
    }

    return (
      <>
        <SearchFilters
          userFilters={user?.filters}
          customFilters={customFilters || {}}
          selectedFilters={getFiltersValues(currentFilters)}
          allChildcareSkillsRefused={user.allChildcareSkillsRefused}
        />
        {appConfig.enableEmailAlert && (
          <SearchFiltersEmailAlert
            //selectedFilters={currentFilters}
            selectedFilters={getFiltersValues(currentFilters)}
            emailAlert={user?.emailAlert}
          />
        )}
      </>
    );
  };

  /**
   * Render mobile search results
   * @returns {ReactComponent}
   */
  const getMobileSearchResults = () => {
    return (
      <>
        <section className="search-results">
          <SearchResults
            user={user}
            sort={sortAndSortBy.dataField}
            sortBy={sortAndSortBy.sortBy}
          />
          {/* <SearchResults user={user} sort={sort} sortBy={sortBy} /> */}
        </section>

        <Dialog
          fullScreen
          open={isOpenFilters}
          onClose={handleCloseFilters}
          TransitionComponent={Transition}
          className={panelClassName}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              {/* <Button autoFocus color="inherit" onClick={handleInitFilters}>
              {t("search-job.init-filters")}
            </Button> */}
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                align="center"
              >
                {t("search-job.filter")}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseFilters}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <section className="search-filters mobile" id="search-filters">
            <div className="inner">
              <SelectedFiltersSection hideFilters={hideFilters} />
              {getSearchComponents()}
            </div>
            {getSearchFiltersBottomSection()}
          </section>
        </Dialog>
      </>
    );
  };

  /**
   * Render SearchFilters Bottom Section (Mobile)
   * @returns {ReactComponent}
   */
  const getSearchFiltersBottomSection = () => {
    return (
      <section className="search-filters-bottom">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={6} align="center">
            <Button variant="text" onClick={handleInitFilters}>
              {t("search-job.init-filters")}
            </Button>
          </Grid>
          <Grid item xs={6} align="center">
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={handleSearch}
            >
              {t("search-job.btn", {
                items: showSearchResultNumber(currentSearchResults),
              })}
            </LoadingButton>
          </Grid>
        </Grid>
      </section>
    );
  };

  /**
   * Render SearchFilters Top Section (Desktop)
   * @returns {ReactComponent}
   */
  const getSearchFiltersTopSection = () => {
    if (hideFilters) {
      return null;
    }

    return (
      <section className="search-filters-top">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} align="center" className="init-filters-container">
            <Button variant="text" onClick={handleInitFilters}>
              {t("search-job.init-filters")}
            </Button>
          </Grid>
        </Grid>
      </section>
    );
  };

  /**
   * Render Desktop search results
   * @returns {ReactComponent}
   */
  const getDesktopSearchResults = () => {
    // defaults map center, zoom
    let center = MAP_DEFAULT_CONFIG.center;
    let zoom = MAP_DEFAULT_CONFIG.zoom;

    // a geo-distance has been set : we get coords to center / zoom the map

    const geoDistanceFilter = getFiltersValue(currentFilters, "geo-distance");

    if (geoDistanceFilter && geoDistanceFilter.value) {
      // console.log("[SearchJob] getDesktopSearchResults", geoDistanceFilter);
      const location = geoDistanceFilter?.meta?.coordinates?.split(",");
      if (location && location.length === 2) {
        center = {
          lat: parseFloat(location[0]),
          lng: parseFloat(location[1]),
        };
        zoom = 12;
      }
    }

    return (
      <div className="multi-col">
        <div className="left-col">
          {getSortFiltersSection()}
          {getSelectedFiltersSection()}
          {getSearchFiltersTopSection()}
          <section className="search-filters" id="search-filters">
            {getSearchComponents()}
          </section>
        </div>
        <div className="right-col">
          <section className="map-results">
            {showMap && <SearchMap center={center} zoom={zoom} />}
          </section>

          <section className="search-results">
            <SearchResults
              user={user}
              sort={sortAndSortBy.dataField}
              sortBy={sortAndSortBy.sortBy}
            />
            {/* <SearchResults user={user} sort={sort} sortBy={sortBy} /> */}
          </section>
        </div>
      </div>
    );
  };

  /**
   * Render Mobile || Desktop search results
   * @returns {ReactComponent}
   */
  const getSearchResults = () => {
    return isMobileDevice()
      ? getMobileSearchResults()
      : getDesktopSearchResults();
  };

  /**
   * Render Mobile || Desktop SelectedFiltersSection
   * @returns {ReactComponent}
   */
  const getSelectedFiltersSection = () => {
    return isMobileDevice() ? null : (
      <SelectedFiltersSection
        hideFilters={hideFilters}
        hideFilterLabel={true}
      />
    );
  };

  /**
   * Render Action Container
   * @returns {ReactComponent}
   */
  const getActionContainer = () => {
    return (
      isMobileDevice() && (
        <div className="actions-container">
          {getSortFiltersSection()}
          <Button
            variant="outlined"
            onClick={handleClickOpenFilters}
            startIcon={<FilterListIcon />}
            className="open-filters-btn"
          >
            {t("search-job.filter")}
          </Button>
        </div>
      )
    );
  };

  /**
   * Render SortFilters Section
   * @returns {ReactComponent}
   */
  const getSortFiltersSection = () => {
    if (hideFilters) {
      return null;
    }

    return (
      <section className="sort-filters">
        <div className="sort-container">
          <SortAndSortBySelector
            handleChange={setSortAndSortBy}
            selected={sortAndSortBy}
            currentFilters={currentFilters}
          />
          {/* <SortSelector handleChange={setSort} selected={sort} />
          <SortBySelector handleChange={setSortBy} selected={sortBy} /> */}
        </div>
      </section>
    );
  };

  /**
   * Render MainFilters Section
   * @returns {ReactComponent}
   */
  const getMainFiltersSection = () => {
    if (hideFilters) {
      return null;
    }
    const className = isDesktopDevice() ? "main-filters" : "main-filters flex";
    //  : "main-filters floating";

    return (
      <section className={className}>
        {isDesktopDevice() && (
          <div className="filter-container">
            <GeoDistanceFilter
              className="filter geo-distance-no-distance-select"
              mergedFilters={getFiltersValues(currentFilters)}
            />
          </div>
        )}

        {getActionContainer()}
      </section>
    );
  };

  return (
    <div className={className}>
      <ReactiveBase
        app={appConfig.appBaseIOConfig.clusterName}
        credentials={appConfig.appBaseIOConfig.credentials}
        enableAppbase
        url={appConfig.appBaseIOConfig.url}
        className="search-job-container"
        mapKey={appConfig.appBaseIOConfig.gMapApiKey}
        theme={{
          colors: {
            primaryColor: SEARCH_STYLES.primaryColor,
          },
        }}
        transformRequest={(props) => {
          console.log(
            "[SearchJob] transformRequest",
            props,
            JSON.parse(props.body)
          );

          if (props.body) {
            const parsedBody = JSON.parse(props.body);

            if (parsedBody.query && parsedBody.query.length) {
              // -- we merge user & custom filters
              const userMergedFilters = {
                ...user?.filters,
                ...(customFilters || {}),
              };

              let userFiltersQueries = [];
              let parsedFilteredQueries = [];

              // -- we isolate result query obj
              const resultQueryIndex = parsedBody.query.findIndex((query) => {
                return query.id === "result";
              });
              const resultQuery = parsedBody.query.splice(resultQueryIndex, 1);

              // -- we add user filters depending on current view
              if (Boolean(isMyOffersParentComponent)) {
                const filterIds = ["job-offer-ids"];
                userFiltersQueries = getReactiveBaseUserFiltersQueries(
                  userMergedFilters,
                  filterIds
                );
                // -- we remove previous queries with same ids
                if (userFiltersQueries.length) {
                  parsedFilteredQueries = parsedBody.query.filter((query) => {
                    return !filterIds.includes(query.id);
                  });
                }
              } else {
                const filterIds = [
                  "job-offer-types",
                  "language-1-levels",
                  "language-2-levels",
                  // "languages-levels",
                  // "childcare-skills",
                  // "language-teaching-skills", // NB: if activated, reactive search will try to get offers with both childcare-skills & language-teaching-skills but it's impossible because an offer is either G or C ...
                  "skills",
                  "job-offer-status",
                ];
                userFiltersQueries = getReactiveBaseUserFiltersQueries(
                  userMergedFilters,
                  filterIds
                );

                console.log(
                  "[SearchJob] transformRequest - userFiltersQueries:",
                  userFiltersQueries
                );

                // -- we remove previous queries with same ids
                if (userFiltersQueries.length) {
                  parsedFilteredQueries = parsedBody.query.filter((query) => {
                    return !filterIds.includes(query.id);
                  });
                }

                // -- we override bad properties from getDayTimePeriodsRangeComponent

                // we capture every query indexes with "reactive_component_field"
                const timePeriodsQueryIndexes = [];

                for (const index in parsedBody.query) {
                  const query = parsedBody.query[index];

                  if (query.dataField[0] === "reactive_component_field") {
                    timePeriodsQueryIndexes.push(index);
                  }
                }
                // console.log(
                //   "[SearchJob] transformRequest - timePeriodsQueryIndexes:",
                //   timePeriodsQueryIndexes,
                //   "parsedBody.query:",
                //   parsedBody.query,
                //   "currentFilters:",
                //   currentFilters
                // );

                // we get selectedDayTimePeriods directly from the DOM (NB: currentFilters are not synchronized at this step...)
                const selectedDayTimePeriods = getSelectedDayTimePeriods();

                // we override every index
                for (const timePeriodsQueryIndex of timePeriodsQueryIndexes) {
                  const timePeriodsQuery =
                    parsedBody.query[timePeriodsQueryIndex];

                  const componentId = timePeriodsQuery.id;
                  const filterId = timePeriodsQuery.index;
                  const value =
                    timePeriodsQuery.customQuery.query.term[filterId][0];

                  // set new values
                  parsedBody.query[timePeriodsQueryIndex].id = componentId;
                  parsedBody.query[timePeriodsQueryIndex].type = "range";
                  parsedBody.query[timePeriodsQueryIndex].dataField = [
                    filterId,
                  ];
                  parsedBody.query[timePeriodsQueryIndex].execute = false;

                  // we check if filter is present in currentFilters, we set value to null it if not
                  if (
                    typeof selectedDayTimePeriods[componentId] === "undefined"
                    // typeof currentFilters[componentId] === "undefined" // KO (NB: currentFilters are not synchronized at this step...)
                  ) {
                    console.log(
                      "[SearchJob] transformRequest - selectedDayTimePeriods[componentId] is undefined > setting value to null"
                    );
                    parsedBody.query[timePeriodsQueryIndex].value = null;
                  } else {
                    parsedBody.query[timePeriodsQueryIndex].value = [
                      {
                        start: value,
                        end: 2359,
                      },
                    ];
                  }

                  // we remove useless properties
                  delete parsedBody.query[timePeriodsQueryIndex].customQuery;
                }
              }

              console.log(
                "[SearchJob] transformRequest - userFiltersQueries:",
                userFiltersQueries,
                "parsedFilteredQueries:",
                parsedFilteredQueries
              );

              parsedBody.query = [
                ...parsedFilteredQueries,
                ...userFiltersQueries,
                ...resultQuery,
              ];

              console.log(
                "[SearchJob] transformRequest - parsedBody.query:",
                parsedBody.query
              );

              // get "geo-distance" current location (NB: hack to compute distance for each result ...)
              const geoDistanceQuery = parsedBody.query.find((query) => {
                return query.id === "geo-distance";
              });

              if (geoDistanceQuery) {
                if (geoDistanceQuery?.value?.location) {
                  const location = geoDistanceQuery.value.location.split(",");

                  if (
                    location.length &&
                    isFloat(parseFloat(location[0])) &&
                    isFloat(parseFloat(location[1]))
                  ) {
                    setCurrentGeoLocation({
                      lat: parseFloat(location[0]),
                      lon: parseFloat(location[1]),
                    });
                  }
                }
              } else {
                console.log(
                  "[SearchJob] transformRequest - initCurrentGeoLocation"
                );
                initCurrentGeoLocation();
              }

              console.log(
                "[SearchJob] transformRequest - query",
                parsedBody.query
              );
            }
            props.body = JSON.stringify(parsedBody);
          }

          return { ...props };
        }}
      >
        <StateProvider includeKeys={["isLoading"]} strict={true}>
          {({ searchState }) => {
            if (
              searchState &&
              searchState.result &&
              typeof searchState.result.isLoading !== "undefined"
            ) {
              // console.log('searchState', searchState, searchState?.result?.isLoading);
              setIsLoading(searchState?.result?.isLoading);
            }
            return null;
            // <div>Debug: ${JSON.stringify(searchState)}</div>
          }}
        </StateProvider>
        <Loader
          show={isLoading}
          scrollTargetEltId={appConfig.reactiveListScrollTargetEltId}
          loadingText={t("search-job.search-lazy-loading")}
        />

        {getMainFiltersSection()}
        {/* {getSortFiltersSection()} */}
        {/* {getSelectedFiltersSection()} */}
        {getSearchResults()}
      </ReactiveBase>
    </div>
  );
};

export default SearchJob;

/**
 * Parse request error Object to readable string
 * @param {Object} error
 * @returns {string}
 */
const parseResponseErrorMessage = (error) => {
  // console.log(
  //   "parseResponseErrorMessage",
  //   error,
  //   error.reponse,
  //   error.response.data,
  //   error.message
  // );
  // if (!error.response) { // NB : returns : undefined ...
  //   return error.message ? error.message.toString() : "unknown error ...";
  // }
  if (!error.response.data) {
    return error.message ? error.message.toString() : "unknown error ...";
  }

  let statusCode;
  let errorStr;
  let message;

  if (error.response.data.error) {
    statusCode = `[${error.response.data.error.statusCode}]` || "";
    errorStr = "";
    message = `: ${error.response.data.error.message}` || "";
  } else {
    statusCode = `[${error.response.data.statusCode}]` || "";
    errorStr = ` - ${error.response.data.error}` || "";
    message = `: ${error.response.data.message}` || "";
  }

  return statusCode + errorStr + message;
};

export { parseResponseErrorMessage };

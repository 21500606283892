import React from "react";

import { Helmet } from "react-helmet-async";

import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import MandatoryView from "./MandatoryView";

function Mandatory() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title={t("menu.mandatory")} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box p="1em">
            <MandatoryView />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Mandatory;

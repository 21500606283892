import React from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet-async";

import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import ResourcesView from "./ResourcesView";

import { pagesMargin } from "../../constants";

function Resources() {
  const { t } = useTranslation();
  const theme = useTheme();
  const noMargin = useMediaQuery(theme.breakpoints.down("lg"));

  const marginSx = noMargin ? null : pagesMargin;

  return (
    <React.Fragment>
      <Helmet title={t("menu.resources.resources")} />
      <Box sx={marginSx}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <ResourcesView />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Resources;

import React from "react";

import useAuth from "../hooks/useAuth";
import useAlert from "../hooks/useAlert";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";

import { parseResponseErrorMessage } from "../helpers/errorMessages.helpers";

import Message from "../components/message/Message";

const Notifications = (props) => {
  const { employeeConnector, setAppUserNotifications } = useAuth();
  const user = useAuthenticatedUser();
  const alert = useAlert();
  const messages = user?.notifications?.toDisplay;
  const hasMessages =
    messages && Array.isArray(messages) && messages.length > 0 ? true : false;
  const messagesToDisplay = hasMessages
    ? messages.filter(
        (message) => !user?.notifications?.displayed?.includes(message)
      )
    : [];
  const currentMessage = hasMessages ? messagesToDisplay[0] : null;

  console.log(
    "[Notifications] messages",
    messages,
    "messagesToDisplay",
    messagesToDisplay
  );

  const handleCloseMessage = () => {
    const employeeState = {
      notifications: {
        toDisplay: [...messages],
        displayed: [...user?.notifications?.displayed, currentMessage],
      },
    };
    setAppUserNotifications(employeeState);
  };

  const handleMessageValidate = async (code) => {
    const data = {
      message_code: code,
    };
    console.log("[Notifications] handleMessageValidate", data);
    try {
      const result = await employeeConnector.postMessageSeen(data);
      const seen = result.data.results.messages_seen;
      console.log(
        "[Notifications] handleMessageValidate seen",
        seen,
        currentMessage
      );
      if (seen.includes(currentMessage)) {
        const employeeState = {
          notifications: {
            toDisplay: messagesToDisplay.filter((m) => m !== currentMessage),
            displayed: [...user?.notifications.displayed, currentMessage],
          },
        };
        setAppUserNotifications(employeeState);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[Notifications] handleMessageValidate", error);
    }
  };

  return (
    <Message
      message={currentMessage}
      autoOpen={hasMessages}
      onValidate={handleMessageValidate}
      onClose={handleCloseMessage}
      showValidation
    />
  );
};

export default Notifications;

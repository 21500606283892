import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { ReactiveList, ResultList } from "@appbaseio/reactivesearch";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";

import { Box, Chip, Divider as MuiDivider, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { spacing } from "@mui/system";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";
import useSearchJob from "../../hooks/useSearchJob";
import { apiConfig, appConfig } from "../../config";

import Expand from "./Expand";
import Tooltip from "./Tooltip";

import { getOfferTranslatedProp } from "../../helpers/translations.helpers";
import { getFilters } from "./filters/filters.helpers";
import { isPushesView } from "../../helpers/common.helpers";
import {
  showError,
  getJobOfferStatusProps,
  getDistance,
  getDefaultGeoLocation,
} from "./search.helpers";
import { getJobOfferClassName, getChildrenAges } from "./user.helpers";
import { formatDate } from "../../helpers/dates.helpers";

const Divider = styled(MuiDivider)(spacing);

/**
 * Returns SearchResults
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function SearchResults(props) {
  const { user, sort, sortBy } = props;
  const { employeeConnector } = useAuth();

  const { t, i18n } = useTranslation();
  const alert = useAlert();
  // const navigate = useNavigate();
  const {
    updateCurrentJobOffer,
    updateCurrentJobOfferQueryParams,
    updateCurrentSearchResults,
    currentGeoLocation,
    currentView,
    repositories,
  } = useSearchJob();
  const [bookmarkedJobOffers, setBookmarkedJobOffers] = useState(null);
  const [pushedJobOffers, setPushedJobOffers] = useState(null);

  const language = i18n.language;

  /**
   * Redirect to offer view
   * @param {Object} event
   * @param {Object} item
   * @returns {void}
   */
  const handleResultListClick = (event, item) => {
    console.log("[SearchResults] handleResultListClick", item);

    event.preventDefault();
    if (
      typeof event.target.className !== "string" ||
      event.target.className.includes("MuiButton-root")
    ) {
      return;
    }

    const jobOfferId = item[apiConfig.samApi.jobOfferPublicKey];
    if (!jobOfferId) {
      throw new Error(
        "[SearchResults] handleResultListClick jobOfferId not found !"
      );
    }

    // update local JobOffer
    updateCurrentJobOffer(item);

    // update jobOfferQueryParams
    const queryString = window.location.search;
    updateCurrentJobOfferQueryParams(queryString);

    // redirect
    // navigate(`/job-offers/${jobOfferId}`);

    // open offer in new tab
    const { protocol, host } = document.location;
    const basePath = appConfig.basePath !== "" ? `${appConfig.basePath}/` : "";
    const queryParams = `${appConfig.newTabQueryParam}=1`;
    const href = `${protocol}//${host}/${basePath}job-offers/${jobOfferId}?${queryParams}`;
    console.info("[SearchResults] handleResultListClick - opening:", href);
    window.open(href, "_blank");
  };

  /**
   * JobOffer Bookmark
   * @param {Object} event
   * @param {Object} jobOffer
   * @returns {void}
   */
  const handleBookmark = async (event, jobOffer) => {
    event.preventDefault();

    console.log("[SearchResult] handleBookmark", jobOffer, user.jobOffers);

    const { id } = jobOffer;

    const isBookmarkedJobOffer =
      user?.jobOffers?.bookmarks.filter((jobOffer) => jobOffer.requestId === id)
        .length > 0;

    try {
      const response = isBookmarkedJobOffer
        ? await employeeConnector.unBookmarkJobOffer(id)
        : await employeeConnector.bookmarkJobOffer(id);

      if (response && response.data) {
        user.jobOffers.bookmarks = response.data;

        setBookmarkedJobOffers([...user.jobOffers.bookmarks]);

        // console.log(
        //   "[SearchResult] handleBookmark : jobOffers.bookmarks updated",
        //   user.jobOffers.bookmarks
        // );

        alert.success({
          message: isBookmarkedJobOffer
            ? t("alert.success.job-offer-unbookmarked", { jobOffer })
            : t("alert.success.job-offer-bookmarked", { jobOffer }),
        });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[SearchResult] handleBookmark", error);
    }
  };

  /**
   * JobOffer Delete (from pushes)
   * @param {Object} event
   * @param {Object} jobOffer
   * @returns {void}
   */
  const handleDelete = async (event, jobOffer) => {
    event.preventDefault();

    console.log("[SearchResult] handleDelete", jobOffer, user.jobOffers);

    const { id } = jobOffer;

    try {
      const response = await employeeConnector.deleteJobOffer(id);

      if (response && response.data) {
        user.jobOffers.deletions = response.data;

        const deletedJobOfferIds = user.jobOffers.deletions.map((jobOffer) => {
          return jobOffer.requestId;
        });

        const updatedPushedJobOffers = user.jobOffers.pushes.filter(
          (jobOffer) => {
            return !deletedJobOfferIds.includes(jobOffer.requestId);
          }
        );
        user.jobOffers.pushes = updatedPushedJobOffers;

        setPushedJobOffers([...user.jobOffers.pushes]);

        // console.log(
        //   "[SearchResult] handleDelete : jobOffers.pushes updated",
        //   user.jobOffers.pushes
        // );

        alert.success({
          message: t("alert.success.job-offer-deleted", { jobOffer }),
        });
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[SearchResult] handleDelete", error);
    }
  };

  const chipSx = {
    marginRight: "0.5em",
    marginTop: "0.5em",
    marginBottom: "0.5em",
    borderRadius: "20px",
  };

  /**
   * Returns LazyLoader JSX
   */
  const LazyLoader = () => {
    return (
      <div className="lazy-loader">
        <p>{t("search-job.search-lazy-loading")}</p>
      </div>
    );
  };

  /**
   * Returns no result message
   */
  const renderNoResults = () => {
    console.log("[SearchResult] renderNoResults");
    return <p className="search-no-result">{t("search-job.no-results")}</p>;
  };

  const handleData = (data) => {
    console.log("[SearchResult] handleData", data);

    const { resultStats } = data;

    if (resultStats) {
      updateCurrentSearchResults(resultStats);
    }
  };

  /**
   * Returns getDaysSchedule
   * @param {Object} item
   * @returns {ReactComponent}
   */
  const getDaysSchedule = (item) => {
    return (
      <Box className="days-schedule">
        <Chip
          sx={chipSx}
          label={t("search-job.days.monday")}
          color={item?.monday_schedule_1.length ? "primary" : undefined}
          variant={item?.monday_schedule_1.length ? null : "outlined"}
        />
        <Chip
          sx={chipSx}
          label={t("search-job.days.tuesday")}
          color={item?.tuesday_schedule_1.length ? "primary" : undefined}
          variant={item?.tuesday_schedule_1.length ? null : "outlined"}
        />
        <Chip
          sx={chipSx}
          label={t("search-job.days.wednesday")}
          color={item?.wednesday_schedule_1.length ? "primary" : undefined}
          variant={item?.wednesday_schedule_1.length ? null : "outlined"}
        />
        <Chip
          sx={chipSx}
          label={t("search-job.days.thursday")}
          color={item?.thursday_schedule_1.length ? "primary" : undefined}
          variant={item?.thursday_schedule_1.length ? null : "outlined"}
        />
        <Chip
          sx={chipSx}
          label={t("search-job.days.friday")}
          color={item?.friday_schedule_1.length ? "primary" : undefined}
          variant={item?.friday_schedule_1.length ? null : "outlined"}
        />
        <Chip
          sx={chipSx}
          label={t("search-job.days.saturday")}
          color={item?.saturday_schedule_1.length ? "primary" : undefined}
          variant={item?.saturday_schedule_1.length ? null : "outlined"}
        />
        {/* <Chip
          sx={chipSx}
          label={t("search-job.days.sunday")}
          color={item?.sunday_schedule_1.length ? "primary" : undefined}
          variant={item?.sunday_schedule_1.length ? null : "outlined"}
        /> */}
      </Box>
    );
  };

  /**
   * Returns distance
   * TODO : default : get user coords
   * @param {Object} item
   * @returns {String}
   */
  const showDistance = (item) => {
    // console.log("[SearchResult] showDistance item:", item);
    // for test (NY city coords)
    // const currentGeoLocation = {
    //   lat: 40.73061,
    //   lon: -73.935242,
    // };

    if (!currentGeoLocation) {
      return null;
    }

    const { location, sort } = item;

    let distance;

    if (sort && sort.length > 1) {
      // NB: computed distance returned by Elastic API (depending on the filter, sort main contains 1 or 2 entries ...)
      distance = sort[1];
    } else {
      // console.log(
      //   "[SearchResult] showDistance : distance not returned by API ..."
      // );

      // we compute the distance ...
      distance = getDistance(
        currentGeoLocation.lat,
        currentGeoLocation.lon,
        location.lat,
        location.lon,
        "K"
      );
    }

    return distance ? `${distance.toFixed(1)} km` : null;
  };

  /**
   * Returns HoursByWeek
   * @param {Float} hours_by_week
   * @returns {String}
   */
  const getHoursByWeek = (hours_by_week) => {
    return `${hours_by_week.toFixed(1)} ${t("search-job.hoursByWeek")}`;
  };

  /**
   * Returns Package
   * @param {Float} job_offer_package
   * @returns {String}
   */
  const getPackage = (job_offer_package) => {
    return `${job_offer_package}€`;
  };

  /**
   * Handle Render Result Stats
   * @param {Object} stats
   * @returns {String}
   */
  const handleRenderResultStats = (stats) => {
    // console.log("[SearchResult] handleRenderResultStats:", stats);

    const { numberOfResults } = stats;

    let message;

    switch (numberOfResults) {
      case 0:
        message = t(`search-job.result-stats.none`);
        break;
      case 1:
        message = t(`search-job.result-stats.one`);
        break;
      default:
        message = t(`search-job.result-stats.many`, {
          numberOfResults,
        });
        break;
    }

    return (
      <div
        className="search-results-stats"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    );
  };

  /**
   * Returns delete Button
   * @param {Object} item
   * @returns {ReactComponent}
   */
  const getDeleteButton = (item) => {
    return (
      <IconButton onClick={(e) => handleDelete(e, item)}>
        <DeleteOutlineIcon />
      </IconButton>
    );
  };

  /**
   * Returns result item
   * @param {Object} item
   * @param {Object} user
   * @param {String} viewType ('raw', 'beautify')
   */
  const getResultItem = (item, user, viewType = "beautify") => {
    const { _promoted, _click_id, _index, highlight, _type, index, ...rest } =
      item;

    // console.log("[getResultItem] user:", user);

    const itemUserInfo = {
      bookmarked:
        user?.jobOffers?.bookmarks.filter(
          (jobOffer) => jobOffer.requestId === item?.id
        ).length > 0,
      applied:
        user?.jobOffers?.applications.filter(
          (jobOffer) => jobOffer.requestId === item?.id
        ).length > 0,
      status:
        user?.jobOffers?.applications.filter(
          (jobOffer) => jobOffer.requestId === item?.id
        )[0]?.status || null,
      deleted:
        user?.jobOffers?.deletions.filter(
          (jobOffer) => jobOffer.requestId === item?.id
        ).length > 0,
    };

    item.bookmarked = itemUserInfo.bookmarked;
    item.applied = itemUserInfo.applied;
    item.status = itemUserInfo.status;
    item.deleted = itemUserInfo.deleted;

    // NB : if item.deleted > we make offer disappear by using display:none ...

    // console.log("[SearchResults] getResultItem", item);

    if (viewType === "raw") {
      return (
        <Expand className="result-item" key={rest._id}>
          {Object.keys(rest).map((key) => (
            <div className="result-item-key">
              <span>{key}</span>
              <Tooltip
                code={typeof rest[key] === "object"}
                title={JSON.stringify(rest[key], null, 2) || "N/A"}
              >
                <div
                  className="result-item-value"
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof rest[key] === "object"
                        ? "{...}"
                        : JSON.stringify(rest[key]) || "N/A",
                  }}
                />
              </Tooltip>
            </div>
          ))}
        </Expand>
      );
    } else {
      return (
        <ReactiveList.ResultListWrapper
          key={item?._id}
          id={item?.id}
          className={getJobOfferClassName(
            "result-item",
            user.jobOffers,
            item[apiConfig.samApi.jobOfferInternalKey]
          )}
        >
          <ResultList
            href={item?.url_fr}
            className="offer"
            onClick={(e) => handleResultListClick(e, item)}
          >
            {/* <ResultList.Image src={item?.image} /> */}
            <ResultList.Content>
              <ResultList.Title>
                <Box display="flex" justifyContent="space-between">
                  <span>
                    {getOfferTranslatedProp(
                      item,
                      "title",
                      language,
                      repositories,
                      t
                    )}{" "}
                    - {item[apiConfig.samApi.jobOfferPublicKey]}
                  </span>

                  <div className="action-buttons-container">
                    {isPushesView(currentView) && getDeleteButton(item)}

                    <IconButton onClick={(e) => handleBookmark(e, item)}>
                      {item?.bookmarked ? (
                        <FavoriteIcon color="error" />
                      ) : (
                        <FavoriteBorderIcon />
                      )}
                    </IconButton>
                  </div>
                </Box>
              </ResultList.Title>
              <Divider my={1} />
              <ResultList.Description>
                <div className="description">
                  <Box>
                    {item?.status ? (
                      <Chip
                        sx={{
                          ...chipSx,
                          bgcolor: getJobOfferStatusProps(item?.status).color,
                          color: getJobOfferStatusProps(item?.status).textColor,
                        }}
                        label={t(`search-job.${item?.status}`)}
                      />
                    ) : null}
                  </Box>
                  <p>
                    {item?.zip_code} - {item?.city}{" "}
                    <span className="distance">{showDistance(item)}</span>
                  </p>
                  <p>{`${t("search-job.fromDate")} : ${formatDate(
                    item?.start_date,
                    language
                  )}`}</p>
                  <p>
                    {`${item?.children_total} ${
                      item?.children_total > 1
                        ? t("search-job.children")
                        : t("search-job.child")
                    } : `}
                    {getChildrenAges(item?.children_ages, t)}
                  </p>
                  <p>{getHoursByWeek(item?.hours_by_week)}</p>
                  <p>{`${t("job-offer.salary")} : ${getPackage(
                    item?.package
                  )}`}</p>
                  {getDaysSchedule(item)}
                </div>
              </ResultList.Description>
            </ResultList.Content>
          </ResultList>
        </ReactiveList.ResultListWrapper>
      );
    }
  };

  useEffect(() => {
    if (user.jobOffers && bookmarkedJobOffers === null) {
      setBookmarkedJobOffers(user.jobOffers.bookmarks);
    }
    if (user.jobOffers && pushedJobOffers === null) {
      setPushedJobOffers(user.jobOffers.pushed);
    }
  }, [
    bookmarkedJobOffers,
    pushedJobOffers,
    user?.jobOffers,
    user?.jobOffers?.bookmarks,
    user?.jobOffers?.pushed,
  ]);

  return (
    <ReactiveList
      componentId="result"
      dataField="_score"
      loader={<LazyLoader />}
      pagination={false}
      infiniteScroll={true}
      scrollTarget={appConfig.reactiveListScrollTargetEltId}
      scrollOnChange={false}
      excludeFields={[]}
      highlightFields={[]}
      // highlightOptions={{
      //   fragment_size: 100,
      //   number_of_fragments: 5,
      //   post_tags: ["</mark>"],
      //   pre_tags: ["<mark>"],
      // }}
      includeFields={["*"]}
      react={{
        and: getFilters([]),
      }}
      renderItem={(item) => getResultItem(item, user)}
      size={10}
      defaultQuery={() => {
        const sortItems = [{ [sort]: { order: sortBy } }]; // default
        // const sortItems = [{ [sort.dataField]: { order: sortBy.value } }]; // deprecated

        if (currentGeoLocation) {
          sortItems.push({
            _geo_distance: {
              location: currentGeoLocation,
              order: "asc",
              unit: "km",
              mode: "min",
              distance_type: "arc",
              ignore_unmapped: true,
            },
          });
        }
        if (sort === "geo-distance") {
          const location = currentGeoLocation
            ? currentGeoLocation
            : getDefaultGeoLocation();
          return {
            sort: [
              // NB : result.sort includes computed distance !!!
              {
                _geo_distance: {
                  location: location,
                  order: "asc",
                  unit: "km",
                  mode: "min",
                  distance_type: "arc",
                  ignore_unmapped: true,
                },
              },
            ],
          };
        }
        return {
          sort: sortItems,
        };
      }}
      renderNoResults={renderNoResults}
      renderError={(error) => showError("result", error)}
      className="search-results-main-wrapper"
      onData={(data) => handleData(data)}
      renderResultStats={(stats) => handleRenderResultStats(stats)}
    />
  );
}

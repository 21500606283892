import React from "react";
import { useTranslation } from "react-i18next";

import { Box, CircularProgress, Typography } from "@mui/material";

import { parseDate, dateToString } from "../contracts/helper-contract";

const STATUS = {
  A: "future",
  R: "done",
  S: "deleted",
  N: "cancelled",
};

const InterventionDetail = (props) => {
  const { intervention } = props;
  const { t } = useTranslation();

  /**
   * Parse HHmm time to HH:mm format
   * @param {String} time
   * @returns {String}
   */
  const splitTime = (time) => {
    if (!time || time.length < 4) return null;

    return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
  };

  return intervention ? (
    <Box>
      <Box mb="1em">
        <Typography variant="h6">{t("planning.detail.type")}</Typography>
        <Typography>
          {t(`planning.detail.prestation.${intervention.prestation.service}`)}
        </Typography>
      </Box>
      <Box mb="1em">
        <Typography variant="h6">{t("planning.detail.date")}</Typography>
        <Typography>
          {dateToString(new Date(parseDate(intervention.date)))}
        </Typography>
      </Box>
      <Box mb="1em">
        <Typography variant="h6">{t("planning.detail.start")}</Typography>
        <Typography>{splitTime(intervention.start_time)}</Typography>
      </Box>
      <Box mb="1em">
        <Typography variant="h6">{t("planning.detail.end")}</Typography>
        <Typography>{splitTime(intervention.end_time)}</Typography>
      </Box>
      <Box mb="1em">
        <Typography variant="h6">{t("planning.detail.status.head")}</Typography>
        <Typography>
          {t(`planning.detail.status.${STATUS[intervention.etat]}`)}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box>
      <CircularProgress />
    </Box>
  );
};

export default InterventionDetail;

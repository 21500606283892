import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  //Avatar,
  Paper,
  Typography,
} from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/momji_logo_837x551.svg";
import SignInComponent from "../../components/auth/SignIn";

import { useTranslation } from "react-i18next";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 180px;
  height: 120px;
  margin-bottom: 20px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

// const BigAvatar = styled(Avatar)`
//   width: 92px;
//   height: 92px;
//   text-align: center;
//   margin: 0 auto ${(props) => props.theme.spacing(5)};
// `;

function SignIn() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title={t("auth.welcome")} />
        {/* <BigAvatar alt="Lucy" src="/static/img/avatars/avatar-1.jpg" /> */}

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("auth.welcome")}
        </Typography>
        <Typography component="h2" variant="body1" align="center" mb="1em">
          {t("auth.sign-in")}
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;

import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";
import { downloadFile } from "../../helpers/common.helpers";

import { documentGroupTypes, identiteTypes, statuses } from "./constants";
import Document from "./Document.js";

const Button = styled(MuiButton)(spacing);

const DocumentGroup = (props) => {
  const {
    groups,
    groupType,
    canChangeGroup,
    onJustificatifValueChange,
    onDocumentUpload,
  } = props;
  const { user, employeeConnector } = useAuth();
  const { t, i18n } = useTranslation();
  const [loadingRequiredDocs, setLoadingRequiredDocs] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(props.currentGroup || "");
  const [requiredDocuments, setRequiredDocuments] = useState(null);
  const [loading, setLoading] = useState(false);

  const language = i18n.language;
  const currentGroupLabel = currentGroup
    ? groups.filter((group) => group.value === currentGroup)[0]?.label[language]
    : null;

  console.log("[DocumentGroup] props:", props);

  /**
   * Handle group changing
   * @param {Object} e
   * @returns {void}
   */
  const handleGroupChange = async (e) => {
    setCurrentGroup(e.target.value);
  };

  /**
   * Handle group selection validation
   * @returns {void}
   */
  const handleGroupValidate = async () => {
    console.log("[DocumentGroup] handleGroupValidate", currentGroup);
    setLoadingRequiredDocs(true);

    const data = { justificatifTypeId: currentGroup };

    try {
      setLoading("loading");
      const result = props.currentGroup
        ? await employeeConnector.updateJustificatifType(data)
        : await employeeConnector.setJustificatifType(data);
      if (result) {
        onJustificatifValueChange(result);
        setRequiredDocuments(result.documents);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[DocumentGroup] handleGroupValidate", error);
    }

    setLoadingRequiredDocs(false);
  };

  /**
   * Handle document deletion
   * @param {Object} document
   * @returns {void}
   */
  const handleDocumentDelete = async (document) => {
    console.log("[DocumentGroup] handleDocumentDelete", document.id);
    try {
      const response = await employeeConnector.deleteDocument(document.id);
      if (response) {
        try {
          const result = await employeeConnector.getDocuments();
          if (result) {
            setRequiredDocuments(result);
          }
        } catch (error) {
          alert.error({ message: parseResponseErrorMessage(error) });
          console.error("[DocumentGroup] handleDocumentDelete", error);
        }
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[DocumentGroup] handleDocumentDelete", error);
    }
  };

  /**
   * Handle document uploading
   * @returns {void}
   */
  const handleDocumentUpload = () => {};

  /**
   * Handle document upload success
   * @returns {void}
   */
  const handleDocumentUploadSuccess = async () => {
    console.log("[DocumentGroup] handleDocumentUpload");
    try {
      const result = await employeeConnector.getDocuments();
      if (result) {
        setRequiredDocuments(result);
        onDocumentUpload();
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[DocumentGroup] handleDocumentUploadSuccess", error);
    }
  };

  /**
   * Handle document upload failure
   * @returns {void}
   */
  const handleDocumentUploadFailed = async () => {
    console.log("[DocumentGroup] handleDocumentUploadFailed");
  };

  /**
   * Handle document download
   * @param {Object} document
   * @returns {void}
   */
  const handleDocumentDownload = async (document) => {
    // console.log("[DocumentGroup] handleDocumentDownload", document);

    const result = await employeeConnector.downloadDocument(document.id);
    console.log("[DocumentGroup] handleDocumentDownload", result);

    const { data, headers } = result;
    const filename = `${user.id}_${document.id}`;

    downloadFile(data, headers["content-type"], filename);
  };

  /**
   * Filter list of documents to correspond to selected identity document type
   * @param {Object} documents
   * @returns {Array}
   */
  const selectedIdentite = (documents) => {
    const selected = identiteTypes.filter(
      (identiteType) => identiteType.value === currentGroup
    );

    return selected.length > 0
      ? documents.filter((document) =>
          selected[0].ids.includes(document.document_type.document_type_id)
        )
      : [];
  };

  /**
   * Filter list of documents to correspond to selected document group type
   * @param {Object} documents
   * @returns {Array}
   */
  const filterByGroupType = (documents) => {
    console.log("[DocumentGroup] filterByGroupType", documents, groupType);
    if (!documents || !Array.isArray(documents)) return [];
    return documents.filter(
      (document) => document.document_type.groupe === groupType
    );
  };

  /**
   * If documentGroupTypes is IDENTITE, filters requiredDocuments to see if some have non INIT status and preselect their group
   * @returns {void}
   */
  const preselectIdGroup = () => {
    if (!requiredDocuments || requiredDocuments.length === 0) return null;
    if (groupType !== documentGroupTypes.IDENTITE) return false;
    const list = filterByGroupType(requiredDocuments);
    list.forEach((document) => {
      if (
        statuses[document.status] !== statuses.INIT &&
        statuses[document.status] !== statuses.EMPTY
      ) {
        let value = "";
        identiteTypes.forEach((idType) => {
          value = idType.ids.includes(document.document_type.document_type_id)
            ? idType.value
            : value;
        });
        if (value) setCurrentGroup(value);

        return true;
      }
    });
    return false;
  };

  /**
   * Get user documents list
   * @returns {void}
   */
  const getDocuments = useCallback(async () => {
    setLoading("loading");
    try {
      const result = await employeeConnector.getDocuments();
      if (result) {
        setRequiredDocuments(result);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[DocumentGroup] getDocuments", error);
    }
  }, [employeeConnector]);

  /**
   * Render user documents list and uploaders
   * @returns {Array}
   */
  const renderDocuments = () => {
    if (!requiredDocuments || requiredDocuments.length === 0) return null;
    if (!currentGroup && groupType === documentGroupTypes.IDENTITE) {
      if (preselectIdGroup()) return null;
      else
        return (
          <Typography>{t("career.documents.select-id-doc-type")}</Typography>
        );
    }

    const list =
      groupType !== documentGroupTypes.IDENTITE
        ? filterByGroupType(requiredDocuments)
        : selectedIdentite(requiredDocuments);

    if (list.length === 0) {
      return (
        <Typography>{t("career.documents.noDocumentsRequired")}</Typography>
      );
    }

    return list.map((document) => (
      <Document
        key={document.id}
        document={document}
        onDocumentUpload={handleDocumentUpload}
        onDocumentUploadSuccess={handleDocumentUploadSuccess}
        onDocumentUploadFailed={handleDocumentUploadFailed}
        onDocumentDownload={handleDocumentDownload}
        onDocumentDelete={handleDocumentDelete}
      />
    ));
  };

  useEffect(() => {
    if (
      (props.currentGroup !== "" ||
        groupType !== documentGroupTypes.JUSTIFICATIF) &&
      requiredDocuments === null &&
      !loading
    ) {
      getDocuments();
    }
  }, [getDocuments, groupType, requiredDocuments, props, loading]);

  return (
    <>
      <Box mb="2em">
        <Typography>
          {t(`career.documents.description.${groupType}`)}
        </Typography>
        {groups.length > 1 ? (
          <Box mt="1em">
            {canChangeGroup ? (
              <Box>
                <Typography>
                  {t(`career.documents.question.${groupType}`)}
                </Typography>
                <Box mt="1em" mb="1em">
                  <FormControl>
                    <InputLabel id="document-groupe-type-select-label">
                      {t(`career.documents.list-label.${groupType}`)}
                    </InputLabel>
                    <Select
                      sx={{ minWidth: "15em", mr: "1em" }}
                      labelId="document-groupe-type-select-label"
                      disabled={loadingRequiredDocs}
                      onChange={handleGroupChange}
                      value={currentGroup}
                      label={t(`career.documents.list-label.${groupType}`)}
                    >
                      {groups.map((group) => (
                        <MenuItem key={group.value} value={group.value}>
                          {group.label[language]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {groupType !== documentGroupTypes.IDENTITE ? (
                  <Button
                    variant="contained"
                    disabled={currentGroup === "" || loadingRequiredDocs}
                    onClick={handleGroupValidate}
                  >
                    {t("career.documents.validate")}
                  </Button>
                ) : null}
              </Box>
            ) : (
              <Box>
                <Typography>{currentGroupLabel}</Typography>
              </Box>
            )}
          </Box>
        ) : null}
      </Box>
      {loading ? (
        <Box pt="1em" display="flex">
          <Box mr="2em">
            <CircularProgress />
          </Box>
          <Typography pt="0.5em">{t(`loader.${loading}`)}</Typography>
        </Box>
      ) : null}
      {renderDocuments()}
    </>
  );
};

export default DocumentGroup;

import React from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { getYearsFrom, getMonthsList } from "./user-profile.helper";
import { START_YEAR } from "./constants";

const ChildExperience = (props) => {
  const { num, handleDeleteChild, handleEditChild, selectSx, formik } = props;
  const { t } = useTranslation();
  return (
    <Box key={`child${num}`} mb="1em">
      <Box display="flex" justifyContent="space-between">
        <Typography pt="0.5em">
          {`${t("user-profile.experience.child")} n°${num + 1} - ${t(
            "user-profile.experience.birth-date"
          )}`}
        </Typography>
        <IconButton color="primary" onClick={() => handleDeleteChild(num)}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box>
          <FormControl
            error={
              formik.errors &&
              formik.errors.children &&
              !!formik.errors.children[num]
            }
          >
            <InputLabel id={`experience-child${num}Month-select-label`}>
              {t("user-profile.experience.month")}
            </InputLabel>
            <Select
              labelId={`experience-child${num}Month-select-label`}
              sx={{ ...selectSx, marginRight: "2em" }}
              label={t("user-profile.experience.month")}
              value={formik.values.children[num]?.birth_month || ""}
              onChange={(e) =>
                handleEditChild(num, "birth_month", e.target.value)
              }
            >
              {getMonthsList(t)}
            </Select>
          </FormControl>
          <FormHelperText error>
            {formik.errors &&
            formik.errors.children &&
            formik.errors.children[num]
              ? formik.errors.children[num]
              : null}
          </FormHelperText>
        </Box>
        <Box>
          <FormControl
            error={
              formik.errors &&
              formik.errors.children &&
              !!formik.errors.children[num]
            }
          >
            <InputLabel id={`experience-child${num}Year-select-label`}>
              {t("user-profile.experience.year")}
            </InputLabel>
            <Select
              labelId={`experience-child${num}Year-select-label`}
              sx={selectSx}
              label={t("user-profile.experience.year")}
              value={formik.values.children[num]?.birth_year || ""}
              onChange={(e) =>
                handleEditChild(num, "birth_year", e.target.value)
              }
            >
              {getYearsFrom(START_YEAR)}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default ChildExperience;

import axios from "axios";
import { appBaseIOConfig } from "../config.js";
import { logError } from "./connector.helpers.js";

class AppBaseIOConnector {
  constructor() {
    this.url = appBaseIOConfig.url;
    this.clusterName = appBaseIOConfig.clusterName;

    const base64Str = window.btoa(appBaseIOConfig.credentials);
    // const base64Str = Buffer.from(appBaseIOConfig.credentials, "utf8").toString(
    //   "base64"
    // );

    this.headers = {
      Authorization: `Basic ${base64Str}`,
      "Content-type": "application/json",
    };

    this.config = {
      headers: this.headers,
      crossDomain: true,
    };
    console.log(`[AppBaseIOConnector] config:`, this.config);
  }

  /**
   * Get config
   * @returns {Object}
   */
  getConfig = () => {
    return this.config;
  };

  /**
   * Get JobOffer by docId (AppBaseIO index)
   * @param {String} docId
   * @returns {Object}
   */
  getJobOffer = async (docId) => {
    const result = await axios
      .get(`${this.url}/${this.clusterName}/_doc/${docId}`, this.config)
      .catch((error) => {
        logError(error, "[AppBaseIOConnector - getJobOffer]");
        throw error;
      });

    return result?.data;
  };

  /**
   * Get JobOffer by search
   * @param {Object} search
   * ex: 
      {
        reference: reference,
      }
   * @returns {Object}
   */
  searchJobOffer = async (search) => {
    const data = {
      query: {
        match: search,
      },
    };
    const result = await axios(
      {
        method: "post",
        url: `${this.url}/${this.clusterName}/_doc/_search`,
        data: { ...data },
        ...this.config,
      },
      this.config
    ).catch((error) => {
      logError(error, "[AppBaseIOConnector - searchJobOffer]");
      throw error;
    });

    const d = result?.data;
    // console.log("[AppBaseIOConnector] searchJobOffer", d);

    return d?.hits?.hits.length ? d?.hits?.hits[0] : null;
  };
}

export default AppBaseIOConnector;

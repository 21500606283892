import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import PrepareInterviewMessage from "./PrepareInterviewMessage";
import PrepareFamilyMeetingMessage from "./PrepareFamilyMeetingMessage";

const Message = (props) => {
  const { message, showValidation, onValidate, autoOpen, onClose } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(autoOpen);

  /**
   * Handle message closing
   * @return {void}
   */
  const handleCloseMessages = useCallback(() => {
    console.log("[Message] handleCloseMessages");
    onClose();
    setOpen(false);
  }, [onClose]);

  /**
   * Handle message validation
   * @return {void}
   */
  const handleValidate = useCallback(() => {
    onValidate(message);
    handleCloseMessages();
  }, [handleCloseMessages, onValidate, message]);

  const existingMessages = {
    PREP_ENT: (
      <PrepareInterviewMessage
        onValidate={handleValidate}
        onClose={handleCloseMessages}
        showValidation={showValidation}
      />
    ),
    PREP_MEET: (
      <PrepareFamilyMeetingMessage
        onValidate={handleValidate}
        onClose={handleCloseMessages}
        showValidation={showValidation}
      />
    ),
  };

  return message ? (
    <Dialog open={open} onClose={handleCloseMessages}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box margin="auto">
            <Typography variant="h4">
              {t(`messages.${message}.title`)}
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={handleCloseMessages}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pb: "0", overflow: "hidden" }}>
        {existingMessages[message]}
      </DialogContent>
      <DialogActions>
        {showValidation ? (
          <Box p="1em">
            <Button variant="contained" onClick={handleValidate}>
              {t(`messages.${message}.validate`)}
            </Button>
          </Box>
        ) : null}
      </DialogActions>
    </Dialog>
  ) : null;
};

export default Message;

import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Box, CircularProgress, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";

const Progress = (props) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const { employeeConnector } = useAuth();
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Render progress tree from list of steps
   * @returns {Array}
   */
  const renderProgress = () => {
    if (!progress || progress.length === 0) return null;

    return progress.map((step, index) => {
      return (
        <Box mt="1.5em" ml="2em" key={index}>
          <Box display="flex">
            <CheckCircleIcon
              color={step.valide ? "success" : "disabled"}
              fontSize="large"
            />
            <Typography pt="0.5em" ml="0.5em" variant="h6">
              {step.text}
            </Typography>
          </Box>
          {step.parcours && step.open
            ? step.parcours.map((action, i) => {
                return (
                  <Box mt="0.5em" ml="2em" key={i} display="flex">
                    <CheckCircleIcon
                      color={action.valide ? "success" : "disabled"}
                      fontSize="large"
                    />
                    <Typography pt="0.5em" ml="0.5em" variant="h6">
                      {action.text}
                    </Typography>
                  </Box>
                );
              })
            : null}
        </Box>
      );
    });
  };

  /**
   * Get user career's progress
   * @returns {void}
   */
  const getProgress = useCallback(async () => {
    try {
      setLoading(true);
      const result = await employeeConnector.getProgress();
      if (result) {
        setProgress(result.data.results.parcours);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[CareerView] getProgress", error);
    }
  }, [alert, employeeConnector]);

  useEffect(() => {
    if (progress === null && !loading && employeeConnector) {
      getProgress();
    }
  }, [progress, getProgress, employeeConnector, loading]);

  return (
    <Box>
      <Typography variant="h4">{t("career.your-progress.title")}</Typography>
      {loading ? (
        <Box mt="1em">
          <CircularProgress />
        </Box>
      ) : null}
      {progress ? renderProgress() : null}
    </Box>
  );
};

export default Progress;

import React, { useCallback, useState, useRef } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import useSearchJob from "../../hooks/useSearchJob";

import { getYearsFrom, getMonthsList } from "./user-profile.helper";
import ChildExperience from "./ChildExperience";
import { isMobileDevice } from "../../helpers/common.helpers";
import { START_YEAR } from "./constants";

const Button = styled(MuiButton)(spacing);

const FormControlSx = isMobileDevice()
  ? {
      width: "100%",
    }
  : {};

const ExperienceForm = (props) => {
  const {
    handleCloseForm,
    handleAddExperience,
    handleUpdateExperience,
    experience,
  } = props;
  const { t, i18n } = useTranslation();
  const { repositories } = useSearchJob();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const formik = useFormik({
    initialValues: {
      type_id: experience?.type_id || "",
      month_start: experience?.month_start || "",
      year_start: experience?.year_start || "",
      month_end: experience?.month_end || "",
      year_end: experience?.year_end || "",
      children:
        experience?.children.map((child) => {
          return {
            child_id: child.child_id,
            birth_year: child.birth_year,
            birth_month: child.birth_month,
          };
        }) || [],
      missions:
        experience?.missions.map((mission) => {
          return { mission_id: mission.mission_id };
        }) || [],
    },
    onSubmit: (values) => {
      experience
        ? handleUpdateExperience(experience, values)
        : handleAddExperience(values);
      handleClose();
    },
    validate: (values) => {
      const errors = {};

      if (!values.type_id) errors.type_id = t("form.errors.required.type");

      if (!values.year_start)
        errors.year_start = t("form.errors.required.start-date");
      if (!values.month_start)
        errors.month_start = t("form.errors.required.start-date");
      if (!values.year_end)
        errors.year_end = t("form.errors.required.end-date");
      if (!values.month_end)
        errors.month_end = t("form.errors.required.end-date");
      if (
        values.year_end < values.year_start ||
        (values.year_end === values.year_start &&
          values.month_end < values.month_start)
      )
        errors.year_end = t("form.errors.invalid.end-date");

      for (let num in values.children) {
        if (
          values.children[num].birth_month === "" ||
          values.children[num].birth_year === ""
        ) {
          errors.children = Array.isArray(errors.children)
            ? [...errors.children]
            : [];
          errors.children[num] = t("form.errors.required.birth-date");
          break;
        }

        if (
          values.children[num].birth_year > values.year_end ||
          (values.children[num].birth_year === values.year_end &&
            values.children[num].birth_month > values.month_end)
        ) {
          errors.children = Array.isArray(errors.children)
            ? [...errors.children]
            : [];
          errors.children[num] = t("form.errors.invalid.birth-date");
        }
      }

      return errors;
    },
    validateOnChange: false,
    validateOnBlur: false,
  });
  const [currentExperienceChildren, setCurrentExperienceChildren] = useState(
    experience?.children.map((child) => {
      return {
        birth_year: child.birth_year,
        birth_month: child.birth_month,
      };
    }) || []
  );
  const [currentExperienceMissions, setCurrentExperienceMissions] = useState(
    experience?.missions.map((mission) => mission.mission_id) || []
  );

  const selectSx = {
    minWidth: fullScreen ? "9em" : "15em",
  };

  const stateRef = useRef();
  stateRef.current = {
    currentExperienceChildren: currentExperienceChildren,
    currentExperienceMissions: currentExperienceMissions,
  };
  const language = i18n.language;

  /**
   * Handle adding new child
   * @returns {void}
   */
  const handleAddChild = () => {
    formik.values.children = [
      ...currentExperienceChildren,
      { birth_month: "", birth_year: "", child_id: 0 },
    ];

    setCurrentExperienceChildren([...formik.values.children]);
  };

  /**
   * Handle adding new mission
   * @returns {void}
   */
  const handleAddMission = (e) => {
    const value = e.target.value;

    setCurrentExperienceMissions([...currentExperienceMissions, value]);
    formik.values.missions.push({ mission_id: value });
  };

  /**
   * Handle editing child
   * @param {integer} index
   * @param {string} prop
   * @param {string} value
   * @returns {void}
   */
  const handleEditChild = (index, prop, value) => {
    formik.values.children[index][prop] = value;

    setCurrentExperienceChildren([...formik.values.children]);
  };

  /**
   * Handle deleting child
   * @returns {void}
   */
  const handleDeleteChild = useCallback(
    (index) => {
      stateRef.current.currentExperienceChildren.splice(index, 1);
      setCurrentExperienceChildren([
        ...stateRef.current.currentExperienceChildren,
      ]);
      formik.values.children.splice(index, 1);
    },
    [formik.values]
  );

  /**
   * Handle deleting mission
   */
  const handleDeleteMission = useCallback(
    (value) => {
      setCurrentExperienceMissions([
        ...stateRef.current.currentExperienceMissions.filter(
          (mission) => mission !== value
        ),
      ]);
      formik.values.missions = formik.values.missions.filter(
        (mission) => mission.mission_id !== value
      );
    },
    [formik.values]
  );

  /**
   * Render selected missions
   * @returns {Array}
   */
  const renderMissions = () => {
    return currentExperienceMissions.map((mission) => {
      return (
        <Box key={`mission${mission}`} mr="1em" mt="0.5em">
          <Chip
            label={
              repositories?.childcares?.responsibilities[mission][language]
            }
            onDelete={() => handleDeleteMission(mission)}
          />
        </Box>
      );
    });
  };

  /**
   * Render experience types list
   * @returns {Array}
   */
  const renderExperienceTypes = () => {
    const result = [];
    const experienceTypes = repositories?.childcares?.contexts;

    for (const type in experienceTypes) {
      result.push(
        <MenuItem key={type} value={type}>
          {experienceTypes[type][language]}
        </MenuItem>
      );
    }

    return result;
  };

  /**
   * Render missions list
   * @returns {Array}
   */
  const renderMissionsList = () => {
    const result = [];
    const missions = repositories?.childcares?.responsibilities;

    for (const mission in missions) {
      if (
        formik.values.missions.filter((value) => value.mission_id === mission)
          .length === 0
      ) {
        result.push(
          <MenuItem key={mission} value={mission}>
            {missions[mission][language]}
          </MenuItem>
        );
      }
    }

    return result;
  };

  const handleClose = () => {
    formik.resetForm();
    formik.values.children = [];
    formik.values.missions = [];
    handleCloseForm();
    setCurrentExperienceChildren([]);
    setCurrentExperienceMissions([]);
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid">
          <Typography mb="0.5em" variant="h6">
            {t("user-profile.experience.type")}
          </Typography>
          <FormControl
            sx={FormControlSx}
            error={Boolean(formik.errors && formik.errors.type_id)}
          >
            <Box mb="1em" display="grid">
              <Select
                sx={selectSx}
                id="type_id"
                name="type_id"
                value={formik.values.type_id}
                onChange={formik.handleChange}
              >
                {renderExperienceTypes()}
              </Select>
              <FormHelperText>{formik.errors.type_id}</FormHelperText>
            </Box>
          </FormControl>
        </Box>
        <Box>
          <Typography mb="0.5em" variant="h6">
            {t("user-profile.experience.from")}
          </Typography>
          <Box sx={{ display: "flex", marginBottom: "1em" }}>
            <Box>
              <FormControl
                error={Boolean(
                  formik.errors &&
                    (formik.errors.yeart_start || formik.errors.month_start)
                )}
              >
                <InputLabel id="experience-fromMonth-select-label">
                  {t("user-profile.experience.month")}
                </InputLabel>
                <Select
                  sx={{ ...selectSx, marginRight: "2em" }}
                  labelId="experience-fromMonth-select-label"
                  id="month_start"
                  name="month_start"
                  label={t("user-profile.experience.month")}
                  value={formik.values.month_start}
                  onChange={formik.handleChange}
                >
                  {getMonthsList(t)}
                </Select>
              </FormControl>
              <FormHelperText error>
                {formik.errors.month_start || formik.errors.year_start
                  ? formik.errors.month_start
                  : null}
              </FormHelperText>
            </Box>
            <Box>
              <FormControl
                error={Boolean(
                  formik.errors &&
                    (formik.errors.yeart_start || formik.errors.month_start)
                )}
              >
                <InputLabel id="experience-fromYear-select-label">
                  {t("user-profile.experience.year")}
                </InputLabel>
                <Select
                  sx={{ ...selectSx }}
                  labelId="experience-fromYear-select-label"
                  id="year_start"
                  name="year_start"
                  label={t("user-profile.experience.year")}
                  value={formik.values.year_start}
                  onChange={formik.handleChange}
                >
                  {getYearsFrom(START_YEAR)}
                </Select>
              </FormControl>
              <FormHelperText error>
                {formik.errors.month_start || formik.errors.year_start
                  ? formik.errors.year_start
                  : null}
              </FormHelperText>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography mb="0.5em" variant="h6">
            {t("user-profile.experience.to")}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box>
              <FormControl
                error={Boolean(
                  formik.errors &&
                    (formik.errors.year_end || formik.errors.month_end)
                )}
              >
                <InputLabel id="experience-toMonth-select-label">
                  {t("user-profile.experience.month")}
                </InputLabel>
                <Select
                  sx={{ ...selectSx, marginRight: "2em" }}
                  labelId="experience-toMonth-select-label"
                  id="month_end"
                  name="month_end"
                  label={t("user-profile.experience.month")}
                  value={formik.values.month_end}
                  onChange={formik.handleChange}
                >
                  {getMonthsList(t)}
                </Select>
              </FormControl>
              <FormHelperText error>
                {formik.errors.month_end || formik.errors.year_end
                  ? formik.errors.month_end
                  : null}
              </FormHelperText>
            </Box>
            <Box>
              <FormControl
                error={Boolean(
                  formik.errors &&
                    (formik.errors.year_end || formik.errors.month_end)
                )}
              >
                <InputLabel id="experience-toYear-select-label">
                  {t("user-profile.experience.year")}
                </InputLabel>
                <Select
                  sx={{ ...selectSx }}
                  labelId="experience-toYear-select-label"
                  id="year_end"
                  name="year_end"
                  label={t("user-profile.experience.year")}
                  value={formik.values.year_end}
                  onChange={formik.handleChange}
                >
                  {getYearsFrom(START_YEAR)}
                </Select>
              </FormControl>
              <FormHelperText error>
                {formik.errors.month_end || formik.errors.year_end
                  ? formik.errors.year_end
                  : null}
              </FormHelperText>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between" mt="2em" mb="1em">
            <Typography variant="h6">
              {t("user-profile.experience.children")}
            </Typography>
            <IconButton color="primary" onClick={handleAddChild}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
          {currentExperienceChildren.length ? (
            currentExperienceChildren.map((childNum, i) => (
              <ChildExperience
                key={i}
                num={i}
                handleDeleteChild={handleDeleteChild}
                handleEditChild={handleEditChild}
                formik={formik}
                selectSx={selectSx}
              />
            ))
          ) : (
            <Typography mb="2em">
              {t("user-profile.experience.noChildren")}
            </Typography>
          )}
        </Box>
        <Box display="grid">
          <Typography mb="0.5em" variant="h6">
            {t("user-profile.experience.missions")}
          </Typography>
          <FormControl sx={FormControlSx}>
            <Select
              sx={selectSx}
              id="missions"
              name="missions"
              value=""
              onChange={(e) => handleAddMission(e)}
            >
              {renderMissionsList()}
            </Select>
          </FormControl>
          <Box flexWrap="wrap" display="flex" mt="1em">
            {renderMissions()}
          </Box>
        </Box>
        <Box mt="1em" display="flex" justifyContent="right">
          <Button onClick={handleClose}>{t("user-profile.cancel")}</Button>
          <Box ml="1em">
            <Button variant="contained" type="submit">
              {t("user-profile.save")}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ExperienceForm;

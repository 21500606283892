import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";

import {
  Alert as MuiAlert,
  TextField as MuiTextField,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import { detailedLanguage } from "../../helpers/translations.helpers";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ForgotPassword() {
  // const navigate = useNavigate();
  const { forgotPassword } = useAuth();
  const { t, i18n } = useTranslation();
  const [successMessage, setSuccessMessage] = useState(null);

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("auth.validation.valid-email"))
          .max(255)
          .required(t("auth.validation.required-email")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const response = await forgotPassword(
            values.email,
            detailedLanguage[i18n.language]
          );
          console.log("[ForgotPassword] response", response);

          setSuccessMessage(response.message);

          // navigate(`/auth/sign-in`);
        } catch (error) {
          const errorMessage = error?.message || t("auth.errors.global");

          setStatus({ success: false });
          setErrors({ submit: errorMessage });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {successMessage && (
            <Alert mt={2} mb={1} severity="success">
              {successMessage}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label={t("auth.login")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
            size="large"
            className="auth-button"
          >
            {t("auth.forgot-password-submit")}
          </LoadingButton>
          <Button
            component={Link}
            to={`/auth/sign-in`}
            fullWidth
            color="primary"
            size="large"
          >
            {t("auth.back-to-sign-in")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ForgotPassword;

import React from "react";
const Tooltip = ({ title, children, code }) => {
  return (
    <div className="tooltip">
      {children}
      <span
        className="tooltiptext"
        dangerouslySetInnerHTML={{
          __html: code ? `<pre>${title}</pre>` : title,
        }}
      />
    </div>
  );
};

export default Tooltip;

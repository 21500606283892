import React from "react";

import { Card, CardContent } from "@mui/material";

import RequiredDocuments from "../../components/documents/RequiredDocuments";
import Quiz from "../../components/career/Quiz";
import Engagement from "../../components/career/Engagement";
import Progress from "../../components/career/Progress";

const cardSx = {
  padding: "1em",
  marginBottom: "1em",
};

function CareerView() {
  return (
    <>
      <Card sx={cardSx}>
        <CardContent>
          <Progress />
        </CardContent>
      </Card>

      <Card sx={cardSx}>
        <CardContent>
          <RequiredDocuments />
        </CardContent>
      </Card>

      <Quiz cardSx={cardSx} />

      <Card sx={cardSx}>
        <CardContent>
          <Engagement />
        </CardContent>
      </Card>
    </>
  );
}

export default CareerView;

import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import JobOffer from "../../components/SearchJob/JobOffer";

import useAuth from "../../hooks/useAuth";
import useSearchJob from "../../hooks/useSearchJob";
import useAlert from "../../hooks/useAlert";

import Loader from "../../components/SearchJob/Loader";

import AppBaseIOConnector from "../../connectors/AppBaseIOConnector";
import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

/**
 * Returns JobOfferView
 * @returns {ReactComponent}
 */
function JobOfferView() {
  const { t } = useTranslation();
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [isCurrentJobOfferLoaded, setIsCurrentJobOfferLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const { user, setAppUserJobOffers } = useAuth();
  const { currentJobOffer, updateCurrentJobOffer } = useSearchJob();
  const alert = useAlert();

  /**
   * Complete data allready loaded by setAppUser()
   * @returns {void}
   */
  const initJobOffers = useCallback(async () => {
    console.log("[JobOfferView] initJobOffers");

    await setAppUserJobOffers();
  }, [setAppUserJobOffers]);

  /**
   * Check currentJobOffer, retrieve it if necessary
   * @returns {void}
   */
  const initCurrentJobOffer = useCallback(
    async (t) => {
      console.log("[JobOfferView] initCurrentJobOffer");

      try {
        const appBaseIOConnector = new AppBaseIOConnector();
        const match = {
          reference: id,
        };
        const result = await appBaseIOConnector.searchJobOffer(match);

        setIsCurrentJobOfferLoaded(true);

        if (result) {
          updateCurrentJobOffer(result?._source);
        } else {
          // 404
          alert.error({ message: t("job-offer.not-found", { reference: id }) });
        }
      } catch (error) {
        alert.error({ message: parseResponseErrorMessage(error) });
        console.error("[JobOfferView] initCurrentJobOffer", error);
      }
    },
    [alert, id, updateCurrentJobOffer]
  );

  useEffect(
    () => {
      console.log(
        "[JobOfferView] useEffect - user:",
        user,
        "currentJobOffer:",
        currentJobOffer
      );

      if (user) {
        if (!user.jobOffers) {
          setIsLoading(true);
          initJobOffers();
        } else {
          setIsLoading(false);
          setIsUserDataLoaded(true);

          // direct call to jobOffer view > we retrieve JobOffer from APPBaseIO index
          if (!currentJobOffer && !isCurrentJobOfferLoaded) {
            console.warn(
              `[JobOfferView] useEffect - no currentJobOffer > calling AppBaseIOConnector with id: ${id}`
            );
            setIsLoading(true);
            initCurrentJobOffer(t);
          } else {
            // classic navigation > we use stored currentJobOffer
            setIsCurrentJobOfferLoaded(true);
            setIsLoading(false);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentJobOffer,
      id,
      initCurrentJobOffer,
      initJobOffers,
      isCurrentJobOfferLoaded,
      user,
    ]
  );

  return (
    <>
      <section className="job-offer">
        <Loader show={isLoading} />
        {isUserDataLoaded && isCurrentJobOfferLoaded && currentJobOffer && (
          <JobOffer />
        )}
      </section>
    </>
  );
}

export default JobOfferView;

import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const Due = (props) => {
  const { employeeConnector } = useAuth();
  const { t } = useTranslation();
  const [dues, setDues] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Handle opening due file
   * @returns {void}
   */
  const handleSeeActionClick = async (due) => {
    console.log("[DUE] handleSeeActionClick", due);
    try {
      const result = await employeeConnector.getDueFile(due.declaration_id);
      if (result) {
        window.open(result.data.results.url, "_blank");
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[DUE] getDuesList", error);
    }
  };

  /**
   * Get due list of user
   * @returns {void}
   */
  const getDuesList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getDues();
      if (result) {
        setDues(result.data.results.dues);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[DUE] getDuesList", error);
    }
  }, [employeeConnector]);

  /**
   * Render DUE (may be list)
   * @returns {Array}
   */
  const renderDues = () => {
    if (!dues || dues.length === 0) return null;
    return dues.map((due) => {
      return (
        <Box key={due.declaration_id} mt="1em">
          <Card>
            <CardContent>
              <Typography mb="1em">
                {t("contract.due.employer")} - {due.declaration_libelle}
              </Typography>
              <Divider my={6} />
              <Typography mt="1em">
                {t("contract.due.begins-on")} {due.declaration_date}
              </Typography>
              <LoadingButton
                mt="1em"
                variant="contained"
                onClick={() => handleSeeActionClick(due)}
                loading={loading}
              >
                {t("contract.due.see-action")}
              </LoadingButton>
            </CardContent>
          </Card>
        </Box>
      );
    });
  };
  useEffect(() => {
    if (dues === null && employeeConnector && !loading) {
      getDuesList();
    }
  }, [dues, getDuesList, employeeConnector, loading]);

  return (
    <Box>
      <Box display="grid" justifyContent="center">
        <Typography>{t("contract.due.description")}</Typography>
        <Box
          sx={{
            marginBottom: "1em",
          }}
        >
          {loading && !dues ? (
            <Box mt="1em">
              <CircularProgress />
            </Box>
          ) : null}
          {renderDues()}
        </Box>
      </Box>
    </Box>
  );
};

export default Due;

import React, { useState } from "react";
import PayslipList from "../../components/payslips/PayslipList";
import Rib from "../../components/payslips/Rib";

const PayslipsView = (props) => {
  const [ribView, setRibView] = useState(false);
  const [ribRequired, setRibRequired] = useState(false);

  const onRibViewClick = (required) => {
    setRibRequired(required);
    setRibView(true);
  };

  const onListViewClick = () => {
    setRibView(false);
  };

  return ribView ? (
    <Rib ribRequired={ribRequired} onListViewClick={onListViewClick} />
  ) : (
    <PayslipList onRibViewClick={onRibViewClick} />
  );
};

export default PayslipsView;

import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Helmet } from "react-helmet-async";

import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import RAFView from "./RAFView";

import { pagesMargin } from "../../constants";

function RAF() {
  const { t } = useTranslation();
  const theme = useTheme();
  const noMargin = useMediaQuery(theme.breakpoints.down("lg"));

  const marginSx = noMargin ? null : pagesMargin;

  return (
    <React.Fragment>
      <Helmet title={t("menu.refer")} />

      <Box sx={marginSx}>
        <Typography
          variant="h3"
          gutterBottom
          display="grid"
          className="view-title"
        >
          {t("menu.refer")}
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <RAFView />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default RAF;

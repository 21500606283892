/**
 * Returns Gmap Distance Result
 * @param {Object}
 * @returns {Object}
 */
export const getGmapDistanceResult = (response) => {
  // console.log("[getGmapDistanceResult]", response);
  let result = {
    travel: null,
    origin: null,
    destination: null,
  };

  if (response) {
    result = {
      travel: response?.rows[0].elements[0],
      origin: response?.origin_addresses[0],
      destination: response?.destination_addresses[0],
    };
  }

  return result;
};

/**
 * Returns Gmap Geocoding Result
 * @param {Object}
 * @returns {Object}
 */
export const getGmapGeocodingResult = (response) => {
  let result = {
    location: null,
  };

  if (response && response.results.length) {
    result = {
      location: response.results[0]?.geometry?.location,
    };
  }

  return result;
};

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import CloseIcon from "@mui/icons-material/Close";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import EngagementText from "./EngagementText";

import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";

const Button = styled(MuiButton)(spacing);

const Engagement = (props) => {
  const { cardSx } = props;
  const { t } = useTranslation();
  const alert = useAlert();
  const { employeeConnector } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [approved, setApproved] = useState(null);
  const [hasRead, setHasRead] = useState(null);

  /**
   * Handle the engagement convention approving
   * @returns {void}
   */
  const handleApproveChange = () => {
    setHasRead(!hasRead);
  };

  /**
   * Handle engagement dialog opening
   * @returns {void}
   */
  const handleOpenEngagement = () => {
    setOpenDialog(true);
  };

  /**
   * Handle engagement dialog closing
   * @returns {void}
   */
  const handleCloseEngagement = () => {
    setOpenDialog(false);
  };

  /**
   * Handle the engagement convention validation
   * @returns {void}
   */
  const handleValidateEngagement = async () => {
    try {
      const result = await employeeConnector.postEngagement();
      if (result) {
        setApproved(true);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[CareerView] postEngagement", error);
    }
    handleCloseEngagement();
  };

  /**
   * Get status of the user's engagement
   * @returns {void}
   */
  const getApproved = useCallback(async () => {
    try {
      const result = await employeeConnector.getEngagement();
      if (result) {
        setApproved(result.data.results.confirmation);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      console.error("[CareerView] getEngagement", error);
    }
  }, [employeeConnector, alert]);

  useEffect(() => {
    if (approved === null && employeeConnector) {
      getApproved();
    }
  }, [approved, employeeConnector, getApproved]);

  return (
    <>
      <Card sx={cardSx}>
        <CardContent>
          <Typography mb="1em" variant="h4">
            {t("career.engagement.title")}
          </Typography>
          <Typography mb="2em">
            {approved
              ? t("career.engagement.has-read")
              : t("career.engagement.description")}
          </Typography>

          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              size="large"
              onClick={handleOpenEngagement}
            >
              {approved
                ? t("career.engagement.see-engagement")
                : t("career.engagement.read")}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={openDialog}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography pl="25%" variant="h4">
              {t("career.engagement.modal-title")}
            </Typography>
            <IconButton onClick={handleCloseEngagement}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb="1em">
            <EngagementText />
          </Box>
          {approved ? null : (
            <Box>
              <Box display="flex">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasRead}
                      onChange={handleApproveChange}
                    />
                  }
                  label={t("career.engagement.check")}
                />
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  disabled={!hasRead}
                  variant="contained"
                  size="large"
                  onClick={handleValidateEngagement}
                >
                  {t("career.engagement.validate")}
                </Button>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Engagement;

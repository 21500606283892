import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { parseResponseErrorMessage } from "../../helpers/errorMessages.helpers";

import useAlert from "../../hooks/useAlert";
import useAuth from "../../hooks/useAuth";

import {
  Box,
  Button as MuiButton,
  Card,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { spacing } from "@mui/system";

import { healthcareChoices, healthcareActions } from "./constants";

const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);

const HealthCareList = (props) => {
  const { employeeConnector } = useAuth();
  const navigate = useNavigate();
  const alert = useAlert();
  const { t } = useTranslation();
  const [list, setList] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleActionClick = (healthcare) => {
    console.log("[HealthCareList] handleActionClick", healthcare);
    navigate(`/contracts/healthcares/${healthcare.mutuelle_id}`);
  };

  const handleSeeFileClick = async (healthcare) => {
    console.log("[HealthCareList] handleSeeFileClick", healthcare);
    setLoading(true);
    try {
      const result = await employeeConnector.getHealthcareFile(healthcare);
      if (result) {
        const { url } = result.data.results;
        if (url) {
          window.open(`https://${url}`, "_blank").focus();
          setLoading(false);
          console.log("[HealthCareList] handleSeeFileClick", url);
        }
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[HealthCareList] handleSeeFileClick", error);
    }
  };

  /**
   * Get healthcare list of user
   * @returns {void}
   */
  const getHealthcareList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await employeeConnector.getHealthcares();
      if (result) {
        setList(result.data.results.mutuelles);
        setLoading(false);
      }
    } catch (error) {
      alert.error({ message: parseResponseErrorMessage(error) });
      setLoading(false);
      console.error("[HealthCareList] getHealthcareList", error);
    }
  }, [employeeConnector, alert]);

  /**
   * Render status text
   * @param {Interger} status
   * @returns {Object}
   */
  const renderStatus = (status) => {
    if (!status || status === "0") return null;

    if (status === healthcareChoices.ACCEPTED)
      return <Typography>{t("contract.healthcare.accepted")}</Typography>;
    if (status === healthcareChoices.REFUSED)
      return <Typography>{t("contract.healthcare.refused")}</Typography>;

    return null;
  };

  /**
   * Render action button
   * @param {Object} healthcare
   * @returns {Object}
   */
  const renderActionButton = (healthcare) => {
    let action = null;
    if (healthcare.to_confirm) action = healthcareActions.CHOOSE;
    else if (healthcare.to_sign) action = healthcareActions.SIGN;
    else if (healthcare.to_read_next_step) action = healthcareActions.COMPLETE;

    if (!action) return null;

    return (
      <Button variant="contained" onClick={() => handleActionClick(healthcare)}>
        {t(`contract.healthcare.action-button.${action}`)}
      </Button>
    );
  };

  /**
   * Render list of healthcares
   * @returns {Array}
   */
  const renderList = () => {
    if (loading && !list) {
      return (
        <Box mt="5em" display="grid" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }
    if (!list || (list && list.length === 0)) return null;

    return list.map((healthcare) => {
      return (
        <Card
          key={healthcare.mutuelle_id}
          sx={{
            cursor: "pointer",
            marginBottom: "1em",
          }}
        >
          <CardContent>
            <Typography>
              {t("contract.healthcare.title")} {healthcare.libelle}
            </Typography>
            <Divider my={6} />
            {renderStatus(healthcare.choix)}
            <Box mt="1em" display="flex">
              {healthcare.to_read ? (
                <Box mr="1em">
                  <LoadingButton
                    variant="contained"
                    onClick={() => handleSeeFileClick(healthcare.mutuelle_id)}
                    loading={loading}
                  >
                    {t("contract.healthcare.see-document")}
                  </LoadingButton>
                </Box>
              ) : null}
              {renderActionButton(healthcare)}
            </Box>
          </CardContent>
        </Card>
      );
    });
  };

  useEffect(() => {
    if (list === null && employeeConnector && !loading) {
      getHealthcareList();
    }
  }, [list, employeeConnector, getHealthcareList, loading]);

  return (
    <>
      <Box mb="1em">
        <Typography>{t("contract.healthcare.momji-obligation")}</Typography>
        <Typography mt="1em">
          {t("contract.healthcare.your-obligation")}
        </Typography>
      </Box>
      <Box>{renderList()}</Box>
    </>
  );
};

export default HealthCareList;

const translations = {
  common: {
    dateFieldInputFormat: "dd/MM/yyyy hh:mm",
    documentation: "Documentation",
  },
  datagrid: {
    id: "ID",
    name: "Nom",
    firstname: "Prénom",
    userType: "Type d'utilisateur",
    code: "Code",
    isActive: "Actif",
    isActiveF: "Active",
    edit: "Editer",
    delete: "Supprimer",
    activate: "Activer",
    deactivate: "Désactiver",
    actions: "Actions",
    type: "Type",
    httpMethod: "Méthode HTTP",
    path: "Chemin",
    api: "API",
    role: "Rôle",
    action: "Action",
    department: "Département",
  },
  search: "Rechercher ...",
  home: {
    title: "Tableau de bord",
    breadcrumb: "Accueil",
  },

  loader: {
    loading: "Chargement en cours",
    add: "Ajout en cours",
    delete: "Suppression en cours",
    downloading: "Téléchargement en cours",
    "repositories-loading": "Chargement en cours des données ...",
  },

  form: {
    saveChanges: "Enregistrer",
    cancel: "Annuler",
    delete: "Supprimer",
    errors: {
      required: {
        type: "Type requis",
        "start-date": "Date de début incomplète",
        "end-date": "Date de fin incomplète",
        "birth-date": "Date de naissance requise",
        field: "Ce champ est obligatoire",
      },
      invalid: {
        "end-date": "La date de fin doit être postérieur à celle de début",
        "birth-date":
          "La date de naissance doit être antérieure à la date de fin de l'expérience",
        code: "Le format du code n'est pas correct",
        duration:
          "La prestation doit être d'une durée entre une et douzes heures",
      },
    },
  },
  dialog: {
    cancel: "Annuler",
    confirm: "Confirmer",
    confirmChangeText:
      "Êtes-vous sûr de vouloir enregistrer ces modifications ?",
    confirmDeleteText: "Êtes-vous sûr de vouloir supprimer cet élément ?",
    confirmCancel:
      "Vous avez des changements non enregistrés, voulez vous vraiment continuer ?",
  },

  alert: {
    success: {
      create: "Création réussie",
      add: "Ajout réussi",
      delete: "Suppression réussie",
      update: "Modification réussie",
      loading: "Chargement réussi",
      //apply: "Candidature enregistrée",
      //bookmark: "Offre mise en favori",
      //unbookmark: "Offre retirée des favoris",
      "email-alert-on": "Alerte mail activée",
      "email-alert-off": "Alerte mail désactivée",
      "forgot-password": "Un mail a été envoyé à votre adresse",
      "job-offer-bookmarked": "Offre {{ jobOffer.reference }} mise en favori",
      "job-offer-unbookmarked":
        "Offre {{ jobOffer.reference }} retirée des favoris",
      "job-offer-applied":
        "Candidature enregistrée à l'offre {{ jobOffer.reference }}",
      "add-experience": "Expérience ajoutée",
      "job-offer-deleted":
        "Offre  {{ jobOffer.reference }} supprimée de vos suggestions",
      "job-offer-unapplied":
        "Candidature retiré pour l'offre {{ jobOffer.reference }}",
      "to-clipboard": "Ajouté au presse-papier",
    },
  },

  dropzone: {
    drophere: "Déposer les fichiers ou cliquer ici pour sélectionner",
    success: "Succès",
    failed: "Echec",
  },

  menu: {
    home: "Tableau de bord",
    "job-offers": "Nos offres d'emploi",
    contracts: {
      contracts: "Mes contrats",
      due: "DUE",
      healthcare: "Mutuelle",
    },
    payslips: "Mes fiches de paie",
    planning: "Mon planning",
    documents: "Mes documents",
    resources: {
      resources: "Accompagnement Mômji",
      activities: "Mes activités du mois",
      "good-deals": "Mes bons plans",
      training: "Ma formation Mômji",
    },
    mandatory: "Action obligatoire",
    "user-profile": "Mon profil",
    career: "Mon parcours Mômji",
    "my-offers": {
      "my-offers": "Mes offres",
      applications: "Candidatures",
      bookmarks: "Favoris",
      pushes: "Suggestions",
    },
    refer: "Parrainez vos amis",
  },

  detail: {
    name: "Nom",
    family_name: "Nom",
    firstname: "Prénom",
    given_name: "Prénom",
    userType: "Type d'utilisateur",
    isActive: "Actif",
    isActiveF: "Active",
    id: "ID ",
    createdAt: "Crée le",
    updatedAt: "Modifié le",
    description: "Description",
    code: "Code",
    fieldsRequired: "Les champs marqués d'une * sont obligatoires",
    createdBy: "Crée par",
    updatedBy: "Modifié par",
    type: "Type",
    httpMethod: "Méthode HTTP",
    roleType: "Type de rôle",
    department: "Département",
    action: "Action",
    api: "Api",
    path: "Chemin",
    email: "Adresse email",
    role: "Rôle",
    noValue: "Aucune valeures",
  },

  auth: {
    loading: "Chargement des données de l'utilisateur",
    welcome: "Accéder à mon compte",
    "missed-you": "Vous nous avez manqué",
    "sign-in": "Authentifiez-vous avant de poursuivre",
    profile: "Profil",
    "sign-out": "Déconnexion",
    "sign-out-progress": "Déconnexion en cours ...",
    account: "Compte",
    login: "Votre adresse mail",
    password: "Votre mot de passe",
    "remember-me": "Se souvenir de moi",
    "forgot-password": "J'ai oublié mon mot de passe",
    "reset-password": "Saisir un nouveau mon mot de passe",
    "reset-password-both-same": "Vos 2 mots de passe doivent être identiques",
    "reset-password-new": "Votre nouveau mot de passe",
    "reset-password-new-confirm": "Confirmez votre nouveau mot de passe",
    submit: "Envoyer",
    validation: {
      "valid-email": "Adresse mail non valide",
      "required-email": "Adresse mail nécessaire",
      "required-password": "Mot de passe nécessaire",
    },
    errors: {
      global: "Something went wrong ...",
    },
    "forgot-password-email":
      "Veuillez entrer votre adresse mail afin de ré-initialiser votre mot de passe",
    "forgot-password-submit":
      "Faire une demande pour ré-initialiser mon mot de passe",
    "reset-password-email": "Veuillez saisir votre nouveau mot de passe",
    "reset-password-submit": "Enregistrer mon nouveau mon mot de passe",
    "reset-password-min-size":
      "Votre mot de passe doit faire au moins 12 caractères",
    "auto-connect": "Connexion en cours à votre espace ...",
    "page-not-found-title": "La page demandée n'existe pas.",
    "page-not-found-description":
      "La page demandée n'existe pas ou a été déplacée ...",
    "back-to-website": "Revenir au site",
    "back-to-login": "Revenir à la page de connexion",
    required: "requis",
    "bad-password-format":
      "Le mot de passe ne correspond pas au format attendu. Il doit posséder : 8 caractères, au moins un nombre, au moins une lettre en minuscule, au moins une lettre en majuscule",
    "back-to-sign-in": "Revenir à la page d'authentification",
    "internal-error-title": "Erreur interne du serveur",
    "internal-error-description":
      "Le serveur a rencontré une erreur inattendue qui ne lui permet pas de terminer la demande.",
  },

  "search-job": {
    application: "Candidature",
    rejected: "Candidature rejetée",
    canceled: "Candidature annulée",
    meeting: "Rencontre en cours",
    contract: "Contrat en cours",
    transmission: "Candidature transmise",
    search: "Rechercher",
    "search-loading": "Chargement en cours ...",
    "search-lazy-loading": "Chargement des offres en cours ...",
    "search-data-loading": "Chargement des données en cours ...",
    "my-offers-loading": "Chargement de vos offres en cours ...",
    "job-offer-types": "Types d'offre",
    fromDate: "A partir du",
    child: "Enfant",
    children: "Enfants",
    languages: "Langues",
    "languages-levels": "Niveaux par langue",
    "starting-address": "Adresse de départ",
    "starting-address-input": "Selectionnez une adresse de départ",
    distance: "Distance",
    "starting-location": "Lieu de départ",
    filters: "Filtres",
    filter: "Filtrer",
    "init-filters": "Réinitialiser les filtres",
    "filters-loading": "Chargement des filtres ...",
    "children-ages": "Age de l'enfant le plus jeune",
    "children-ages-ranges": {
      1: "< 1 an",
      2: "1 - 2 ans",
      3: "3 - 5 ans",
      4: "> 6 ans",
    },
    "children-nber-ranges": {
      3: "3 et plus",
    },
    "children-total": "Nombre d'enfants",
    availabilities: "Disponibilités",
    "childcare-skills": "Compétences en garde d'enfants",
    hoursByWeek: "heures / semaine",
    days: {
      monday: "L",
      tuesday: "M",
      wednesday: "M",
      thursday: "J",
      friday: "V",
      saturday: "S",
      sunday: "D",
    },
    "time-from": "A partir de",
    relevance: "pertinence",
    package: "package",
    "zip-code": "code postal",
    asc: "ASC",
    desc: "DESC",
    reference: "Numéro de l'offre",
    "reference-placeholder": "N° de l'offre",
    offer: "offre",
    offers: "offres",
    "email-alert": "Alerte par email",
    "email-alert-label":
      "Cochez cette case si vous souhaitez recevoir les nouvelles offres correspondant à vos critères",
    btn: "Voir les {{ items }} offres",
    "no-results": "Aucune offre n'a été trouvée ...",
    sorting: {
      label: "Trier",
      distance: "Distance",
      "hours-by-week": "Heures par semaine",
      "starting-date": "Date de démarrage",
      package: "Package",
      asc: "croissant",
      desc: "décroissant",
    },
    "result-stats": {
      none: "Aucune offre n'a été trouvée ...",
      one: "<b>1</b> offre a été trouvée",
      many: "<b>{{ numberOfResults }}</b> offres ont été trouvées",
    },
    "job-offer-sub-types": "Type d'offre",
    "job-offer-childcare-types": "Nos offres de garde en",
    "job-offer-course-types": "Nos offres de cours de langue en",
  },

  "job-offer": {
    offer: "Offre",
    status: "Statut",
    dealstatus: {
      none: "Vous n'avez pas encore postulé à cette offre",
      transmission: "Votre candidature a été transmise au client",
      meeting:
        "Votre candidature a été retenu et vous êtes en processus de rencontre",
      contract: "Votre candidature a été acceptée",
      archive: "Votre candidature a été archivée",
      rejected: "Votre candidature a été rejetée",
      application: "Vous avez envoyé votre candidature à cette offre",
    },

    validationStep: {
      validation: "Validation",
      correspondingAvailability: "Disponibilités horaires",
      validateSchedule:
        "Je m'assure d'être disponible aux horaires demandés dans le planning de l'offre",
      validateDates:
        "Je m'assure d'être disponible entre les dates demandées par l'offre",
      startWhen: "Je suis disponible à partir du ",
      endWhen: "Je suis disponible jusqu'au ",
      travelTime: "Temps de trajet",
      validateTravelTime:
        "Je m'assure de ne pas avoir plus d'une heure de trajet pour cette offre",
      validateApply: "Je valide ma candidature",
      yes: "oui",
      no: "non",
    },

    applied: {
      title: "Candidature enregistrée",
      congratulation: "Félicitations !",
      tips: "Pour maximiser vos chances de trouver une famille, nous vous conseillons de postuler aussi à d'autres offres.",
      description:
        "Votre candidature a bien été prise en compte et nous allons l’étudier dans les jours qui viennent. ",
      return: "Je postule à d’autres offres",
    },

    start: "Démarrage",
    end: "Fin",
    perWeek: "par semaine",
    hour: "heure",
    grossSalary: "Salaire brut",
    paidLeave: "Congés",
    transportation: "Transport",
    detail: "Détails de l'offre",
    "see-detail": "Voir les détails de l'offre",
    type: "Type d'offre",
    languageLevel: "Niveaux de langues minimum requis",
    dates: "Dates",
    schedule: "Planning de garde",
    client: "La famille",
    location: "Localisation",
    salary: "Package / heure",
    travelTime: "Temps de trajet",
    similarOffers: "Offres similaires",
    apply: "Postuler",
    unapply: "Retirer ma candidature",
    yearsOld: "ans",
    and: "et",
    "not-found": "Offre n° {{ reference }} inconnue",
    map: {
      "load-data": "Charger les données au déplacement",
    },

    "sub-types": {
      initiation:
        "Garde en initiation : c’est en français avec 30 min d’initiation à l’anglais",
      immersion: "Garde en immersion : c’est 100% dans une langue étrangère",
      creative:
        "Garde créative : c’est 100% en français avec des d’activités créatives à réaliser",
      course:
        "Cours de langues : vous préparez et animez un cours de langues pour répondre aux objectifs de votre élève",

      details: {
        initiation:
          "Garde en initiation : c’est en français avec 30 min d’initiation à l’anglais",
        immersion: "Garde en immersion : c’est 100% en {{ language }}",
        creative:
          "Garde créative : c’est 100% en français avec des d’activités créatives à réaliser",
        course:
          "Cours de langues : vous préparez et animez un cours de langues pour répondre aux objectifs de votre élève",
      },

      tooltips: {
        initiation:
          "Mômji Initiation : en français avec 30 min d’initiation à l’anglais",
        immersion: "Mômji Immersion : en langue étrangère ({{ language }})",
        creative: "Mômji Créative : en français avec des activités créatives",
        course: "Cours : moments basés sur des leçons",
      },

      titles: {
        initiation: "Garde Initiation à l’anglais",
        immersion: "Garde Immersion en {{ language }}",
        creative: "Garde Créative en français",
        course: "Cours de langue en {{ language }}",
      },
    },
    "additionnal-info": "Informations complémentaires",
  },

  "user-profile": {
    yourProfile: "Mon Profil",
    informations: "Mes informations",
    professionalExperiences: "Mes expériences professionnelles",
    noExperiences: "Aucune expérience enregistrée",
    newExperience: "Nouvelle expérience professionnelle",
    communicationLanguage: "Langue de Communication",
    changeCommunicationLanguage:
      "Pour modifier la langue de votre espace et la langue dans laquelle on vous envoie nos communications.",
    password: "Mon mot de passe",
    resetPassword:
      "Pour réinitialiser votre mot de passe, cliquez sur le bouton ci-dessous.",
    resetMyPassword: "Réinitialiser mon mot de passe",
    cancel: "Annuler",
    save: "Enregistrer",
    languages: {
      "en-GB": "Anglais",
      "fr-FR": "Français",
    },
    firstname: "Prénom",
    lastname: "Nom",
    nationality: "Nationalité",
    phoneNumber: "Numéro de téléphone",
    email: "Email",
    address: "Adresse",
    zipcode: "Code postal",
    city: "Ville",
    experience: {
      experience: "Expérience",
      duration: "Durée",
      "birth-date": "Date de naissance",
      newBorn: "Nouveau né",
      type: "Type",
      from: "Du",
      to: "Au",
      month: "Mois",
      year: "Année",
      children: "Enfants",
      noChildren: "Aucun enfant renseigné",
      child: "Enfant",
      missions: "Missions",
      January: "Janvier",
      February: "Février",
      March: "Mars",
      April: "Avril",
      May: "Mai",
      June: "Juin",
      July: "Juillet",
      August: "Août",
      September: "Septembre",
      October: "Octobre",
      November: "Novembre",
      December: "Décembre",
      monthOld: "mois",
      monthsOld: "mois",
      yearsOld: "ans",
      yearOld: "an",
      and: "et",
    },
  },

  "on-boarding": {
    welcome: {
      title: "Bienvenue chez Mômji !",
      text: "Dans votre espace privé, accédez à toutes nos offres, postulez et trouvez votre job Mômji !",
    },
    profile: {
      title: "Complètez votre profil",
      text: "Plus vite votre profil sera rempli, plus vite il sera transmis à des familles et vous pourrez démarrer votre mission ! N’attendez plus et complétez-le !",
    },
    offers: {
      title: "Nos offres",
      text: {
        immersion: "Garde en immersion : c’est 100% dans une langue étrangère",
        initiation:
          "Garde en initiation : c’est en français avec 30 min d’initiation à l’anglais",
        creative:
          "Garde créative : c’est 100% en français avec plein d’activités créatives à réaliser",
      },
    },
    discover: {
      title: "À la découverte de Mômji",
    },
    helpcenter: "Notre centre d'aide",
  },

  "my-offers": {
    applications: "Candidatures",
    bookmarks: "Favoris",
    pushes: "Suggestions",
  },

  career: {
    documents: {
      documents: "Documents",
      addFile: "Ajouter un fichier ou une photo",
      JUSTIFICATIF: "Justificatif pour travailler en France",
      IDENTITE: "Documents d'identité",
      OTHER: "Documents supplémentaires demandés",
      status: {
        init: "Requis",
        invalidated: "Refusé",
        "to-validate": "En attente de validation",
        validated: "Validé",
      },
      validate: "Valider",
      noDocumentsRequired: "Pas de document supplémentaire nécessaire",
      "select-id-doc-type": "Sélectionner un type de document",
      acceptedFormat: "Formats acceptés",
      maxSize: "Taille maximale acceptée",
      alreadyUploaded: "Document déjà téléversé",
      description: {
        IDENTITE:
          "Votre document d’identité est nécessaire pour éditer les documents relatifs à vos contrats chez Mômji.",
        JUSTIFICATIF:
          "Les documents suivants sont nécessaires pour te permettre de travailler en France.",
        OTHER: "Les documents suivants sont demandés par notre service",
      },
      question: {
        IDENTITE: "Quel type de document d’identité possèdez-vous ?",
        JUSTIFICATIF: "Quel type de document d’identité possèdez-vous ?",
        OTHER: "",
      },
      "list-label": {
        IDENTITE: "Document d'identité",
        JUSTIFICATIF: "Justificatif",
        OTHER: "Document supplémentaire",
      },
    },
    "your-progress": {
      title: "Votre avancée",
    },
    quiz: {
      title: "Questionnaire de recrutement (optionnel)",
      description:
        "Le questionnaire de recrutement permet de tester vos connaissances à propos de la garde d’enfants.",
      answer: "Je réponds au questionnaire",
      done: "Vous avez répondu au questionnaire.",
      opened:
        "Vous avez commencé le remplissage du quiz, veuillez rafraichir votre page.",
    },
    engagement: {
      title: "Charte d'engagement",
      description:
        "La charte d’engagement liste les éléments à respecter lorsque vous travaillez dans une famille, vous devez absolument la lire. ",
      read: "Je lis et valide la charte",
      "see-engagement": "Voir la charte",
      "modal-title": "Charte d'engagement",
      check: "J'ai bien lu la charte d'engagement",
      validate: "Je valide",
      "has-read": "Vous avez lu la charte",
      text: {
        intro: "En devenant nounou Mômji, je m’engage à : ",
        1: "1) <bold>Me présenter à l’entretien</bold> avec la famille si mon profil est sélectionné",
        2: "2) <bold>Être ponctuel(le)</bold> et <bold>toujours prévenir en cas de retard</bold>",
        3: "3) <bold>Ne pas utiliser mon téléphone portable pendant la garde</bold> (sauf en cas d’urgence ou pour scanner le QR code présent chez la famille, me donnant accès aux activités Mômji mensuelles)",
        4: "4) <bold>Ne pas m’absenter</bold> (exceptés maladie ou examens pour lesquels un justificatif devra être fourni) et <bold>déclarer l’intégralité de mes heures travaillées</bold> avec la famille dans le planning en ligne",
        5: "5) <bold>Veiller à la sécurité des enfants</bold> au domicile et lors de déplacements, cela inclut :",

        6: "6) <bold>Respecter la santé et le bien-être des enfants</bold>, cela inclut :",

        7: "7) <bold>Prendre soin du matériel présent au domicile de la famille</bold> et quitter les lieux dans l’état de rangement et de propreté tels qu’ils l’étaient lors de mon arrivée",
        8: "<p>8) <bold>Mettre en pratique les activités fournies en scannant chaque mois le magnet QR code</bold> reçu chez la famille ou en consultant mes e-mails. <bold>En plus des activités Mômji, je dois</bold> (selon la prestation de garde que j’effectue) :</p><p><bold>En garde Créative : Utiliser le kit Pandacraft</bold>, reçu par la famille chaque mois</p><p><bold>En garde en Initiation : Utiliser le kit d’activités bilingue Mômji</bold> et <bold>faire 30 minutes d’activités en anglais par session de garde</bold></p><p><bold>En garde en Immersion : Utiliser le kit d’activités bilingue Mômji</bold> et <bold>parler uniquement la langue étrangère</bold> choisie par les parents</p>",
        9: "9) <bold>Être ambassadeur de la marque Mômji, en respectant ses valeurs</bold>: bienveillance, respect, épanouissement, inclusion, ouverture aux autres et aux autres cultures.",
        lists: {
          1: "ne pas laisser les enfants sans surveillance",
          2: "ne pas confier les enfants à une tierce personne",
          3: "ne pas introduire une tierce personne au domicile de la famille (sans accord direct du ou des parents)",
          4: "ne pas fumer devant les enfants",
          5: "ne pas utiliser de langage grossier et /ou vulgaire",
          6: "prendre les mesures nécessaires en cas d’accident de l’enfant (appeler le <bold>15 pour le SAMU</bold>, le <bold>18 pour les pompiers</bold> ou le <bold>112 pour toutes les urgences</bold>) et prévenir immédiatement le ou les parents",
        },
        confirm:
          "Je confirme avoir pris connaissance de cette charte et m’engage à la respecter.",
      },
    },
  },

  travelTime: {
    startingPointQuestion: "Adresse",
    enterStartingPoint:
      "Saisis l'adresse d'où vous partez pour calculer votre temps de trajet approximatif",
    duration: "Durée",
    distance: "Distance",
    seeDirection: "Voir le trajet",
  },

  "week-days": {
    title: "Jour(s) de la semaine",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
  },

  "daytime-periods": {
    "all-day": "Toute la journée",
    1: "Oh - 12h",
    2: "12h - 16h",
    3: "16h - 19h",
    4: "19h - 24h",
  },
  "daytime-start-periods": {
    1: "Oh",
    2: "12h",
    3: "16h",
    4: "19h",
  },

  entities: {
    department: {
      list: "Départements",
      edit: "Editer le département",
      add: "Ajouter un département",
    },
    api: {
      list: "APIs",
      edit: "Editer l'API",
      add: "Ajouter une API",
    },
    "role-type": {
      list: "Types de rôle",
      edit: "Editer le type de rôle",
      add: "Ajouter un type de rôle",
    },
    action: {
      list: "Actions",
      edit: "Editer l'action",
      add: "Ajouter une action",
    },
    route: {
      list: "Routes",
      edit: "Editer la route",
      add: "Ajouter une route",
    },
    role: {
      list: "Rôles",
      edit: "Editer le rôle",
      add: "Ajouter un rôle",
      routes: {
        title: "Routes associées",
        subtitle: "Sélectionnez les routes que vous souhaitez associer au rôle",
      },
    },
    user: {
      list: "Utilisateurs",
      edit: "Editer l'utilisateur",
      add: "Ajouter un utilisateur",
    },
  },

  admin: {
    nav: "Administration FR",
  },

  dashboard: {
    welcome: "Bienvenue chez Momji !",
    "your-todo": "Mes tâches",
    "generic-message": "",
    SEARCHJOB: {
      title: "Nos offres d'emploi",
      description:
        "Consultez nos offres en immersion, en initiation et créatives et servez vous des filtres pour ajuster votre recherche.",
      action: "Je découvre les offres",
    },
    MYJOBS: {
      title: "Mes offres",
      description:
        "Suivez vos candidatures, vos favoris et les suggestions que Mômji vous fait.",
      action: "Je suis l’avancée de mes offres",
    },
    PROFIL: {
      title: "Mon profil",
      description:
        "Renseignez vos informations d'identité ainsi que vos expériences",
      action: "Je renseigne mes informations",
    },
    CONTACT: {
      title: "Contacter Momji",
      description: "",
      action: "Je prend contact avec Momji",
    },
    PARCOURS: {
      title: "Mon parcours",
      description:
        "Prenez connaissances de votre avancé dans notre processus pour connaitre les étapes suivantes",
      action: "Je consulte mon parcours",
    },
    PLANNING: {
      title: "Mon planning",
      description:
        "Consultez votre planning pour obtenir des informations sur vos prestations à venir",
      action: "Je consulte mon planning",
    },
    CONTRACTS: {
      title: "Mes contrats",
      description:
        "Consultez vos contrats passés, en cours ou en processus de signature",
      action: "Je consulte mes contrats",
    },
    BULLETINS: {
      title: "Mes fiches de paie",
      description: "Consultez et téléchargez vos fiches de paie",
      action: "je consulte mes fiches de paie",
    },
  },

  // Navigation (cf: dashboardItems.js)
  "permissions-api": {
    nav: "Permissions",
    departments: "Départements",
    apis: "APIs",
    roleTypes: "Types de rôle",
    actions: "Actions",
    routes: "Routes",
    roles: "Rôles",
    users: "Utilisateurs",
  },
  users: {
    nav: "Utilisateurs",
    profile: "Profil",
  },

  validators: {
    wrongFormat: "Mauvais format",
  },

  contract: {
    "no-result": "Aucun contrat",
    "no-result-description":
      "Dès qu'une famille et vous aurez décidé de travailler ensemble, votre contrat apparaîtra ici",
    "begins-on": "Commence le",
    "ends-on": "Prend fin le",
    "linked-to-offer": "Lié à l’offre n°",
    "offer-num": "Offre n°",
    status: {
      title: "Statut",
      over: "Terminé",
      in_sign: "A signer",
      active: "En cours",
      to_sign: "A signer",
    },
    section: {
      contracts: "Contrats",
      due: "DUE",
      healthcare: "Mutuelle",
    },
    "family-info": "Coordonnées de la famille",
    email: "Email:",
    phone: "Téléphone:",
    address: "Adresse:",
    action: {
      file: "Voir mon contrat",
      sign: "Je signe mon contrat",
    },
    healthcare: {
      "momji-obligation":
        "Depuis le 1er janvier 2016, toutes les sociétés françaises ont pour obligation de mettre en place un régime collectif complémentaire de santé pour leurs salariés.",
      "your-obligation":
        "Vous devez suivre cette procédure que vous souhaitiez adhérer ou non à la mutuelle.",
      title: "Mutuelle pour",
      refused: "Vous avez décidé de ne pas adhérer à la mutuelle.",
      accepted: "Vous avez décidé d’adhérer à la mutuelle.",
      link: "Voir le tableau des garanties de la mutuelle",
      fee: "Si vous souscrivez à la mutuelle, vous serez prélevé tous les mois de 50% x 20,23€ quel que soit le nombre d’heures que vous aurez travaillé.",
      resiliation:
        "En cas d’adhésion, vous ne pourrez pas annuler votre inscription pendant 1 an ou pendant la durée de votre contrat s’il est inférieur à 1 an.",
      inflation:
        "Pour information, le coût mensuel de la cotisation peut augmenter chaque année. Mômji ne peut influer sur cette évolution tarifaire.",
      information:
        "Rappel : pour souscrire à la mutuelle, vous devez au préalable être inscrit auprès de la Caisse Primaire d’Assurance Maladie (CPAM) et avoir un numéro de sécurité sociale français. Pour obtenir ce numéro, vous devez déposer un dossier complet à votre CPAM, le délai d’obtention est compris entre 4 et 8 mois à partir du dépôt du dossier.",
      "yes-choice": "Je souhaite adhérer à la mutuelle",
      "no-choice": "Je ne souhaite pas adhérer à la mutuelle",
      validate: "Valider mon choix",
      "you-accepted":
        "Vous avez choisi d’adhérer à la mutuelle. Pour finaliser votre adhésion, téléchargez et complétez :",
      "form-link": "Le bulletin d'adhésion",
      "send-us":
        "Ensuite, evoyez-nous le bulletin d’adhésion accompagné de votre attestation de droits à la sécurité sociale et de votre RIB français, à l’adresse",
      "when-complete":
        "Quand votre dossier sera finalisé, vous recevrez de la part de la mutuelle :",
      card: "• votre carte de mutuelle",
      "web-access": "• vos accès à https://secure.aggema.com",
      "will-end":
        "Votre affiliation à la mutuelle se terminera dès la fin de votre contrat avec la famille.",
      "more-info":
        "Pour plus d’informations, vous pouvez contacter directement la mutuelle à l’adresse gestionsante@aggema.fr ou au 01 41 90 98 58.",
      "action-button": {
        choose: "Faire mon choix",
        sign: "Signer ma déclaration",
        complete: "Compléter mon dossier",
      },
      "see-document": "Voir le document",
      "waranties-link": "Voir le tableau des garanties de la mutuelle",
      "accept-title": "Adhésion",
      "refuse-title": "Refus d'adhésion",
      "complete-title": "Compléter mon dossier",
    },
    due: {
      description:
        "La déclaration unique d’embauche est un document fourni par Mômji lorsque vous êtes embauché en tant que salarié. Il permet de remplir en une seule fois plusieurs obligations déclaratives liées à l'embauche.",
      employer: "Employeur",
      "begins-on": "Commence le",
      "see-action": "Voir ma DUE",
    },
    "date-change": {
      action: "Changer ma date de départ",
      title: "Changer ma date de départ",
      reason: "Pour quelle raison voulez-vous changer votre date de départ ?",
      "available-until": "Je suis finalement disponible jusqu’au : ",
      validate: "Valider le changement",
    },
  },

  payslips: {
    payslips: {
      description:
        "Pour recevoir votre paie, vous devez posséder un compte bancaire européen.",
      "see-rib": "Voir mon relevé d’identité bancaire",
      "enter-rib": "Renseigner mon relevé d’identité bancaire",
    },
    rib: {
      title: "Relevé d'identité bancaire",
      description:
        "Pour recevoir votre paie, vous devez posséder un compte bancaire européen.",
      "no-file": "Fichier manquant",
      "file-uploaded": "Un fichier a déjà été fourni",
      "your-upload": "Votre fichier",
      name: "Nom et prénom",
      domiciliation: "Domiciliation de la banque",
      bank: "Banque",
      iban: "Iban",
      bic: "BIC/SWIFT",
      edit: "Modifier",
      cancel: "Annuler",
      save: "Enregister",
    },
  },

  mandatory: {
    disponibility: {
      title: "Disponibilités",
      description:
        "La dernière fois que nous avons vérifié, vous étiez disponible jusqu'au",
      "description-no-date":
        "La dernière fois que nous avons vérifié, vous étiez disponible pour une période indéfinie",
      question: "Est-ce toujours le cas ?",
      yes: "Oui",
      no: "Non, je suis disponible jusqu'au",
      "no-date": "Non, je ne sais pas jusqu'à quand je suis disponible",
      validate: "Valider",
    },
    "contract-disponibility": {
      title: "Mes Contrats",
      description:
        "Veuillez trouver ci-dessous la liste de vos emplois actuels. Si la date de fin d'un emploi a changé, vous devez nous en informer au plus vite. ",
      "start-on": "Commence le",
      "end-on": "Prend fin le",
      "my-end-date": "Ma date de départ prévue : ",
      "choice-saved": "Votre réponse a été prise en compte.",
      "change-date": "Changer ma date de départ",
      "confirm-date": "Conserver ma date de départ actuelle",
    },
  },

  planning: {
    locked: "Le planning est vérouillé entre le 2 et le 9 de chaque mois",
    actions: {
      button: "Actions",
      coupon: "Saisir un coupon",
      intervention: "Saisir une prestation",
    },
    coupon: {
      title: "Saisir un coupon",
      date: "Date de la prestation",
      time: "Heure de début de la prestation",
      hour: "Heure",
      minute: "Minute",
      code: "Code (8 caractères)",
      submit: "Valider ce coupon",
    },
    intervention: {
      title: "Saisir une prestation",
      date: "Date de la prestation",
      "start-time": "Heure de début de la prestation",
      "end-time": "Heure de fin de la prestation",
      hour: "Heure",
      minute: "Minute",
      family: "Famille",
      validate: "Valider ces informations",
      submit: "Valider cette prestation",
      "no-contract":
        "Vous n'avez aucun contrat qui couvre cette date de prestation.",
      "one-contract": "Votre prestation sera associée à ce contrat.",
      "several-contract":
        "Nous avons trouvé plusieurs contrats qui couvrent cette date de prestation, veuillez sélectionner celui qui est concerné.",
    },
    detail: {
      type: "Type :",
      title: "Détail d'une prestation",
      date: "Date de l'intervention :",
      start: "Heure de début :",
      end: "Heure de fin :",
      status: {
        head: "Statut :",
        future: "A réaliser",
        done: "Réalisée",
        deleted: "Supprimée",
        cancelled: "Annulée",
      },
      prestation: {
        ACT_GP: "Garde créative",
        ACT_GPAR: "Garde créative partagée",
        ACT_GV: "Garde créative",
        AG: "Cours de langue",
        AI: "Cours de langue",
        AP: "Cours de langue",
        C: "Cours de langue",
        CG: "Cours de langue",
        CI: "Cours de langue",
        EPL: "Cours de langue",
        G: "Garde",
        Immersion_garde_part: "Garde partagée en immersion",
        Immersion_garde_péri: "Garde en immersion",
        Immersion_garde_vaca: "Garde en immersion",
        Initiation_garde_par: "Garde partagée en initiation",
        Initiation_garde_pér: "Garde en initiation",
        Initiation_garde_vac: "Garde en initiation",
        KG: "Cours de langue",
        KMI: "Cours de langue",
        KPI: "Cours de langue",
        M: "Ménage Repassage",
        ME: "Ménage ",
        Mission: "Mission ",
        MR: "Ménage / Repassage",
        Placement: "Placement ",
        RE: "Repassage ",
        Service: "Service ",
        SHK_GP: "Garde en immersion",
        SHK_GPAR: "Garde partagée en immersion",
        T: "Talent ",
        TCI: "Cours de langue",
        TG: "Cours de langue",
        TLI: "Cours de langue",
      },
    },
  },

  "refer-a-friend": {
    description: {
      1: {
        bold: "Aidez vos amis à trouver un job avec Mômji",
        normal: " et",
      },
      2: {
        bold: " gagnez 100€",
        normal: " en carte cadeau Amazon !",
      },
      3: {
        normal:
          "La cagnotte se débloquera dès que votre filleul aura réalisé 30 heures de garde avec une famille !",
      },
    },
    share:
      "Partagez votre lien de parrainage et invitez vos amis à candidater en cliquant dessus :",
    "copy-link": "Je copie mon lien",
  },
  messages: {
    PREP_ENT: {
      title: "Préparez votre entretien Mômji",
      text: "Vous avez un entretien de prévu le {{ date }}. Pour multiplier vos chances d’être sélectionné, regardez cette vidéo :",
      validate: "J’ai vu la vidéo",
      link: "",
    },
    PREP_MEET: {
      title: "Préparez votre (première) rencontre avec la famille",
      text: "Vous allez prochainement rencontrer une famille. Pour multiplier vos chances d’être sélectionné, regardez cette vidéo :",
      validate: "J’ai vu la vidéo",
      link: "",
    },
  },
  resources: {
    activities: {
      title: "Mes activités du mois",
      text: {
        1: "Mômji vous accompagne au quotidien grâce à sa pédagogie !",
        2: "<bold>Chaque mois, faîtes découvrir un nouveau pays aux enfants</bold> que vous gardez, <bold>grâce à 4 activités Mômji.</bold>",
        3: "Pour les découvrir, <bold>rendez-vous sur cette page</bold> à tout moment ou <bold>scannez le magnet QR code</bold> que la famille a reçu à son domicile !",
      },
      button: "Je retrouve les activités",
    },
    "good-deals": {
      title: "Mes bons plans",
      text: {
        1: "En tant que nounou Mômji, <bold>vous bénéficiez de bons plans et promotions</bold> que nous avons négociés auprès de nombreux partenaires !",
        2: "Retrouvez tout au long de l’année, <bold>des codes promos et astuces pour vous loger, passer votre permis de conduire, pratiquer une activité sportive, vous divertir</bold> et plus encore...",
      },
      button: "Je découvre",
    },
    training: {
      main: {
        title: "Ma formation Mômji",
        text: {
          1: "En tant que nounou, <bold>votre mission est de mettre en place la pédagogie Mômji au quotidien</bold>, en fonction de la prestation que vous assurez.",
          2: "<bold>Les vidéos et supports suivants font partie de votre parcours de formation</bold>, pour permettre aux enfants de découvrir une langue, une culture, une passion et partager ensemble des moments enrichissants !",
          3: "Prenez le temps de les regarder dès le démarrage de votre contrat !",
        },
      },
      creative: {
        title: "Si vous êtes en garde Créative",
        text: "En tant que nounou Mômji Créative votre mission est d’accompagner le développement des enfants grâce à la pédagogie et aux activités Mômji.",
        section: {
          title: "La formation en garde Créative",
          "good-start": "Comment bien démarrer mes prestations ?",
          safety: "J'apprends tout sur la sécurité des enfants",
          authority:
            "Comment trouver la bonne autorité vis-à-vis des enfants ?",
          activities:
            "Des idées d'activités que je peux faire avec les enfants",
          videos: {
            creative: "Qu’est-ce que la garde Mômji Créative ?",
            activities: "Les activités",
            pedagogy: "La pédagogie Mômji",
          },
        },
      },
      initiation: {
        title: "Si vous êtes en garde Initiation",
        text: "En tant que nounou Mômji Bilingue en Initiation, votre mission est d’assurer un temps d’initiation à l’anglais pendant minimum 30 minutes à chaque garde.",
        section: {
          title: "La formation en garde Bilingue en Initiation",
          "good-start": "Comment bien démarrer mes prestations ?",
          safety: "J'apprends tout sur la sécurité des enfants",
          authority:
            "Comment trouver la bonne autorité vis-à-vis des enfants ?",
          activities:
            "Des idées d'activités que je peux faire avec les enfants",
          teach: "Comment transmettre une nouvelle langue aux enfants ?",
          videos: {
            pedagogy: "La pédagogie Mômji Bilingue",
            behaviour: "L'attitude en Initiation",
            vocabulary: "Le nouveau vocabulaire",
            organization: "L’organisation",
            adaptability: "L’adaptabilité",
          },
        },
      },
      immersion: {
        title: "Si vous êtes en garde Immersion",
        text: "En tant que nounou Mômji Bilingue en Immersion, votre mission est d’assurer la garde 100% dans la langue choisie par les parents.",
        section: {
          title: "La formation en garde Bilingue en Immersion",
          "good-start": "Comment bien démarrer mes prestations ?",
          safety: "J'apprends tout sur la sécurité des enfants",
          authority:
            "Comment trouver la bonne autorité vis-à-vis des enfants ?",
          activities:
            "Des idées d'activités que je peux faire avec les enfants",
          teach: "Comment transmettre une nouvelle langue aux enfants ?",
          videos: {
            pedagogy: "La pédagogie Mômji Bilingue",
            approach: "Une approche implicite",
            resort: "Le recours au français",
            reassure: "Rassurer l’enfant",
            rituals: "Instaurer des rituels",
          },
        },
      },
      button: "Je découvre",
      videos: "Vidéos",
      documents: "PDF",
      download: "Je télécharge",
    },
  },
};

export { translations };

import { ALLOWED_EXTERNAL_ENV } from "../constants";

// ============================================================
// Functions

/**
 * Returns Default User Language
 * @returns {String}
 */
function getDefaultUserLanguage() {
  return "fr-FR";
}

/**
 * Returns true if App launched in Sam
 * ex: /candidate/13233954/survey?env=sam&user=65549556
 * @returns {Boolean}
 */
function isAppLaunchedFromSamEnv() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const env = urlParams.get("env");

  if (!ALLOWED_EXTERNAL_ENV.includes(env)) {
    return false;
  }
  return true;
}

/**
 * Get User infos from SAM URL
 * ex: /candidate/13233954/survey?env=sam&user=65549556&firstName=Titi&lastName=Tutu&login=titi.tutu@momji.fr
 * &session_id=df9f5p5247m3gtg22dae27mj38
 * @returns {Object}
 */
function getUserInfoFromURLParams() {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  if (!isAppLaunchedFromSamEnv()) {
    // throw new Error(`env ${env} not allowed !`);
    return undefined;
  }

  const result = {
    id: Number(urlParams.get("user")),
    firstName: urlParams.get("firstName"),
    lastName: urlParams.get("lastName"),
    login: urlParams.get("login"),
    session_id: urlParams.get("session_id"),
    language: urlParams.get("language")
      ? urlParams.get("language")
      : getDefaultUserLanguage(),
  };
  console.log("[DEBUG] getUserInfoFromURLParams", result);

  return result;
}

/**
 * Get Auto Connection Infos from SAM URL
 * ex:
 *  /auth/auto-sign-in?key1=84ZV1T086Q8K6KO980MAGK932TF410H3CP7G0M36&key2=7XWB097XCN3LYAW6HFS5ORKN6WY147N204C8A908&view=documents
 *  /auth/auto-sign-in?/key1=84ZV1T086Q8K6KO980MAGK932TF410H3CP7G0M36&key2=7XWB097XCN3LYAW6HFS5ORKN6WY147N204C8A908&view=documents
 * @returns {Object}
 */
function getAutoConnectionInfosFromURLParams() {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const key1 = urlParams.get("key1");

  // NB : htaccess redirection from v1 to v2 add a "/" to key1 name
  const key1AfterRedirection = urlParams.get("/key1");

  const result = {
    key1: key1AfterRedirection ? key1AfterRedirection : key1,
    key2: urlParams.get("key2"),
    // view: urlParams.get("view"),
    module: urlParams.get("module"),
    request_id: urlParams.get("request_id"),
  };
  console.log("[DEBUG] getAutoConnectionInfosFromURLParams", result);

  return result;
}

/**
 * Map GET 'module' to an app route
 * ex: module=search > /job-offers
 * @param {String} module
 * @param {Number} request_id
 * @returns {String}
 */
function mapModuleToAppRoute(module, request_id = null) {
  let route = null;

  switch (module) {
    case "profile_settings":
      route = "/profile";
      break;

    case "search":
      route = "/job-offers";

      if (request_id) {
        return `${route}/${Number(request_id)}`;
      }
      break;
    default:
      return null;
  }
  return route;
}

/**
 * Get token from SAM URL
 * ex: /auth/reset-password?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvdG9reW8uc3
 * @returns {Object}
 */
function getTokenFromURLParams() {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const result = {
    token: urlParams.get("token"),
  };
  console.log("[DEBUG] getTokenFromURLParams", result);

  return result;
}

/**
 * Returns Sam Auth Headers
 * @param {Object} user (NB: from standalone app)
 * @returns {Object}
 */
function getSamAuthHeaders(user = null) {
  const currentUser = user || getUserInfoFromURLParams();
  // console.log('[DEBUG] (getSamAuthHeaders)', currentUser);
  if (!currentUser) {
    return null;
  }
  if (!currentUser.session_id) {
    throw new Error("session_id not found in currentUser !");
  }
  if (!currentUser.id) {
    throw new Error("id not found in currentUser !");
  }
  return {
    "session-id": currentUser.session_id,
    "sam-user-id": currentUser.id,
    language: currentUser.language
      ? currentUser.language
      : getDefaultUserLanguage(),
  };
}

/**
 * Get LocaStorage App Namespace
 * NB: avoid data side effects if multiple apps opened in one same browser !
 * @returns {String}
 */
function getLocaStorageAppNamespace() {
  let namespace = "";

  const url = new URL(document.location.href);
  namespace = url.host; // ex: 0.0.0.0:8002

  if (isAppLaunchedFromSamEnv()) {
    namespace += ".sam";
  }

  return namespace;
}

/**
 * Returns normalized Errors
 * @param {Object} error
 * @returns {Object}
 */
function getApiNormalizedErrors(error) {
  return {
    statusCode: error.response.data.error?.statusCode,
    message: `${error.response.data.error?.statusCode} : ${error.response.data.error?.message}`,
  };
}

// ============================================================
// Exports
export {
  isAppLaunchedFromSamEnv,
  getSamAuthHeaders,
  getUserInfoFromURLParams,
  getAutoConnectionInfosFromURLParams,
  getTokenFromURLParams,
  getLocaStorageAppNamespace,
  getApiNormalizedErrors,
  mapModuleToAppRoute,
};

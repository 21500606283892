import * as React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

// import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
// import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
// import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
// import SearchInput from "./SearchInput";

import { isMobileDevice } from "../../helpers/common.helpers";

import { appConfig } from "../../config";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    color: ${(props) => props.theme.header.color};
  }
`;

const showBrandName = () => {
  if (isMobileDevice()) {
    return <div className="brand-navbar">{appConfig.brand}</div>;
  }
  return null;
};

const Navbar = ({ onDrawerToggle }) => {
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large"
                  id={appConfig.mobileDrawerToggleEltId}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              {showBrandName()}
            </Grid>
            {/* <Grid item>{ <SearchInput /> }</Grid> */}

            <Grid item>
              {/* <NavbarMessagesDropdown /> */}
              {/* <NavbarNotificationsDropdown /> */}
              {/*<NavbarLanguagesDropdown />*/}
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);

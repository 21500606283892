import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import {
  getAutoConnectionInfosFromURLParams,
  mapModuleToAppRoute,
} from "../../utils/sam.api";

import {
  Alert as MuiAlert,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import { spacing } from "@mui/system";

import { ReactComponent as Logo } from "../../vendor/momji_logo_837x551.svg";

import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useSearchJob from "../../hooks/useSearchJob";

import { getStoredUser } from "../../helpers/auth.helpers";

const Alert = styled(MuiAlert)(spacing);

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 180px;
  height: 120px;
  margin-bottom: 20px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function AutoSignIn() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { autoSignIn, setAppUser, isAuthenticated } = useAuth();
  const { getStoredRepositories, setAppRepositories, isRepositoriesLoading } =
    useSearchJob();

  /**
   * set Repositories
   * @param {Object} user
   * @returns {void}
   */
  const setRepositories = useCallback(
    async (user) => {
      try {
        await setAppRepositories(user);
      } catch (error) {
        console.error("[AutoSignIn.setRepositories]", error);
      }
    },
    [setAppRepositories]
  );

  useEffect(() => {
    console.log("[AutoSignIn] useEffect - isAuthenticated:", isAuthenticated);

    // authenticate user
    const handleAuthenticate = async () => {
      try {
        const { key1, key2, module, request_id } =
          getAutoConnectionInfosFromURLParams();

        await autoSignIn(key1, key2, true);

        const user = getStoredUser(); // NB: state.user returns null ...
        // console.log("[AutoSignIn.handleAuthenticate] getStoredUser: ", user);
        const repositories = getStoredRepositories(); // NB: state.repositories returns null ...
        console.log(
          "[AutoSignIn.handleAuthenticate] repositories: ",
          repositories
        );

        if (user) {
          await setAppUser();

          if (module) {
            const route = mapModuleToAppRoute(module, request_id);

            // all repositories have to be fetch first
            if (!repositories) {
              await setRepositories(user);
            }
            navigate(`/${route}`);
            return;
          }
          navigate(`/`);
        }
      } catch (error) {
        console.error("[AutoSignIn.handleAuthenticate]", error);
        const errorMessage = error?.message || t("auth.errors.global");
        setError(errorMessage);
      }
    };
    handleAuthenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Brand />
      <Wrapper>
        <Helmet title={t("auth.welcome")} />

        {error && (
          <Alert mt={2} mb={3} severity="warning">
            {error}
          </Alert>
        )}
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("auth.auto-connect")}
        </Typography>

        {isRepositoriesLoading && (
          <Box mt="2em" display="flex" justifyContent="center">
            <CircularProgress />
            <Typography pt="0.9em" ml="1em">
              {t("loader.repositories-loading")}
            </Typography>
          </Box>
        )}
      </Wrapper>
    </React.Fragment>
  );
}

export default AutoSignIn;

import React from "react";
import * as Sentry from "@sentry/react";

const SentryErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={
      <div>
        <h2>Hum, hum ... Something went wrong ...</h2>
      </div>
    }
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default SentryErrorBoundary;

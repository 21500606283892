const parseDate = (string, locale) => {
  const year = string.substr(0, 4);
  const month = string.substr(4, 2);
  const day = string.substr(6);

  const date = new Date(year, month - 1, day);
  return locale ? date.toLocaleDateString() : date.toDateString();
};

const dateToString = (date) => {
  const year = date.getFullYear();
  const month =
    date.getMonth().toString().length > 1
      ? date.getMonth() + 1
      : `0${parseInt(date.getMonth()) + 1}`;
  const day =
    date.getDate().toString().length > 1
      ? date.getDate()
      : `0${date.getDate()}`;

  return `${day}/${month}/${year}`;
};

export { parseDate, dateToString };

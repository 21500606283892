import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { spacing } from "@mui/system";

import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { getStoredUser } from "../../helpers/auth.helpers";
import {
  getUILanguage,
  detailedLanguage,
} from "../../helpers/translations.helpers";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { user, signIn, setAppUser, isAuthenticated } = useAuth();
  const { t, i18n } = useTranslation();

  // Is user still authenticated ?
  useEffect(() => {
    console.log("[SignIn] useEffect - isAuthenticated:", isAuthenticated);

    const handleAuthRedirect = () => {
      // user authenticated > redirect to home page

      // const user = getStoredUser();
      // console.log("[SignIn] useEffect - user:", user);

      if (user && isAuthenticated && user.lastLoadedOn) {
        console.log("[SignIn] useEffect - authenticated > redirecting to: /");

        // we set user UI default language
        const language = getUILanguage(user.language);
        i18n.changeLanguage(language);

        // we redirect
        navigate(`/`);
      }
    };
    handleAuthRedirect();
  }, [i18n, isAuthenticated, navigate, user]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        rememberMe: true,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("auth.validation.valid-email"))
          .max(255)
          .required(t("auth.validation.required-email")),
        password: Yup.string()
          .max(255)
          .required(t("auth.validation.required-password")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // authenticate user
          await signIn(
            values.email,
            values.password,
            values.rememberMe,
            detailedLanguage[i18n.language]
          );

          // We retrieve stored user to complete infos
          const user = getStoredUser(); // TODO : use state.user ...
          // console.log("[SignIn] OK - onSubmit - getStoredUser:", user);

          if (user) {
            // console.log("[SignIn] onSubmit - setAppUser", user);
            await setAppUser();

            // navigate("/"); // NB: allready handled by useEffect hook !
          }
        } catch (error) {
          console.error("[SignIn]", error);
          const errorMessage = error?.message || t("auth.errors.global");

          setStatus({ success: false });
          setErrors({ submit: errorMessage });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label={t("auth.login")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label={t("auth.password")}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="rememberMe"
                value={values.rememberMe}
                defaultChecked
                onChange={() => setFieldValue("rememberMe", !values.rememberMe)}
                color="primary"
              />
            }
            label={t("auth.remember-me")}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
            loading={isSubmitting}
            className="auth-button"
          >
            {t("auth.submit")}
          </LoadingButton>
          <Button
            component={Link}
            to={`/auth/forgot-password`}
            fullWidth
            color="primary"
          >
            {t("auth.forgot-password")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;

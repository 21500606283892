import { LOCALSTORAGE_ITEM_KEYS } from "../constants.js";

const LOGGER_DEBUG = false;
const LOGGER_PREFIX = "[isActionAuthorized]";

/**
 * Logger
 * @param {Object}
 */
const logger = (...params) => {
  if (LOGGER_DEBUG) {
    // 1. Convert args to a normal array
    const args = Array.prototype.slice.call(params);

    // 2. Prepend log prefix log string
    args.unshift(`${LOGGER_PREFIX} `);

    // 3. Pass along arguments to console.log
    console.log.apply(console, args);
  }
};

/**
 * Allowed HTTP_METHODS
 */
const HTTP_METHODS = ["GET", "POST", "PUT", "PATCH", "DELETE"];

/**
 * Returns true if action is permitted for current user
 * @param {String} route
 * @param {String} httpMethod
 * @param {Object} userPermissions
 * @params {Boolean} byPass
 * @returns {Boolean}
 */
const isActionAuthorized = (
  route,
  httpMethod,
  userPermissions,
  byPass = false
) => {
  logger("// start : ", route, httpMethod, userPermissions);

  if (byPass) {
    return true;
  }

  if (!HTTP_METHODS.includes(httpMethod)) {
    console.error(
      `[isActionAuthorized] httpMethod ${httpMethod} is not allowed !`
    );
    return false;
  }

  if (
    !userPermissions ||
    !Object.keys(userPermissions).length ||
    !userPermissions.routes
  ) {
    console.error(`[isActionAuthorized] userPermissions not found !`);
    return false;
  }

  const DYNAMIC_PATH_REGEX = /:id+/;
  const AUTH_USER_ID_REGEX = /:authuserid/;

  if (route[0] === "/") {
    route = route.substring(1, route.length); // NB : remove first "/"
  }

  const [apiCode, entity] = route.split("/");
  logger(`apiCode: ${apiCode}, entity: ${entity}`);

  const requestUrl = route.replace(apiCode, "");
  logger(`requestUrl: ${requestUrl}`);

  const isRouteAuthorized = userPermissions.routes.some((route) => {
    logger(
      `/// testing user route : ${route.action.httpMethod} : ${route.path} [${route.api.code}]`
    );

    // search only in current API
    if (apiCode !== route.api.code) {
      logger(`${route.path} is not present in current API ${apiCode} scope !`);
      return false;
    }

    // dynamic path (ex: /documents/:id)
    if (
      DYNAMIC_PATH_REGEX.test(route.path) ||
      AUTH_USER_ID_REGEX.test(route.path)
    ) {
      const dynamicPathRegex = getDynamicPathRegex(route.path);
      logger(`path: ${route.path} - dynamicPathRegex: ${dynamicPathRegex}`);

      const dynamicPathAuthorized = dynamicPathRegex.test(requestUrl);

      if (!dynamicPathAuthorized) {
        logger(`${requestUrl} not authorized (dynamicPathRegex test)`);
        return false;
      }
    }

    // simple compare path (ex: /documents)
    else {
      if (route.path !== requestUrl) {
        logger(`${requestUrl} not authorized (simple compare path test)`);
        return false;
      }
    }

    const isActionAuthorized =
      route.action.httpMethod.toUpperCase() === httpMethod.toUpperCase();

    // logger(
    //   `${apiCode} | ${httpMethod} : ${requestUrl} | isActionAuthorized: ${isActionAuthorized}`
    // );
    return isActionAuthorized;
  });

  logger(
    `// result : ${apiCode} | ${httpMethod} : ${requestUrl} >>> ${
      isRouteAuthorized ? "OK" : "KO"
    }`
  );

  return isRouteAuthorized;
};

/**
 * Returns Regex to validate path (ex: /documents/:id > ^\/documents\/[\d]+$)
 * @param {string} path
 * @returns RegExp
 */
const getDynamicPathRegex = (path) => {
  const dynamicIdsRegexes = [
    {
      findRegex: /\//,
      replaceRegex: "\\/",
    },
    {
      findRegex: /:id+/,
      replaceRegex: "[\\w-]+", // NB : required to validate uuid ...
      // replaceRegex: "[\\d]+",
    },
    {
      findRegex: /:authuserid/,
      replaceRegex: "[\\w-|]+", // ex: auth0|ba29dbb6-e031-4f9e-bd7d-9e4a727a5365
    },
  ];

  let regex = path.replace(dynamicIdsRegexes[0].findRegex, () => {
    return dynamicIdsRegexes[0].replaceRegex;
  });

  regex = regex.replace(dynamicIdsRegexes[1].findRegex, () => {
    return dynamicIdsRegexes[1].replaceRegex;
  });

  regex = regex.replace(dynamicIdsRegexes[2].findRegex, () => {
    return dynamicIdsRegexes[2].replaceRegex;
  });

  regex = `^${regex}$`;
  // console.log(`[getDynamicPathRegex] path: ${path} - regex: ${regex}`);

  return new RegExp(regex);
};

/**
 * Returns route object from string
 * @param {Array} routes
 * @param {String} delimitor
 * @returns {Array}
 */
const convertPermissionsRoutes = (routes, delimitor = "|") => {
  // console.log("[convertPermissionsRoutes]: ", routes);

  return routes.map((route) => {
    const [path, httpMethod, code] = route.split(delimitor);

    return {
      path: path,
      action: { httpMethod: httpMethod },
      api: { code: code },
    };
  });
};

/**
 * Returns Authorized DashboardItems
 * @param {Array} dashboardItems
 * @param {String} userPermissions
 * @param {Boolean} byPass
 * @returns {Array}
 */
const getAuthorizedDashboardItems = (
  dashboardItems,
  userPermissions,
  byPass = false
) => {
  console.log(
    "[getAuthorizedDashboardItems]: ",
    dashboardItems,
    userPermissions,
    byPass
  );

  let authorizedDashboardItems;

  // 1st loop : filtering on pages
  authorizedDashboardItems = dashboardItems.map((dashboardItem) => {
    if (dashboardItem.pages) {
      const filteredPages = dashboardItem.pages.filter((page) => {
        return isActionAuthorized(
          `${page.href}/`,
          "GET",
          userPermissions,
          byPass
        );
      });

      return {
        ...dashboardItem,
        pages: filteredPages,
      };
    }
    return dashboardItem;
  });

  // 2nd loop : filtering on children
  authorizedDashboardItems = authorizedDashboardItems.map((dashboardItem) => {
    const pages = dashboardItem.pages.map((page) => {
      if (page.children) {
        const filteredChildren = page.children.filter((child) => {
          return isActionAuthorized(
            `${child.href}`,
            "GET",
            userPermissions,
            byPass
          );
        });

        return {
          ...page,
          children: filteredChildren,
        };
      }
      return page;
    });

    return {
      ...dashboardItem,
      pages,
    };
  });

  return authorizedDashboardItems;
};

/**
 * Remove true if expirationDate has expired
 * @param {Date} expirationDate
 * @returns {Boolean}
 */
const hasExpirationDateExpired = (expirationDate) => {
  if (!expirationDate) {
    return true;
  }
  return new Date(expirationDate).getTime() < Date.now();
};

/**
 * Get Stored User from localStorage and set isAuthenticated property from current session
 * @returns {Object}
 */
const getStoredUser = () => {
  // console.info("[getStoredUser]");
  try {
    let user = window.localStorage.getItem(LOCALSTORAGE_ITEM_KEYS.USER);

    if (user) {
      user = JSON.parse(user);

      // We set isAuthenticated property in case of AuthGuard is called before AuthContext.Provider init
      const expirationDate = user?.expirationDate;
      const isAuthenticated = !hasExpirationDateExpired(expirationDate);
      user.isAuthenticated = isAuthenticated;

      return user;
    }

    return null;
  } catch (error) {
    console.error("[getStoredUser]", error);
  }
};

export {
  isActionAuthorized,
  convertPermissionsRoutes,
  getAuthorizedDashboardItems,
  hasExpirationDateExpired,
  getStoredUser,
};

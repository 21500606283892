import React from "react";
import styled from "styled-components/macro";

import { IconButton as MuiIconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { spacing } from "@mui/system";
import { isMobileDevice } from "../../../helpers/common.helpers";

const IconButton = styled(MuiIconButton)(spacing);

/**
 * Returns TooltipInfo
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function TooltipInfo(props) {
  const { label } = props;
  const placement = isMobileDevice ? "top-start" : "right-start";
  const enterTouchDelay = isMobileDevice ? 100 : 700;

  return (
    <Tooltip
      title={label}
      placement={placement}
      enterTouchDelay={enterTouchDelay}
      className="tooltip-info"
    >
      <IconButton aria-label={label} mr={2} size="large">
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
}

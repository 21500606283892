export const getHoursList = (min, max) => {
  const result = [];

  for (let i = min; i <= max; i++) result.push(i);
  return result.map((hour) => (hour < 10 ? `0${hour}` : hour.toString()));
};

export const getMinutesList = (intervalle) => {
  const result = [];

  for (let i = 0; i < 60; i += intervalle) result.push(i);
  return result.map((minute) =>
    minute < 10 ? `0${minute}` : minute.toString()
  );
};

export const timeIntervalIsValid = (
  startHour,
  startMinute,
  endHour,
  endMinute,
  min,
  max
) => {
  const start = new Date();
  start.setHours(parseInt(startHour));
  start.setMinutes(parseInt(startMinute));

  const end = new Date();
  end.setHours(parseInt(endHour));
  end.setMinutes(parseInt(endMinute));
  if (startHour > 12 && endHour < startHour) end.setDate(end.getDate() + 1);

  const duration = (end.getTime() - start.getTime()) / 60000;

  if (duration > max || duration < min) return false;

  return true;
};

import React from "react";

import { Box } from "@mui/material";

const YoutubeEmbed = (props) => {
  const { embedId } = props;

  return (
    <Box
      sx={{
        height: "100%",
        aspectRatio: {
          xs: null,
          sm: "16/9",
        },
        margin: "auto",
        maxWidth: {
          xs: "75vw",
          md: "580px",
        },
        maxHeight: {
          xs: "90vh",
        },
      }}
    >
      <iframe
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
};

export default YoutubeEmbed;

import axios from "axios";
import { apiConfig } from "../../config.js";
import { logError } from "../connector.helpers.js";

class RepositoryConnector {
  constructor(config) {
    this.config = config;
    this.url = apiConfig.samApi.url;

    this.entity = "repositories";

    console.log(`[RepositoryConnector] config:`, this.config);
  }

  /**
   * Get config
   * @returns {Object}
   */
  getConfig = () => {
    return this.config;
  };

  /**
   * Get all repositaries used by SearchJob filters
   * @returns {Object}
   */
  getAll = async () => {
    // types
    const types = this.getJobOfferTypes();

    //childcare
    const childcares = {
      contexts: {},
      responsibilities: {},
    };

    const childcares_fr = await this.getChildcare("fr-FR");
    const childcares_en = await this.getChildcare("en-GB");

    for (const context of childcares_fr.childcareContexts) {
      const code = context.code;
      const label = context.label;

      childcares.contexts[code] = {
        fr: label,
        en: "",
      };
    }

    for (const context of childcares_en.childcareContexts) {
      const code = context.code;
      const label = context.label;

      childcares.contexts[code]["en"] = label;
    }

    for (const responsibility of childcares_fr.childcareResponsibilities) {
      const code = responsibility.code;
      const label = responsibility.label;

      childcares.responsibilities[code] = {
        fr: label,
        en: "",
      };
    }

    for (const responsibility of childcares_en.childcareResponsibilities) {
      const code = responsibility.code;
      const label = responsibility.label;

      childcares.responsibilities[code]["en"] = label;
    }

    // languages
    const languages = {};
    const languages_fr = await this.getLanguages("fr-FR");
    const languages_en = await this.getLanguages("en-GB");

    for (const language of languages_fr) {
      const code = language.code;
      const label = language.label;

      languages[code] = {
        fr: label,
        en: "",
      };
    }

    for (const language of languages_en) {
      const code = language.code;
      const label = language.label;

      languages[code]["en"] = label;
    }

    // language Levels
    const languageLevels = {};

    const levels_fr = languages_fr[0].levels;
    const levels_en = languages_en[0].levels;

    for (const level of levels_fr) {
      const code = level.code;
      const label = level.label;

      languageLevels[code] = {
        fr: label,
        en: "",
      };
    }

    for (const level of levels_en) {
      const code = level.code;
      const label = level.label;

      languageLevels[code]["en"] = label;
    }

    // services
    const services = {};
    const services_fr = await this.getServices("fr-FR");
    const services_en = await this.getServices("en-GB");

    for (const service of services_fr) {
      const code = service.code;
      const label = service.label;

      services[code] = {
        fr: label,
        en: "",
      };
    }

    for (const service of services_en) {
      const code = service.code;
      const label = service.label;

      services[code]["en"] = label;
    }

    // availabilities days
    const availabilities = this.getAvailabilities();

    return {
      types: types,
      languages: languages,
      availabilities: availabilities,
      languageLevels: languageLevels,
      services: services,
      childcares: childcares,
    };
  };

  /**
   * Get jobOffer types
   * @returns {Object}
   */
  getJobOfferTypes = () => {
    return {
      G: {
        fr: "Garde",
        en: "ChildCare",
      },
      C: {
        fr: "Cours",
        en: "Teaching",
      },
    };
  };

  /**
   * Get availabilities
   * @returns {Object}
   */
  getAvailabilities = () => {
    return {
      MONDAY: {
        fr: "Lundi",
        en: "Monday",
      },
      TUESDAY: {
        fr: "Mardi",
        en: "Tuesday",
      },
      WEDNESDAY: {
        fr: "Mercredi",
        en: "Wednesday",
      },
      THURSDAY: {
        fr: "Jeudi",
        en: "Thursday",
      },
      FRIDAY: {
        fr: "Vendredi",
        en: "Friday",
      },
      SATURDAY: {
        fr: "Samedi",
        en: "Saturday",
      },
      SUNDAY: {
        fr: "Dimanche",
        en: "Sunday",
      },
    };
  };

  /**
   * Get languages
   * @param {String} locale
   * @returns Promise
   */
  getLanguages = async (locale = "fr-FR") => {
    const result = await axios
      .get(`${this.url}/${this.entity}/languages?locale=${locale}`, this.config)
      .catch((error) => {
        logError(error, "[RepositoryConnector - getLanguages]");
        throw error;
      });

    return result?.data;
  };

  /**
   * Get services
   * @param {String} locale
   * @returns {Object}
   */
  getServices = async (locale = "fr-FR") => {
    const result = await axios
      .get(`${this.url}/${this.entity}/services?locale=${locale}`, this.config)
      .catch((error) => {
        logError(error, "[RepositoryConnector - getServices]");
        throw error;
      });

    return result?.data;
  };

  /**
   * Get childcare
   * @param {String} locale
   * @returns {Object}
   */
  getChildcare = async (locale = "fr-FR") => {
    const result = await axios
      .get(`${this.url}/${this.entity}/childcare?locale=${locale}`, this.config)
      .catch((error) => {
        logError(error, "[RepositoryConnector - getChildcare]");
        throw error;
      });

    return result?.data;
  };
}

export default RepositoryConnector;

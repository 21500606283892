import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import {
  Box,
  Divider as MuiDivider,
  FormGroup,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material/";
import DatePicker from "@mui/lab/DatePicker";
import { spacing } from "@mui/system";

import { formatDate } from "../../helpers/dates.helpers";

const Divider = styled(MuiDivider)(spacing);

const DisponibilityValidator = (props) => {
  const { offer, conditionValidate, setDates } = props;
  const { t, i18n } = useTranslation();
  const [start_date, setStart_date] = useState(offer?.start_date);
  const [end_date, setEnd_date] = useState(offer?.end_date);
  const [startDateMatch, setStartDateMatch] = useState(null);
  const [endDateMatch, setEndDateMatch] = useState(null);
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState(null);

  const language = i18n.language;

  const checkDateCoherency = useCallback(() => {
    const mission_start = new Date(offer.start_date);
    const mission_end = new Date(offer.end_date);
    let result = true;

    if (
      start_date > mission_end ||
      (start_date > end_date && end_date > mission_start)
    ) {
      if (!errors || !errors.start_date)
        setErrors({ ...errors, start_date: "Start date too late" });
      result = false;
    }
    if (
      end_date < mission_start ||
      (end_date < start_date && start_date < mission_end)
    ) {
      if (!errors || !errors.end_date)
        setErrors({ ...errors, end_date: "End date too early" });
      result = false;
    }
    console.log(
      "[Apply] checkDateCoherency",
      start_date,
      end_date,
      mission_start,
      mission_end,
      result,
      errors
    );

    if (result) {
      setErrors(null);
    }
    return result;
  }, [start_date, end_date, offer, errors]);

  const handleDateChange = (value, date) => {
    if (date === "start") {
      setStart_date(value);
    }
    if (date === "end") {
      setEnd_date(value);
    }

    setErrors(null);
  };

  /**
   * Handle validation checkbox changing
   * @returns {void}
   */
  const handleValidateChange = useCallback(
    (value) => {
      setValidated(value);
      setDates({
        start_date:
          start_date && !startDateMatch
            ? start_date
            : new Date(offer.start_date),
        end_date:
          end_date && !endDateMatch ? end_date : new Date(offer.end_date),
      });
      conditionValidate("dates", value);
    },
    [
      conditionValidate,
      setDates,
      start_date,
      end_date,
      startDateMatch,
      endDateMatch,
      offer,
    ]
  );

  /**
   * Handle start or end date of disponibility changing
   * @param {string} change
   * @param {Date} value
   * @returns {void}
   */
  const handleChangeDisponibility = (change, value) => {
    if (change === "start") {
      setStartDateMatch(value);
      if (!value) setStart_date(null);
      else setStart_date(offer.start_date);
    } else if (change === "end") {
      setEndDateMatch(value);
      if (!value) setEnd_date(null);
      else setEnd_date(offer.end_date);
    }

    setErrors(null);
  };

  useEffect(() => {
    //console.log("[Apply] useEffect");

    if (
      startDateMatch !== null &&
      start_date &&
      endDateMatch !== null &&
      end_date &&
      !validated
    ) {
      if (checkDateCoherency()) handleValidateChange(true);
    }

    if (validated) {
      if ((!startDateMatch && !start_date) || (!endDateMatch && !end_date))
        handleValidateChange(false);
      if (!checkDateCoherency()) handleValidateChange(false);
    }
  }, [
    start_date,
    end_date,
    startDateMatch,
    endDateMatch,
    handleValidateChange,
    checkDateCoherency,
    validated,
  ]);

  return (
    <Box>
      <Typography sx={{ fontWeight: "bold" }} variant="h6">
        {t("job-offer.dates")}
      </Typography>

      <Divider my={6} />
      <FormGroup>
        <Box mt="1em">
          <Grid width="100%" container display="flex">
            <Grid mb="1em" item xs={6}>
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                {t("job-offer.start")}{" "}
              </Typography>
            </Grid>
          </Grid>
          <Typography mt="1em" mb="1em">
            {t("job-offer.validationStep.startWhen")}
            <span style={{ fontWeight: "bold" }}>
              {formatDate(offer?.start_date, language)}
            </span>
          </Typography>
          <RadioGroup row name="row-radio-buttons-start">
            <FormControlLabel
              value="yes"
              label={
                <Typography>{t("job-offer.validationStep.yes")}</Typography>
              }
              control={
                <Radio
                  onChange={() => handleChangeDisponibility("start", true)}
                />
              }
            />
            <FormControlLabel
              value="no"
              label={
                <Typography>{t("job-offer.validationStep.no")}</Typography>
              }
              control={
                <Radio
                  onChange={() => handleChangeDisponibility("start", false)}
                />
              }
            />
          </RadioGroup>
          {startDateMatch === false ? (
            <Box>
              <Typography mb="0.5em">
                {t("job-offer.validationStep.startWhen")} :
              </Typography>
              <DatePicker
                maxWidth="fit-content"
                inputFormat="dd/MM/yyyy"
                value={start_date}
                onChange={(newValue) => handleDateChange(newValue, "start")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors && errors.start_date ? true : null}
                    helperText={
                      errors && errors.start_date ? errors.start_date : ""
                    }
                  />
                )}
              />
            </Box>
          ) : null}

          <Grid mt="2em" width="100%" container display="flex">
            <Grid mb="1em" item xs={6}>
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                {t("job-offer.end")}
              </Typography>
            </Grid>
          </Grid>
          <Typography mt="1em" mb="1em">
            {t("job-offer.validationStep.endWhen")}
            <span style={{ fontWeight: "bold" }}>
              {formatDate(offer?.end_date, language)}
            </span>
          </Typography>
          <RadioGroup row name="row-radio-buttons-end">
            <FormControlLabel
              value="yes"
              label={
                <Typography>{t("job-offer.validationStep.yes")}</Typography>
              }
              control={
                <Radio
                  onChange={() => handleChangeDisponibility("end", true)}
                />
              }
            />
            <FormControlLabel
              value="no"
              label={
                <Typography>{t("job-offer.validationStep.no")}</Typography>
              }
              control={
                <Radio
                  onChange={() => handleChangeDisponibility("end", false)}
                />
              }
            />
          </RadioGroup>
          {endDateMatch === false ? (
            <Box>
              <Typography mb="0.5em">
                {t("job-offer.validationStep.endWhen")} :
              </Typography>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                value={end_date}
                onChange={(value) => handleDateChange(value, "end")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors && errors.end_date ? true : null}
                    helperText={
                      errors && errors.end_date ? errors.end_date : ""
                    }
                  />
                )}
              />
            </Box>
          ) : null}
        </Box>
      </FormGroup>
    </Box>
  );
};

export default DisponibilityValidator;

import React from "react";
import ReactDOMServer from "react-dom/server";
import DropzoneComponent from "react-dropzone-component";
import { useTranslation } from "react-i18next";

import { appConfig } from "../config.js";
import useAlert from "../hooks/useAlert";

/**
 * https://github.com/dropzone/dropzone
 * https://docs.dropzone.dev/configuration/basics/configuration-options
 * @param {Object} props
 * @returns {React Component}
 */
export default function Uploader(props) {
  const { t } = useTranslation();
  const alert = useAlert();
  const {
    url,
    xhrConfig,
    dzStyle,
    preview,
    thumbnail,
    showPreview,
    autoProcess,
    onInit,
    onDocumentUpload,
    onDocumentUploadSuccess,
    onDocumentUploadFailed,
  } = props;
  const { id } = props || "";

  const customDzStyle = {
    backgroundColor: "#d3d3d38a",
    minHeight: "10em",
    padding: "1em",
    borderRadius: "1em",
    borderStyle: "dashed",
    borderWidth: "2px",
    display: "flex",
  };

  const previewTemplate = ReactDOMServer.renderToStaticMarkup(
    <div
      className="dz-preview dz-file-preview"
      style={{ margin: "1em", display: showPreview ? "block" : "none" }}
    >
      <div className="dz-details">
        <div className="dz-filename">
          <span data-dz-name="true"></span>
        </div>
        <img
          alt="thumbnail"
          data-dz-thumbnail="true"
          style={{ display: "none" }}
        />
      </div>
      <div className="dz-progress">
        <span className="dz-upload" data-dz-uploadprogress="true"></span>
      </div>
      <div className="dz-success-mark" style={{ display: "none" }}>
        <span>✔ - {t("dropzone.success")}</span>
      </div>
      <div className="dz-error-mark" style={{ display: "none" }}>
        <span>✘ - {t("dropzone.failed")}</span>
      </div>
      <div className="dz-error-message">
        <span data-dz-errormessage="true"></span>
      </div>
    </div>
  );

  const config = {
    postUrl: url,
    iconFiletypes: [".jpg", ".jpeg", ".png", ".gif", ".pdf"],
    showFiletypeIcon: true,
    dropzoneSelector: `.custom-dz${id}`,
  };

  /**
   * Returns accepted Files MimeTypes string
   * ex: "image/jpeg,image/png,image/gif,application/pdf"
   * @param {Array} mimeTypes
   * @returns {String}
   */
  const getAcceptedFileMimeTypes = (mimeTypes) => {
    const acceptedFileMimeTypes = [];
    for (const mimeType of mimeTypes) {
      if (mimeType === "pdf") {
        acceptedFileMimeTypes.push(`application/${mimeType}`);
      } else {
        acceptedFileMimeTypes.push(`image/${mimeType}`);
      }
    }
    return acceptedFileMimeTypes.join(",");
  };

  const djsConfig = {
    method: "post",
    paramName: "file",
    uploadMultiple: false,
    maxFilesize: appConfig.upload.maxFileSize, // in Megas
    acceptedFiles: getAcceptedFileMimeTypes(appConfig.upload.allowedMimeTypes),
    addRemoveLinks: false,
    // params: xhrConfig,
    // headers: xhrConfig?.headers, // KO
    headers: {
      "sam-user-id": xhrConfig?.headers["sam-user-id"],
      language: xhrConfig?.headers["language"],
      "session-id": xhrConfig?.headers["session-id"],
      cachePolicy: xhrConfig?.headers["cachePolicy"],
      // "Access-Control-Allow-Origin": "*", // KO
    },
    withCredentials: true,
    createImageThumbnails: thumbnail,
    previewTemplate: preview ? preview : previewTemplate,
    autoProcessQueue:
      autoProcess !== null || autoProcess !== undefined ? autoProcess : true,
  };
  console.log("[Uploader] djsConfig:", djsConfig);

  const eventHandlers = {
    init: (dropzone) => {
      onInit(dropzone);
      console.log("[Uploader.init]", dropzone);
    },
    addedfile: (file) => {
      onDocumentUpload(file);
      console.log("[Uploader.addedfile]", file);
      if (file.type.includes("image"))
        file.previewElement.children[0].children[1].style.display = "block";
    },
    uploadprogress: (file) =>
      console.log("[Uploader.uploadprogress]", file?.upload?.progress),
    complete: (file) =>
      console.log("[Uploader.complete]", file, file?.xhr?.response),
    success: (file) => {
      console.log("[Uploader.success]", file, file?.xhr?.response);
      onDocumentUploadSuccess(JSON.parse(file?.xhr?.response));
      if (file.previewElement) {
        file.previewElement.children[2].style.display = "block";
        file.previewElement.classList.add("dz-success");
        alert.success({ message: t("alert.success.loading") });
      }
    },
    error: (file, error) => {
      console.error("[Uploader.error]", error);
      if (file.previewElement) {
        file.previewElement.children[3].style.display = "block";
        file.previewElement.classList.add("dz-error");
      }
      alert.error({ message: error.error ? error.error.message : error });
      onDocumentUploadFailed();
    },
  };
  return (
    <>
      <div
        className={`custom-dz${id}`}
        style={dzStyle ? dzStyle : customDzStyle}
      >
        {t("career.documents.addFile")}
      </div>
      <DropzoneComponent
        config={config}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
    </>
  );
}

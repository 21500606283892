import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";

import { Divider as MuiDivider, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import SearchJob from "../../components/SearchJob/SearchJob";

import useAuth from "../../hooks/useAuth";
import useSearchJob from "../../hooks/useSearchJob";

import Loader from "../../components/SearchJob/Loader";

const Divider = styled(MuiDivider)(spacing);

function JobOffers() {
  const { t } = useTranslation();
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user, setAppUserJobOffers } = useAuth();
  const { setCurrentView } = useSearchJob();

  // wet set current view
  setCurrentView("job-offers");

  // we complete data allready loaded by setAppUser()
  useEffect(() => {
    const initJobOffers = async () => {
      if (user) {
        if (!user.jobOffers) {
          console.log("[JobOffers] useEffect - initJobOffers");
          setIsLoading(true);

          await setAppUserJobOffers();
          setIsUserDataLoaded(true);
        } else {
          setIsUserDataLoaded(true);
          setIsLoading(false);
        }
      }
    };
    initJobOffers();
  }, [setAppUserJobOffers, user]);

  return (
    <React.Fragment>
      <Helmet title={t("menu.job-offers")} />
      <Typography
        variant="h3"
        gutterBottom
        display="grid"
        className="view-title"
      >
        {t("menu.job-offers")}
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <section className="search-job">
            <Loader show={isLoading} />
            {isUserDataLoaded && <SearchJob className="search-job-wrapper" />}
          </section>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default JobOffers;

import React from "react";
import {
  DataSearch,
  //DynamicRangeSlider,
  // RangeSlider,
  // SingleRange,
  MultiList,
  // MultiDataList,
  MultiRange,
} from "@appbaseio/reactivesearch";

import { useTranslation } from "react-i18next";

import Loader from "../Loader";

import { getUserFilter } from "../user.helpers";
import { getFilters, getMultiListCustomItem } from "./filters.helpers";
import { showError } from "../search.helpers";
import useSearchJob from "../../../hooks/useSearchJob";
import GeoDistanceFilter from "./GeoDistanceFilter";
// import AvailabilitiesByDaysFilters from "./AvailabilitiesByDaysFilters";
import AvailabilitiesByDaysAndTimePeriods from "./AvailabilitiesByDaysAndTimePeriods";

import { isMobileDevice } from "../../../helpers/common.helpers";
// import { appConfig } from "../../../config";

/**
 * Returns SearchFilters
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function SearchFilters(props) {
  const {
    userFilters,
    customFilters,
    selectedFilters,
    allChildcareSkillsRefused,
  } = props;
  const { t, i18n } = useTranslation();
  const { repositories } = useSearchJob();

  // const [childrenAgesValues, setChildrenAgesValues] = useState([]); // NB : to keep ?
  // console.log("[SearchFilters] - childrenAgesValues:", childrenAgesValues);

  if (!repositories) {
    // NB: if app version has changed, repositories will be re-fetched
    return (
      <Loader
        show={true}
        scrollTargetEltId={"search-filters"}
        loadingText={t("search-job.filters-loading")}
      />
    );
  }
  const language = i18n.language;
  // console.log("[SearchFilters] - language:", language);

  const hasUserFilters = userFilters && Object.keys(userFilters).length;
  console.log(
    "[SearchFilters] - hasUserFilters:",
    hasUserFilters,
    "userFilters:",
    userFilters,
    "customFilters",
    customFilters,
    "selectedFilters",
    selectedFilters
  );

  const mergedFilters = {
    ...userFilters,
    ...customFilters,
    ...selectedFilters,
  };
  console.log("[SearchFilters] - mergedFilters:", mergedFilters);

  /**
   * Returns Children Age Ranges
   * @param {Boolean} includeMaxRange
   * @returns {Array}
   */
  const getChildrenAgeRanges = (includeMaxRange = true) => {
    if (includeMaxRange) {
      return [
        { start: 0, end: 1, label: t("search-job.children-ages-ranges.1") },
        { start: 1, end: 2, label: t("search-job.children-ages-ranges.2") },
        { start: 2, end: 5, label: t("search-job.children-ages-ranges.3") },
        { start: 5, label: t("search-job.children-ages-ranges.4") },
      ];
    }
    return [
      { start: 0, end: 0, label: t("search-job.children-ages-ranges.1") },
      { start: 1, end: 1, label: t("search-job.children-ages-ranges.2") },
      { start: 3, end: 3, label: t("search-job.children-ages-ranges.3") },
      { start: 6, label: t("search-job.children-ages-ranges.4") },
    ];
  };

  /**
   * Returns Children Total Ranges
   * @returns {Array}
   */
  const getChildrenTotalRanges = () => {
    return [
      { start: 1, end: 1, label: "1" },
      { start: 2, end: 2, label: "2" },
      { start: 3, label: t("search-job.children-nber-ranges.3") },
    ];
  };

  /**
   * Returns Filtered children Total Ranges
   * @param {Array} childrenAgeRanges
   * @param {Array} childcareSkillsFilters
   * @returns {Array}
   */
  const getFilteredChildrenAgeRanges = (
    childrenAgeRanges,
    childcareSkillsFilters
  ) => {
    console.log(
      "[SearchFilters] getFilteredChildrenAgeRanges",
      childrenAgeRanges,
      childcareSkillsFilters
    );

    // all skills are refused > show nothing !
    if (allChildcareSkillsRefused) {
      return [];
    }

    // show all entries if no skills
    if (
      !childcareSkillsFilters ||
      (Array.isArray(childcareSkillsFilters) && !childcareSkillsFilters.length)
    ) {
      return childrenAgeRanges;
    }

    const filteredChildrenAgeRanges = childrenAgeRanges.filter(
      (childrenAgeRange) => {
        switch (childrenAgeRange.start) {
          case 0:
            return childcareSkillsFilters.includes("HBS4");
          case 1:
            return childcareSkillsFilters.includes("HBS1");
          case 2:
          case 3:
            return childcareSkillsFilters.includes("HBS2");
          case 5:
          case 6:
            return childcareSkillsFilters.includes("HBS3");

          default:
            return true;
        }
      }
    );

    return filteredChildrenAgeRanges;
  };

  // useEffect(() => {
  //   const initMergedFilters = async () => {
  //     console.log("[SearchFilters] initMergedFilters", mergedFilters);
  //   };
  //   initMergedFilters();
  // }, [mergedFilters]);

  return (
    <>
      {isMobileDevice() && (
        <GeoDistanceFilter
          className="filter mobile-geo-distance-no-distance-select"
          showTitle
          mergedFilters={mergedFilters}
        />
      )}

      <AvailabilitiesByDaysAndTimePeriods
        mergedFilters={mergedFilters}
        repositories={repositories}
        language={language}
      />

      <MultiList
        componentId="job-offer-childcare-types"
        dataField="childcare_type"
        className="filter"
        title={t("search-job.job-offer-childcare-types")}
        filterLabel={t("search-job.job-offer-childcare-types")}
        size={10}
        sortBy="count"
        defaultValue={getUserFilter(mergedFilters, "job-offer-childcare-types")}
        showSearch={false}
        react={{
          and: getFilters(["job-offer-childcare-types"]),
        }}
        renderError={(error) => showError("job-offer-childcare-types", error)}
        // transformData={(data) =>
        //   mapFilterData("job-offer-sub-types", data, repositories, language)
        // }
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(
            label,
            count,
            isSelected,
            "services",
            repositories,
            language,
            t
          )
        }
      />

      <MultiList
        componentId="job-offer-course-types"
        dataField="course_type"
        className="filter"
        title={t("search-job.job-offer-course-types")}
        filterLabel={t("search-job.job-offer-course-types")}
        size={10}
        sortBy="count"
        defaultValue={getUserFilter(mergedFilters, "job-offer-course-types")}
        showSearch={false}
        react={{
          and: getFilters(["job-offer-course-types"]),
        }}
        renderError={(error) => showError("job-offer-course-types", error)}
        // transformData={(data) =>
        //   mapFilterData("job-offer-sub-types", data, repositories, language)
        // }
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(
            label,
            count,
            isSelected,
            "services",
            repositories,
            language,
            t
          )
        }
      />

      {/* <MultiList
        componentId="job-offer-sub-types"
        dataField="sub_type"
        className="filter"
        title={t("search-job.job-offer-sub-types")}
        filterLabel={t("search-job.job-offer-sub-types")}
        size={10}
        sortBy="asc"
        defaultValue={getUserFilter(mergedFilters, "job-offer-sub-types")}
        showSearch={false}
        react={{
          and: getFilters(["job-offer-sub-types"]),
        }}
        renderError={(error) => showError("job-offer-sub-types", error)}
        // transformData={(data) =>
        //   mapFilterData("job-offer-sub-types", data, repositories, language)
        // }
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(
            label,
            count,
            isSelected,
            "services",
            repositories,
            language,
            t
          )
        }
      /> */}

      {/* <MultiList
        componentId="languages"
        dataField="languages"
        className="filter"
        title={t("search-job.languages")}
        filterLabel={t("search-job.languages")}
        size={10}
        sortBy="count"
        defaultValue={getUserFilter(mergedFilters, "languages")}
        showSearch={false}
        react={{
          and: getFilters(["languages"]),
        }}
        renderError={(error) => showError("languages", error)}
        // transformData={(data) =>
        //   mapFilterData("languages", data, repositories, language)
        // }
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(
            label,
            count,
            isSelected,
            "languages",
            repositories,
            language,
            t
          )
        }
      /> */}
      <MultiRange
        componentId="children-ages"
        dataField="min_children_age"
        data={getFilteredChildrenAgeRanges(
          getChildrenAgeRanges(false),
          getUserFilter(userFilters, "childcare-skills")
        )}
        // data={getChildrenAgeRanges(false)}
        // dataField="children_ages"

        className="filter"
        title={t("search-job.children-ages")}
        filterLabel={t("search-job.children-ages")}
        react={{
          and: getFilters(["children-ages"]),
        }}
        defaultValue={getUserFilter(
          mergedFilters,
          "children-ages",
          "MultiRange"
        )}
        // value={childrenAgesValues}
        // onChange={setChildrenAgesValues}
      />
      <MultiRange
        componentId="children-total"
        dataField="children_total"
        data={getChildrenTotalRanges()}
        className="filter"
        title={t("search-job.children-total")}
        filterLabel={t("search-job.children-total")}
        react={{
          and: getFilters(["children-total"]),
        }}
        defaultValue={getUserFilter(
          mergedFilters,
          "children-total",
          "MultiRange"
        )}
      />
      {/* <SingleRange
        componentId="children-total"
        dataField="children_total"
        data={getChildrenTotalRanges()}
        className="filter"
        title={t("search-job.children-total")}
        filterLabel={t("search-job.children-total")}
        react={{
          and: getFilters(["children-total"]),
        }}
        defaultValue={getUserFilter(
          mergedFilters,
          "children-total",
          "SingleRange"
        )}
      /> */}
      {/* <RangeSlider
        componentId="children-total"
        dataField="children_total"
        className="filter"
        title={t("search-job.children-total")}
        filterLabel={t("search-job.children-total")}
        showHistogram={false}
        showFilter={true}
        range={{
          start: 1,
          end: 10,
        }}
        rangeLabels={{
          start: "1",
          end: "10",
        }}
        stepValue={1}
        defaultValue={getUserFilter(
          mergedFilters,
          "children-total",
          "RangeSlider"
        )}
        // defaultValue={getRangeSliderDefaultValues(
        //   getUserFilter(mergedFilters, "children-total")
        // )}
        react={{ and: getFilters(["children-total"]) }}
      /> */}
      <DataSearch
        componentId="reference"
        dataField={[
          {
            field: "reference",
            weight: 1,
          },
        ]}
        title={t("search-job.reference")}
        placeholder={t("search-job.reference-placeholder")}
        className="filter last"
        autosuggest={true}
        showFilter={true}
        URLParams={false}
        size={10}
        defaultValue={getUserFilter(mergedFilters, "reference")}
        react={{
          and: getFilters(["reference"]),
        }}
        renderError={(error) => showError("reference", error)}
      />
      {/* <MultiDataList
        componentId="availabilities"
        dataField="availabilities"
        className="filter"
        title={t("week-days.title")}
        filterLabel={t("week-days.title")}
        showSearch={false}
        showCount={true}
        data={[
          {
            label: t("week-days.monday"),
            value: "MONDAY",
          },
          {
            label: t("week-days.tuesday"),
            value: "TUESDAY",
          },
          {
            label: t("week-days.wednesday"),
            value: "WEDNESDAY",
          },
          {
            label: t("week-days.thursday"),
            value: "THURSDAY",
          },
          {
            label: t("week-days.friday"),
            value: "FRIDAY",
          },
          {
            label: t("week-days.saturday"),
            value: "SATURDAY",
          },
          {
            label: t("week-days.sunday"),
            value: "SUNDAY",
          },
        ]}
        defaultValue={getUserFilter(mergedFilters, "availabilities")}
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(
            label,
            count,
            isSelected,
            "availabilities",
            repositories,
            language,
            t
          )
        }
        react={{ and: getFilters(["availabilities"]) }}
      /> */}
      {/* <AvailabilitiesByDaysFilters selectedFilters={mergedFilters} /> */}
      {/* // -- User filters passed directly by query */}
      {/* <MultiList
        componentId="job-offer-types"
        dataField="type"
        className={hasUserFilters ? "filter admin disabled" : "filter admin"}
        showFilter={!Boolean(hasUserFilters)}
        title={t("search-job.job-offer-types")}
        filterLabel={t("search-job.job-offer-types")}
        size={2}
        sortBy="count"
        defaultValue={getUserFilter(mergedFilters, "job-offer-types")}
        showSearch={false}
        react={{ and: getFilters(["job-offer-types"]) }}
        renderError={(error) => showError("job-offer-types", error)}
        // transformData={(data) =>
        //   mapFilterData("types", data, repositories, language)
        // }
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(
            label,
            count,
            isSelected,
            "types",
            repositories,
            language,t
          )
        }
      /> */}
      {/* <MultiList
        componentId="languages-levels"
        dataField="languages_levels"
        className={hasUserFilters ? "filter admin disabled" : "filter admin"}
        showFilter={!Boolean(hasUserFilters)}
        title={t("search-job.languages-levels")}
        filterLabel={t("search-job.languages-levels")}
        size={30}
        sortBy="count"
        defaultValue={getUserFilter(mergedFilters, "languages-levels")}
        showSearch={false}
        react={{
          and: getFilters(["languages-levels"]),
        }}
        renderError={(error) => showError("languages-levels", error)}
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(label, count, isSelected)
        }
      /> */}
      {/* <MultiList
        componentId="childcare-skills"
        dataField="childcare_skills"
        className={hasUserFilters ? "filter admin disabled" : "filter admin"}
        showFilter={!Boolean(hasUserFilters)}
        title={t("search-job.childcare-skills")}
        filterLabel={t("search-job.childcare-skills")}
        size={10}
        sortBy="count"
        defaultValue={getUserFilter(mergedFilters, "childcare-skills")}
        showSearch={false}
        react={{
          and: getFilters(["childcare-skills"]),
        }}
        renderError={(error) => showError("childcare-skills", error)}
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(label, count, isSelected)
        }
      /> */}
      {/* <MultiList
        componentId="job-offer-ids"
        dataField="id"
        className={hasUserFilters ? "filter admin disabled" : "filter admin"}
        showFilter={!Boolean(hasUserFilters)}
        filterLabel="user-job-offer-ids"
        size={10}
        sortBy="asc"
        defaultValue={getUserFilter(mergedFilters, "job-offer-ids")}
        queryFormat="and"
        showSearch={false}
        showCount={false}
        react={{
          and: getFilters(["job-offer-ids"]),
        }}
        renderError={(error) => showError("job-offer-ids", error)}
        renderItem={(label, count, isSelected) =>
          getMultiListCustomItem(label, count, isSelected)
        }
      /> */}
      {/* // -- Tests */}
      {/* <MultiList
        componentId="reference"
        dataField="reference.keyword"
        title="Rechercher par id offre"
        filterLabel="Id offre"
        size={0}
        sortBy="asc"
        showSearch={false}
        className="filter disabled"
        showFilter={true}
        URLParams={true}
      /> */}
      {/* <DataSearch
        autosuggest={false}
        componentId="city-search"
        dataField={["city"]}
        filterLabel="Ville"
        highlight={false}
        placeholder="Rechercher une ville"
        title="Ville(s)"
        showIcon={true}
        debounce={2}
        clearIcon={<span>X</span>}
      /> */}
      {/* <MultiList
        componentId="cities"
        dataField="city"
        className="filter"
        title=""
        filterLabel="Villes"
        size={10}
        sortBy="count"
        defaultValue={getUserProperty(userFilters, "city")}
        showSearch={false}
        react={{
          and: getFilters(["cities"]),
        }}
      /> */}
      {
        // NB: if active : pass property to "keyword"
        /* <MultiRange
        componentId="hours-by-week"
        dataField="hours_by_week"
        className="filter"
        data={[
          { start: 31, end: 70, label: "Temps plein" },
          { start: 21, end: 30, label: "20 - 30" },
          { start: 11, end: 20, label: "10 - 20" },
          { start: 1, end: 10, label: "0 - 10" },
        ]}
        title="Heures / Semaine"
      /> */
      }
      {/* https://opensource.appbase.io/reactive-manual/range-components/dynamicrangeslider.html */}
      {/* <DynamicRangeSlider
        componentId="package-rating"
        dataField="package"
        title="Salaire horaire (€)"
        filterLabel="Salaire horaire (€)"
        rangeLabels={(min, max) => ({
          start: `${Math.floor(min)} €`,
          end: `${Math.ceil(max)} €`,
        })}
        // rangeLabels={(min, max) => ({
        //   start: `${Number(min).toFixed(2)} €`,
        //   end: `${Number(max).toFixed(2)} €`
        // })}
        stepValue={1}
        onValueChange={(values) => {
          console.log("[DynamicRangeSlider]", values);
          // if (values) {
          //   return [Math.floor(values[0]), Math.ceil(values[1])];
          // }
        }}
        react={{ and: getFilters(["package_rating"]) }}
        renderError={(error) => showError("package-rating", error)}
      /> */}
    </>
  );
}

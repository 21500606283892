import React from "react";
import { useTranslation, Trans } from "react-i18next";
import styled from "@emotion/styled";

import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

import ImageEN from "../../statics/GoodDeals_EN.png";
import ImageFR from "../../statics/GoodDeals_FR.png";

const CustomButton = styled(Button)({
  backgroundColor: "#FED24C",
  color: "#2B4C96",
  "&:hover": {
    backgroundColor: "#EEC23C",
  },
  float: "right",
});

const GoodDeals = () => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const goodDealsUrl =
    language === "en"
      ? "https://www.momji.fr/jobs/en/your-momji-advantages-and-good-deals"
      : "https://www.momji.fr/jobs/les-bons-plans-proposes-par-nos-partenaires";

  const handleOpenLink = () => {
    window.open(goodDealsUrl, "_blank");
  };
  return (
    <Box>
      <Box mb="2em">
        <Typography variant="h4">{t("resources.good-deals.title")}</Typography>
      </Box>
      <Card sx={{ paddingBottom: "2em", width: "fit-content" }}>
        <CardContent>
          <Box>
            <img
              src={language === "en" ? ImageEN : ImageFR}
              alt="Good-deals-visual"
              style={{
                width: "100%",
                height: "100%",
                maxWidth: "720px",
                maxHeight: "540px",
                cursor: "pointer",
              }}
              onClick={handleOpenLink}
            />
            <Box m="1em">
              <Box m="1em">
                <Box mb="1em">
                  <Trans
                    i18nKey="resources.good-deals.text.1"
                    components={{ bold: <strong /> }}
                  />
                </Box>
                <Trans
                  i18nKey="resources.good-deals.text.2"
                  components={{ bold: <strong /> }}
                />
              </Box>
              <CustomButton
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={handleOpenLink}
              >
                {t("resources.good-deals.button")}
              </CustomButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GoodDeals;

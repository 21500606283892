import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  IconButton,
  Link,
  Step,
  Stepper,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRighttOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import CircleIcon from "@mui/icons-material/Circle";

import { isMobileDevice } from "../../helpers/common.helpers";

import logo from "../../statics/Logos/Logo.svg";

import YoutubeEmbed from "../message/YoutubeEmbed";

const Button = styled(MuiButton)(spacing);

function OnBoarding(props) {
  const { onClose } = props;
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      step: "welcome",
      title: "on-boarding.welcome.title",
      text: ["on-boarding.welcome.text"],
    },
    {
      step: "profile",
      title: "on-boarding.profile.title",
      text: ["on-boarding.profile.text"],
    },
    {
      step: "offers",
      title: "on-boarding.offers.title",
      text: [
        "on-boarding.offers.text.immersion",
        "on-boarding.offers.text.initiation",
        "on-boarding.offers.text.creative",
      ],
    },
    {
      step: "discover",
      title: "on-boarding.discover.title",
      video: {
        fr: "1PFZQheqTy4",
        en: "4WuSCpMhf0w",
      },
    },
  ];
  const maxSteps = steps.length;
  let touchStartX = 0;
  const buttonClassName = "OnBoarding-Button";

  const { language } = i18n;

  /**
   * Next step
   * @returns {void}
   */
  const handleNext = () => {
    if (activeStep !== maxSteps - 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   * Previous step
   * @returns {void}
   */
  const handleBack = () => {
    if (activeStep !== 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * Set touch x origin
   * @param {Object} event
   * @returns {void}
   */
  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  /**
   * Calculate swipe direction and trigger next or back
   * @param {Object} event
   * @returns {void}
   */
  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;

    if (touchStartX - touchEndX > 20) {
      handleNext();
    }
    if (touchStartX - touchEndX < -20) {
      handleBack();
    }

    touchStartX = 0;
  };

  /**
   * Render texts for active step
   * @param {integer} activeStep
   * @returns {Array}
   */
  const renderStepText = (activeStep) => {
    const texts = steps[activeStep].text.map((value, index) => (
      <Typography
        key={index}
        sx={{ color: "white", textAlign: "center" }}
        variant="h6"
        mb="0.5em"
      >
        {t(value)}
      </Typography>
    ));
    return (
      <Box
        display="grid"
        justifyContent="center"
        pr="2em"
        pl="2em"
        minHeight="85px"
        mt="4em"
      >
        {texts}
      </Box>
    );
  };

  const renderStepVideo = (activeStep) => {
    console.log(
      "[OnBoarding] renderStepVideo",
      steps[activeStep].video[language]
    );
    return (
      <Box mt="1em">
        <YoutubeEmbed key="video" embedId={steps[activeStep].video[language]} />
      </Box>
    );
  };

  return (
    <Box
      display="grid"
      sx={{
        height: {
          xs: "100%",
          md: "100%",
        },
        "@media screen and (orientation: landscape)": {
          height: {
            xs: "auto",
            md: "100%",
          },
        },
        bgcolor: "#1565C0",
        padding: "1em",
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {activeStep !== 0 && !isMobileDevice() ? (
        <Button
          sx={{ position: "fixed", top: "47%", left: "15%" }}
          className={buttonClassName}
          onClick={handleBack}
        >
          {theme.direction === "rtl" ? (
            <ArrowCircleRighttOutlinedIcon
              sx={{ color: "white", fontSize: "3rem" }}
            />
          ) : (
            <ArrowCircleLeftOutlinedIcon
              sx={{ color: "white", fontSize: "3rem" }}
            />
          )}
        </Button>
      ) : (
        <Button
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "10%",
            minWidth: 0,
            padding: 0,
          }}
          className={buttonClassName}
          onClick={handleBack}
          disabled={activeStep === 0}
        />
      )}
      {activeStep !== maxSteps - 1 && !isMobileDevice() ? (
        <Button
          sx={{ position: "fixed", top: "47%", left: "80.834%" }}
          className={buttonClassName}
          onClick={handleNext}
        >
          {theme.direction === "rtl" ? (
            <ArrowCircleLeftOutlinedIcon
              size="large"
              sx={{ color: "white", fontSize: "3rem" }}
            />
          ) : (
            <ArrowCircleRighttOutlinedIcon
              sx={{ color: "white", fontSize: "3rem" }}
            />
          )}
        </Button>
      ) : (
        <Button
          sx={{
            position: "fixed",
            top: 0,
            left: "90%",
            height: "100%",
            width: "10%",
            minWidth: 0,
            padding: 0,
          }}
          className={buttonClassName}
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        />
      )}
      <Box display="grid" justifyContent="right" width="inherit">
        <IconButton
          className={buttonClassName}
          onClick={onClose}
          disabled={activeStep !== maxSteps - 1}
          mt="1em"
          mr="1em"
        >
          <CloseOutlinedIcon
            sx={{ color: activeStep === maxSteps - 1 ? "white" : "#1565C0" }}
          />
        </IconButton>
      </Box>

      <Box display="grid" justifyContent="center">
        {
          <Box display="grid" justifyContent="center" mb="1em">
            <img style={{ maxWidth: "100px" }} src={logo} alt="Logo" />
          </Box>
        }
        <Typography sx={{ color: "white" }} variant="h3">
          {t(steps[activeStep].title)}
        </Typography>
      </Box>

      {steps[activeStep].text !== undefined && renderStepText(activeStep)}
      {steps[activeStep].video !== undefined && renderStepVideo(activeStep)}

      <Box
        sx={{
          display: "grid",
          justifyContent: "center",
          bgcolor: "#ffffff00",
        }}
      >
        <Link
          href="#"
          underline="always"
          sx={{ color: "white", textDecoratioColor: "white", display: "none" }}
        >
          <Typography variant="h4">{t("on-boarding.helpcenter")}</Typography>
        </Link>
        <Box display="grid" justifyContent="center" mt="3em">
          <Stepper steps={maxSteps} activeStep={activeStep} connector={null}>
            {steps.map(({ step }, index) => {
              return (
                <Step key={step}>
                  <CircleIcon
                    fontSize="small"
                    sx={{ color: activeStep < index ? "white" : "#57A57E" }}
                  />
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Box>
    </Box>
  );
}

export default OnBoarding;

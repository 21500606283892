import { amber, green, red } from "@mui/material/colors";

const colorStatus = {
  over: {
    text: red[500],
    background: red[50],
  },
  to_sign: {
    text: amber[700],
    background: amber[50],
  },
  active: {
    text: green[500],
    background: green[50],
  },
};

const actions = {
  to_sign: "sign",
  to_read: "file",
};

const healthcareChoices = {
  ACCEPTED: "1",
  REFUSED: "2",
};

const healthcareActions = {
  CHOOSE: "choose",
  SIGN: "sign",
  COMPLETE: "complete",
};

const statusMap = new Map();
statusMap.set("Terminé", "over");
statusMap.set("A signer", "to_sign");
statusMap.set("En cours", "active");

const contractType = {
  prestataire: "contracts",
  mandataire: "mandataires",
};

export {
  colorStatus,
  actions,
  healthcareChoices,
  healthcareActions,
  statusMap,
  contractType,
};

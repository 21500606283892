import React, { useState, useEffect, useCallback } from "react";

import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";

import useAlert from "../../../hooks/useAlert";
import useAuth from "../../../hooks/useAuth";

import { parseResponseErrorMessage } from "../../../helpers/errorMessages.helpers";

/**
 * Returns SearchFiltersEmailAlert
 * @param {Object} props
 * @returns {ReactComponent}
 */
export default function SearchFiltersEmailAlert(props) {
  const { t } = useTranslation();
  const alert = useAlert();

  const { selectedFilters, emailAlert, titleVariant } = props;
  const { employeeConnector, setAppUserEmailAlert } = useAuth();
  const isEmailAlertActive = Boolean(emailAlert?.is_active);

  const [checked, setChecked] = useState(isEmailAlertActive);
  const [filters, setFilters] = useState(selectedFilters);

  console.log(
    "[SearchFiltersEmailAlert] isEmailAlertActive:",
    isEmailAlertActive,
    "selectedFilters:",
    selectedFilters,
    "filters:",
    filters,
    emailAlert
  );

  /**
   * Set Email Alert for current user
   * @param {Event} e
   * @returns {ReactComponent}
   */
  const handleSetEmailAlert = async (e) => {
    const checked = e.target.checked;

    setChecked(e.target.checked);

    const data = {
      filters: filters || {},
      is_active: checked,
    };

    setEmailAlert(data, true);
  };

  /**
   * Add / Update user EmailAlert
   * @param {Object} data
   * @param {Boolean} showAlert
   * @returns {void}
   */
  const setEmailAlert = useCallback(
    async (data, showAlert) => {
      try {
        const result = await employeeConnector.addEmailAlert(data);

        // we update the state
        if (result) {
          setAppUserEmailAlert(result.data);

          if (showAlert) {
            alert.success({
              message: Boolean(result.data.is_active)
                ? t("alert.success.email-alert-on")
                : t("alert.success.email-alert-off"),
            });
          }
        }
      } catch (error) {
        alert.error({ message: parseResponseErrorMessage(error) });
        console.error("[SearchFiltersEmailAlert]", error);
      }
    },
    [alert, employeeConnector, setAppUserEmailAlert, t]
  );

  // update email-alert filters if filters have changed
  useEffect(() => {
    if (
      selectedFilters &&
      JSON.stringify(selectedFilters) !== JSON.stringify(filters)
    ) {
      setFilters(selectedFilters);

      // update user filters
      if (emailAlert.is_active) {
        console.log(
          "[SearchFiltersEmailAlert] useEffect - setting emailAlert with new filters:",
          selectedFilters
        );

        const data = {
          filters: selectedFilters,
          is_active: emailAlert.is_active,
        };

        setEmailAlert(data);
      }
    }
  }, [filters, selectedFilters, setEmailAlert, emailAlert.is_active]);

  return (
    <>
      <FormGroup className="email-alert">
        <Typography mb="20px" variant={titleVariant || "h3"} gutterBottom>
          {t("search-job.email-alert")}
        </Typography>
        <FormControlLabel
          checked={checked}
          control={<Switch className="custom-switch" />}
          label={t("search-job.email-alert-label")}
          onClick={(e) => handleSetEmailAlert(e)}
        />
      </FormGroup>
    </>
  );
}

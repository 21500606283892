const translations = {
  common: {
    dateFieldInputFormat: "yyyy/MM/dd hh:mm",
    documentation: "Documentation",
  },
  datagrid: {
    id: "ID",
    name: "Last name",
    firstname: "First Name",
    userType: "User Type",
    code: "Code",
    isActive: "Active",
    isActiveF: "Active",
    edit: "Edit",
    delete: "Delete",
    activate: "Activate",
    deactivate: "Deactivate",
    actions: "Actions",
    type: "Type",
    httpMethod: "Méthode HTTP ",
    path: "Path",
    api: "API",
    role: "Role",
    action: "Action",
    department: "Department",
  },
  search: "Search topics ...",
  home: {
    title: "Dashboard",
    breadcrumb: "Home",
  },

  loader: {
    loading: "Loading",
    add: "Addition in progress",
    delete: "Deletion in progress",
    downloading: "Downloading",
    "repositories-loading": "Loading data ...",
  },
  form: {
    saveChanges: "Save",
    cancel: "Cancel",
    delete: "Delete",
    errors: {
      required: {
        type: "Type required",
        "start-date": "Start date incomplete",
        "end-date": "End date incomplete",
        "birth-date": "Birthdate required",
        field: "This field is required",
      },
      invalid: {
        "end-date": "End date must be posterior to start date",
        "birth-date":
          "Date of birth must be before the end date of the experience",
        code: "The code format is not correct",
        duration:
          "The service must be of a duration between one and twelve hours",
      },
    },
  },
  dialog: {
    cancel: "Cancel",
    confirm: "Confirm",
    confirmChangeText: "Are you sure you want to save these changes?",
    confirmDeleteText: "Are you sure you want to delete this element",
    confirmCancel: "Some changes are not saved, do you want to continue?",
  },

  alert: {
    success: {
      create: "Created successfully",
      add: "Added successfully",
      delete: "Deleted successfully",
      update: "Updated successfully",
      loading: "Loaded successfully",
      //apply: "Application saved",
      //bookmark: "The offer was bookmarked",
      //unbookmark: "The offer was removed from your bookmarks",
      "email-alert-on": "Email alert activated",
      "email-alert-off": "Email alert deactivated",
      "forgot-password": "An email was sent to your email address",
      "job-offer-bookmarked":
        "Job offer {{ jobOffer.reference }} was bookmarked",
      "job-offer-unbookmarked":
        "Job offer {{ jobOffer.reference }} was removed from your bookmarks",
      "job-offer-applied":
        "Applicated registered for the offer {{ jobOffer.reference }}",
      "add-experience": "Experience added",
      "job-offer-deleted":
        "The job offer  {{ jobOffer.reference }} has been deleted from your suggestions",
      "job-offer-unapplied":
        "Your application for the offer {{ jobOffer.reference }} has been removed",
      "to-clipboard": "Added to your clipboard",
    },
  },
  dropzone: {
    drophere: "Drag and drop your files or browse to upload",
    success: "Success",
    failed: "Failure",
  },
  menu: {
    home: "Dashboard",
    "job-offers": "Our job offers",
    contracts: {
      contracts: "My contracts",
      due: "DUE",
      healthcare: "Healthcare",
    },
    payslips: "My payslips",
    planning: "My planning",
    documents: "My documents",
    resources: {
      resources: "Mômji supports",
      activities: "My activities of the month",
      "good-deals": " My deals and promotions",
      training: "My Mômji training",
    },
    "user-profile": "My profile",
    career: "My Mômji journey",
    "my-offers": {
      "my-offers": "My offers",
      applications: "Applications",
      bookmarks: "Bookmarks",
      pushes: "Suggestions",
    },
    refer: "Refer your friends",
  },

  detail: {
    name: "Name",
    family_name: "Last Name",
    firstname: "First Name",
    given_name: "Given Name",
    userType: "User Type",
    isActive: "Active",
    isActiveF: "Active",
    id: "ID ",
    createdAt: "Created on",
    updatedAt: "Updated on",
    description: "Description",
    code: "Code",
    fieldsRequired: "The fields marked with a * sont required",
    createdBy: "Created by",
    updatedBy: "Updated by",
    type: "Type",
    httpMethod: "Méthode HTTP",
    roleType: "Type de rôle",
    department: "Department",
    action: "Action",
    api: "Api",
    path: "Path",
    email: "Email address",
    role: "Role",
    noValue: "No values",
  },

  auth: {
    loading: "Loading user data ...",
    welcome: "Welcome back",
    "missed-you": "We've missed you",
    "sign-in": "Sign in to your account to continue",
    profile: "Profile",
    "sign-out": "Sign out",
    "sign-out-progress": "Logout in progress ...",
    account: "Account",
    login: "Your email",
    password: "Your password",
    "remember-me": "Remember me",
    "forgot-password": "I forgot my password",
    "reset-password": "Enter new password",
    "reset-password-both-same": "Both password need to be the same",
    "reset-password-new": "Your new password",
    "reset-password-new-confirm": "Confirm your new password",
    submit: "Submit",
    validation: {
      "valid-email": "Invalid email address",
      "required-email": "Email address required",
      "required-password": "Password required",
    },
    errors: {
      global: "Something went wrong ...",
    },
    "forgot-password-email":
      "Please enter your email address to reset your password",
    "forgot-password-submit": "Request a new password",
    "reset-password-email": "Please enter your new password",
    "reset-password-submit": "Save your new password",
    "reset-password-min-size": "Your password must be at least 12 characters",
    "auto-connect": "Connection to your space in progress….",
    "page-not-found-title": "Page not found.",
    "page-not-found-description":
      "The page you are looking for might have been removed ...",
    "back-to-website": "Return to website",
    "back-to-login": "Return to login page",
    required: "Required",
    "bad-password-format":
      "The password does not match the expected format. It must have: 8 characters, at least one number, at least one lowercase letter, at least one uppercase letter",
    "back-to-sign-in": "Go back to the login page",
    "internal-error-title": "Internal server error",
    "internal-error-description":
      "The server encountered something unexpected that didn't allow it to complete the request.",
  },

  "search-job": {
    application: "Application",
    rejected: "Application rejected",
    canceled: "Application canceled",
    meeting: "Meeting in progress",
    contract: "Contract in progress",
    transmission: "Application submitted",
    search: "Search",
    "search-loading": "Loading ...",
    "search-lazy-loading": "Loading offers ...",
    "search-data-loading": "Loading data ...",
    "my-offers-loading": "Loading your offers ...",
    "job-offer-types": "Job offer types",
    fromDate: "From",
    child: "Child",
    children: "Children",
    languages: "Languages",
    "languages-levels": "Levels by language",
    "starting-address": "Departure address",
    "starting-address-input": "Select a departure address",
    distance: "Distance",
    "starting-location": "Point of departure",
    filters: "Filters",
    filter: "Filter",
    "init-filters": "Clear all filters",
    "filters-loading": "Loading filters ...",
    "children-ages": "Age of youngest child",
    "children-ages-ranges": {
      1: "< 1 year",
      2: "1 - 2 years",
      3: "3 - 5 years",
      4: "> 6 years",
    },
    "children-nber-ranges": {
      3: "3 and more",
    },
    "children-total": "Number of children",
    availabilities: "Availabilities",
    "childcare-skills": "Childcare skills",
    hoursByWeek: "Hours / week",
    days: {
      monday: "L",
      tuesday: "M",
      wednesday: "M",
      thursday: "J",
      friday: "V",
      saturday: "S",
      sunday: "D",
    },
    "time-from": "From",
    relevance: "relevance",
    package: "package",
    "zip-code": "zip code",
    asc: "ASC",
    desc: "DESC",
    reference: "Reference",
    "reference-placeholder": "Reference number",
    offer: "offer",
    offers: "offers",
    "email-alert": "Email alert",
    "email-alert-label":
      "Check this box if you want to receive new offers that match your criteria",
    btn: "See the offers {{ items }} ",
    "no-results": "No offer found ...",
    sorting: {
      label: "Order",
      distance: "Distance",
      "hours-by-week": "Hours / week",
      "starting-date": "Starting date",
      package: "Package",
      asc: "Ascending",
      desc: "descending",
    },
    "result-stats": {
      none: "No offer found ...",
      one: "<b>1</b> offer has been found",
      many: "<b>{{ numberOfResults }}</b> offers have been found",
    },
    "job-offer-sub-types": "Offer type",
    "job-offer-childcare-types": "Our childcare offers",
    "job-offer-course-types": "Our language course offers",
  },

  "job-offer": {
    offer: "Job offer",
    status: "Status",
    dealstatus: {
      none: "You have not yet applied to this offer",
      transmission: "Your application has been submitted to the family",
      meeting:
        "Your application has been accepted and you are now the process of meeting the family",
      contract: "Your application has been accepted",
      archive: "Your application has been archived",
      rejected: "Your application has been rejected",
      application: "You have sent your application for this offer",
    },

    validationStep: {
      validation: "Confirmation",
      correspondingAvailability: "Corresponding availability",
      validateSchedule:
        "I make sure I am available for the hours requested in the offer schedule",
      validateDates:
        "I make sure I am available between the dates requested in the offer",
      startWhen: "I am available from ",
      endWhen: "I am available until ",
      travelTime: "Travel Time",
      validateTravelTime:
        "I make sure to not have more than one hour of travel time for the offer",
      validateApply: "I confirm my application",
      yes: "yes",
      no: "no",
    },

    applied: {
      title: "Application registered",
      congratulation: "Congratulations!",
      tips: "To maximize your chance of finding a family, we recommend that you also apply to other offers.",
      description:
        "Your application has been taken into account and we will review it in the coming days.",
      return: "I apply to more offers",
    },
    start: "Start",
    end: "End",
    perWeek: "per week",
    hour: "hour",
    grossSalary: "Gross salary",
    paidLeave: "Paid leave",
    transportation: "Transportation",
    detail: "Offer details",
    "see-detail": "See the offer details",
    type: "Type of offer",
    languageLevel: "Minimum language requirements",
    dates: "Dates of the offer",
    schedule: "Offer schedule",
    client: "The family",
    location: "Location",
    salary: "Package / hour",
    travelTime: "Travel Time",
    similarOffers: "Similar offers",
    apply: "Apply",
    unapply: "Remove my application",
    yearsOld: "years old",
    and: "and",
    "not-found": "Offer  n° {{ reference }} unknown",
    map: {
      "load-data": "Load the travel data",
    },

    "sub-types": {
      initiation:
        "Initiation childcare: is in French with a 30 minutes initiation to English",
      immersion: "Immersion childcare: is 100% in a foreign language",
      creative:
        "Creative childcare: is 100% in French with many activities provided",
      course:
        "Language courses : you prepare and lead a language course to meet the objectives of your student",

      details: {
        initiation:
          "Initiation childcare: is in French with a 30 minutes initiation to English",
        immersion: "Immersion childcare: is 100% in {{ language }}",
        creative:
          "Creative childcare: is 100% in French with many activities provided",
        course:
          "Language courses : you prepare and lead a language course to meet the objectives of your student",
      },

      tooltips: {
        initiation:
          "Mômji Initiation : in French with a 30 minutes introduction in English",
        immersion: "Mômji Immersion : in a foreign language ({{ language }})",
        creative: "Mômji Créative : in French with lots of activities to do",
        course: "Cours : lesson-based moments",
      },

      titles: {
        initiation: "Initiation childcare in english",
        immersion: "Immersion childcare in {{ language }}",
        creative: "Creative childcare in French",
        course: "Language course in {{ language }}",
      },
    },
    "additionnal-info": "Additional informations",
  },

  "user-profile": {
    yourProfile: "My profile",
    informations: "My information",
    professionalExperiences: "My professional experiences",
    noExperiences: "No professional experience saved",
    newExperience: "New professional experience",
    communicationLanguage: "Communication Language",
    changeCommunicationLanguage:
      "To modify the language of your personal space and the language in which we send you your communications",
    password: "My password",
    resetPassword: "To reset your password, click on the button below.",
    resetMyPassword: "Reset my password",
    cancel: "Cancel",
    save: "Save",
    languages: {
      "en-GB": "English",
      "fr-FR": "French",
    },
    firstname: "First name",
    lastname: "Last Name",
    nationality: "Nationality",
    phoneNumber: "Phone number",
    email: "Email address",
    address: "Address",
    zipcode: "Zipcode",
    city: "City",
    experience: {
      experience: "Experience",
      duration: "Duration",
      "birth-date": "Date of birth",
      newBorn: "New born",
      type: "Type",
      from: "From",
      to: "To",
      month: "Month",
      year: "Year",
      children: "Children",
      noChildren: "No children registered",
      child: "Child",
      missions: "Missions",
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
      monthOld: "month",
      monthsOld: "months",
      yearsOld: "years",
      yearOld: "year",
      and: "and",
    },
  },

  "on-boarding": {
    welcome: {
      title: "Welcome to Mômji",
      text: "This space is now yours. You will be able to access our offers, apply and find a job!",
    },
    profile: {
      title: "Complete your profile",
      text: "The faster your profile will be completed, the faster families will see it and wish to meet you.",
    },
    offers: {
      title: "Our offers",
      text: {
        immersion: "Immersion childcare: is 100% in a foreign language",
        initiation:
          "Initiation childcare: is in French with a 30 minutes introduction in English",
        creative:
          "Creative Childcare: is in French with moments dedicated to creativity",
      },
    },
    discover: {
      title: "Discovering Mômji",
    },
    helpcenter: "Our help center",
  },

  "my-offers": {
    applications: "Applications",
    bookmarks: "Bookmarks",
    pushes: "Suggestions",
  },
  career: {
    documents: {
      documents: "Documents",
      addFile: "Add a file or photo",
      JUSTIFICATIF: "Documentary proof that you can work in France",
      IDENTITE: "ID documents",
      OTHER: "Other documents requested",
      status: {
        init: "Requested",
        invalidated: "Declined",
        "to-validate": "Pending validation",
        validated: "Confirmed",
      },
      validate: "Validate",
      noDocumentsRequired: "No other documents required",
      "select-id-doc-type": "Select a type of document",
      acceptedFormat: "Accepted formats",
      maxSize: "Maximum size accepted",
      alreadyUploaded: "Document already uploaded",
      description: {
        IDENTITE:
          "Your ID document is required to edit documents related to your contracts with Mômji",
        JUSTIFICATIF:
          "The following documents are mandatory to allow you to work in France",
        OTHER: "The following documents are required for you to work in France",
      },
      question: {
        IDENTITE: "What type of ID document do you have?",
        JUSTIFICATIF: "What type of ID document do you have?",
        OTHER: "",
      },
      "list-label": {
        IDENTITE: "ID documents",
        JUSTIFICATIF: "Documentary proof",
        OTHER: "Other documents",
      },
    },
    "your-progress": {
      title: "Your progress",
    },
    quiz: {
      title: "Recruitment Quizz (optional)",
      description:
        "This recruitment quiz will test your knowledge about childcare.",
      answer: "I answer the quizz",
      done: "You have already taken the quizz.",
      opened: "You have started filling in the quiz, please refresh your page.",
    },
    engagement: {
      title: "Chart of Commitment",
      description:
        "The Chart of Commitment lists the elements to respect when you start working with a family. It is mandatory that you read it.",
      read: "I read the Chart of Commitment",
      "see-engagement": "See Chart of Commitment",
      "modal-title": "Chart of Commitment",
      check: "I have read the Chart",
      validate: "I confirm",
      "has-read": "You have read the Chart of Commitment",
      text: {
        intro: "By becoming a Mômji nanny, I agree to: ",
        1: "1) <bold>Attend the interview</bold> with the family if my profile is selected",
        2: "2) <bold>To be punctual</bold> and <bold>always warn in case of delay</bold>",
        3: "3) <bold>Not to use my cell phone during the childcare</bold> (except in case of emergency or to scan the QR code, present at the family's home, giving me access to the Mômji activities of the month)",
        4: "4) <bold>Not to be absent</bold>(except for illness or exams for which a receipt is required) and <bold>declare all hours worked with the family</bold> in the online schedule",
        5: "5) <bold>To ensure the safety of the children</bold> at home and when traveling, this includes:",

        6: "6) <bold>Respecting the health and well-being of the children</bold>, including:",

        7: "7) <bold>Take care of the material present in the family's home</bold> and leave the place in the same state of order and cleanliness as it were when I arrived",
        8: "<p>8) <bold>To do the activities provided by scanning the QR code magnet</bold> received at the family's home each month or by consulting my emails. <bold>In addition to the Mômji activities, I must</bold> (depending on the childcare service I provide):</p><p><bold>For Creative Childcare: Use the Pandacraft kit</bold>, received by the family each month</p><p><bold>For Initiation Childcare: Use the Mômji activity kit and do 30 minutes of activities in English per session</bold></p><p><bold>For Immersion Childcare: Use the Mômji activity kit and speak only the target language chosen by the parents</bold></p>",
        9: "9) <bold>Be Mômji Ambassador, respecting its values</bold>: caring, respect, fulfillment, inclusiveness, openness to others and to other cultures.",
        lists: {
          1: "not leaving the children unattended",
          2: "not leaving the care of the children to a third party",
          3: "not introducing a third party into the family home (without direct consent of the parent(s))",
          4: "not smoking in front of the children",
          5: "not using offensive and/or vulgar language",
          6: "take the necessary measures in case of accident of the child <bold>(call 15 for the SAMU, 18 for the fire department ou le or 112 for all emergencies)</bold>) and inform immediately the parents",
        },
        confirm:
          "I confirm that I have read this Chart and agree to respect it.",
      },
    },
  },

  travelTime: {
    startingPointQuestion: "Address",
    enterStartingPoint:
      "Enter the address from which you will leave in order to calculate the approximate travel time",
    duration: "Duration",
    distance: "Distance",
    seeDirection: "See the directions",
  },

  "week-days": {
    title: "Day(s) of the week",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },

  "daytime-periods": {
    "all-day": "All day",
    1: "Oh - 12h",
    2: "12h - 16h",
    3: "16h - 19h",
    4: "19h - 24h",
  },
  "daytime-start-periods": {
    1: "Oh",
    2: "12h",
    3: "16h",
    4: "19h",
  },
  entities: {
    department: {
      list: "Departements",
      edit: "Edit the department",
      add: "Add a department",
    },
    api: {
      list: "APIs",
      edit: "Edit the API",
      add: "Add an API",
    },
    "role-type": {
      list: "Type of role",
      edit: "Edit the type of role",
      add: "Add a type of role",
    },
    action: {
      list: "Actions",
      edit: "Edit the action",
      add: "Add an action",
    },
    route: {
      list: "Routes",
      edit: "Edit the route",
      add: "Add a route",
    },
    role: {
      list: "Roles",
      edit: "Edit a role",
      add: "Add a role",
      routes: {
        title: "Associated routes",
        subtitle: "Select the routes you wish to associate to a role",
      },
    },
    user: {
      list: "Users",
      edit: "Edit a user",
      add: "Add a user",
    },
  },

  admin: {
    nav: "Administration EN",
  },

  dashboard: {
    welcome: "Welcome to Mômji!",
    "your-todo": " Your tasks",
    "generic-message": "",
    SEARCHJOB: {
      title: "Our job offers",
      description:
        "Take a look at our offers in Immersion, Initiation and Creative childcare and use the filters to adjust your search",
      action: "I take a look at the offers",
    },
    MYJOBS: {
      title: "My offers",
      description:
        "Follow your applications, your favorites and the Mômji’s suggestions",
      action: "I follow the progress on my offers",
    },
    PROFIL: {
      title: "My profile",
      description:
        "Fill in your ID information as well as your work experiences",
      action: "I fill in my information",
    },
    CONTACT: {
      title: "Contact Mômji",
      description: "",
      action: "I get in touch with Mômji",
    },
    PARCOURS: {
      title: "My Progress ",
      description:
        "Read about your progress in our process to know the following steps",
      action: "I take a look at my progress",
    },
    PLANNING: {
      title: "My schedule",
      description:
        "Take a look at your schedule to get information on upcoming missions",
      action: "I take a look at my planning",
    },
    CONTRACTS: {
      title: "My contracts",
      description: "Take a look at your past, current or pending contracts",
      action: "I take a look at my contracts",
    },
    BULLETINS: {
      title: "My pay slips",
      description: "Take a look and download your pay slips",
      action: "I take a look at my pay slips",
    },
  },

  // Navigation (cf: dashboardItems.js)
  "permissions-api": {
    nav: "Permissions",
    departments: "Departements",
    apis: "APIs",
    roleTypes: "Role types",
    actions: "Actions",
    routes: "Routes",
    roles: "Roles",
    users: "Users",
  },
  users: {
    nav: "Utilisateurs",
    profile: "Profil",
  },

  validators: {
    wrongFormat: "Wrong format",
  },

  contract: {
    "no-result": "No contract",
    "no-result-description":
      "As soon as a family and you will have decided to work together, your contract will appear here",
    "begins-on": "Starts on",
    "ends-on": "Ends on",
    "linked-to-offer": "Linked to the offer n°",
    "offer-num": "Offer n°",
    status: {
      title: "Statut",
      over: "Completed",
      in_sign: "To sign",
      active: "In progress",
      to_sign: "To sign",
    },
    section: {
      contracts: "Contracts",
      due: "DUE",
      healthcare: "Mutual Healthcare",
    },
    "family-info": "The family’s contact details",
    email: "Email address:",
    phone: "Phone number:",
    address: "Address:",
    action: {
      file: "See my contract",
      sign: "I sign my contract",
    },
    healthcare: {
      "momji-obligation":
        "Mômji is legally obliged to set up a health mutual insurance (complementary healthcare) for its employees.",
      "your-obligation":
        "You must follow this procedure whether or not you wish to join the health mutual insurance company.",
      title: "Health mutual insurance for",
      refused: "You have decided not to join the health mutual insurance.",
      accepted: "You have decided to join the health mutual insurance .",
      link: "See the table of benefits of the health mutual insurance ",
      fee: "If you subscribe to the health mutual insurance, you will be deducted 50% x 20.23€ every month, regardless of the number of hours you have worked.",
      resiliation:
        "If you join, you will not be able to cancel your membership for 1 year or for the duration of your contract if it is less than 1 year.",
      inflation:
        "For your information, the monthly fee may increase each year. Mômji cannot influence this price evolution",
      information:
        "Reminder: to subscribe to the health mutual insurance, you must first be registered with the Caisse Primaire d'Assurance Maladie (CPAM) and have a French social security number. To obtain this number, you must submit a complete file to your CPAM. It takes between 4 and 8 months to obtain the number.",
      "yes-choice": "I wish to join the health mutual insurance",
      "no-choice": "I do not wish to join the health mutuel insurance",
      validate: "Confirm my choice",
      "you-accepted":
        "You have chosen to join the health mutual insurance. To finalize your membership, please download and complete: ",
      "form-link": "The membership form",
      "send-us":
        "Then, send us the membership form as well as your certificate of entitlement to social security and your French bank details, to the address:",
      "when-complete":
        "When your file will be completed, you will receive from the health mutual insurance: ",
      card: "• your mutual insurance card",
      "web-access": "• your access to https://secure.aggema.com",
      "will-end":
        "Your membership to the health mutual insurance will end when your contract with the family will be completed",
      "more-info":
        "For more information, you can contact the health mutual insurance directly at this email address gestionsante@aggema.fr or by phone at +33 01 41 90 98 58",
      "action-button": {
        choose: "Make my choice",
        sign: "Sign my statement",
        complete: "Complete my file",
      },
      "see-document": "See the document",
      "waranties-link":
        "See the table of benefits of the health mutual insurance",
      "accept-title": "Membership",
      "refuse-title": "Rejection of membership",
      "complete-title": "Complete my file",
    },
    due: {
      description:
        "The single declaration of employment (DUE) is a document provided by Mômji when you are hired as an employee. It allows you to fill in several declarative obligations linked to the hiring process in one go.",
      employer: "Employer",
      "begins-on": "Starts on",
      "see-action": "See my DUE (single declaration of employment)",
    },
    "date-change": {
      action: "Change my departure date",
      title: "Change my departure date",
      reason: "For what reason would you like to change your departure date?",
      "available-until": "I am now available until :",
      validate: "Confirm the change",
    },
  },

  payslips: {
    payslips: {
      description:
        "To receive your salary, you must have a European bank account.",
      "see-rib": "See my bank statement",
      "enter-rib": "Fill in my bank statement",
    },
    rib: {
      title: "Bank Account Statement",
      description:
        "To receive your salary, you must have a European bank account",
      "no-file": "Missing file",
      "file-uploaded": "A file has already been provided",
      "your-upload": "Your file",
      name: "Last name and First name",
      domiciliation: "Bank address",
      bank: "Bank",
      iban: "Iban",
      bic: "BIC/SWIFT",
      edit: "Edit",
      cancel: "Cancel",
      save: "Save",
    },
  },

  mandatory: {
    disponibility: {
      title: "Availability",
      description: "Last time we checked, you were available until",
      "description-no-date":
        "Last time we checked, you were available for an indefinite period of time",
      question: "Is this still the case?",
      yes: "Yes",
      no: "No, I am available until",
      "no-date": "No, I don’t know until when I’ll be available",
      validate: "Confirm",
    },

    "contract-disponibility": {
      title: "My contracts",
      description:
        "Please find below the list of your current jobs. If the date of one of your missions has changed, you must inform us as quickly as possible",
      "start-on": "Starts on",
      "end-on": "Ends on",
      "my-end-date": "My expected departure date: ",
      "choice-saved": "Your answer has been taken into account",
      "change-date": "Change my departure date",
      "confirm-date": "Keep my current departure date",
    },
  },

  planning: {
    locked: "Planning is locked between the 2nd and 9th of each month",
    actions: {
      button: "Actions",
      coupon: "Enter a coupon",
      intervention: "Enter a childcare session",
    },

    coupon: {
      title: "Enter a coupon",
      date: "Date of the service",
      time: "Start time of the service",
      hour: "Hour",
      minute: "Minute",
      code: "Code (8 characters)",
      submit: "Validate this coupon",
    },

    intervention: {
      title: "Enter a childcare session",
      date: "Date of the childcare session",
      "start-time": "Start time of the childcare session",
      "end-time": "End time of the childcare session",
      hour: "Hour",
      minute: "Minute",
      family: "Family",
      validate: "Validate this information",
      submit: "Validate this childcare session",
      "no-contract":
        "You do not have a contract that covers this date of childcare session.",
      "one-contract": "Your service will be associated with this contract.",
      "several-contract":
        "We have found several contracts that cover this date of childcare session, please select the one that applies.",
    },

    detail: {
      type: "Type :",
      title: "Prestation detail",
      date: "Session date :",
      start: "Start time :",
      end: "End time :",
      status: {
        head: "Status :",
        future: "To do",
        done: "Done",
        deleted: "Deleted",
        cancelled: "Cancelled",
      },
      prestation: {
        ACT_GP: "Creative childcare",
        ACT_GPAR: "Shared creative childcare",
        ACT_GV: "Creative childcare",
        AG: "Language course",
        AI: "Language course",
        AP: "Language course",
        C: "Language course",
        CG: "Language course",
        CI: "Language course",
        EPL: "Language course",
        G: "Garde",
        Immersion_garde_part: "Shared immersion childcare",
        Immersion_garde_péri: "Immersion childcare",
        Immersion_garde_vaca: "Immersion childcare",
        Initiation_garde_par: "Shared initiation childcare",
        Initiation_garde_pér: "Initiation childcare",
        Initiation_garde_vac: "Initiation childcare",
        KG: "Language course",
        KMI: "Language course",
        KPI: "Language course",
        M: "Housekeeping Ironing",
        ME: "Housekeeping Ironing ",
        Mission: "Mission ",
        MR: "Housekeeping / Ironing",
        Placement: "Placement ",
        RE: "Ironing ",
        Service: "Service ",
        SHK_GP: "Immersion childcare",
        SHK_GPAR: "Shared immersion childcare",
        T: "Talent ",
        TCI: "Language course",
        TG: "Language course",
        TLI: "Language course",
      },
    },
  },

  "refer-a-friend": {
    description: {
      1: {
        bold: "Help your friends find a job with Mômji",
        normal: " and",
      },
      2: {
        bold: " win €100",
        normal: " in Amazon gift card!",
      },
      3: {
        normal:
          "Your fund will unlock as soon as your friend will have completed 30 hours of childcare with a family!",
      },
    },
    share:
      "Share your referral link and invite your friends to apply by clicking on it:",
    "copy-link": "I copy my link",
  },
  messages: {
    PREP_ENT: {
      title: "Prepare your Mômji interview",
      text: "You have a scheduled interview on the {{ date }}. To increase your chances of being selected, watch this video:",
      validate: "I watched the video",
      link: "",
    },
    PREP_MEET: {
      title: "Prepare your (first) meeting with the family",
      text: "You will soon meet a family. To increase your chances of being selected, watch this video:",
      validate: "I watched the video",
      link: "",
    },
  },
  resources: {
    activities: {
      title: "My activities of the month",
      text: {
        1: "Mômji accompanies you every day thanks to its pedagogy!",
        2: "<bold>Each month, introduce a new country to the children</bold> you look after, <bold>thanks to 4 Mômji activities.</bold>",
        3: "To discover them, <bold>visit this page at any time</bold> or <bold>scan the QR code magnet that the family received at home!</bold>",
      },
      button: "I find the activities",
    },
    "good-deals": {
      title: "My deals and promotions",
      text: {
        1: "As a Mômji nanny, <bold>you benefit from good deals and promotions</bold> that we have negotiated with many partners!",
        2: "Throughout the year, <bold>you'll find coupon codes and tips to find an accommodation, get your driver's license, learn French, entertain</bold> and even more...",
      },
      button: "I discover",
    },
    training: {
      main: {
        title: "My Mômji training",
        text: {
          1: "As a nanny, your mission is to implement the Mômji pedagogy on a daily basis, according to the service you provide.",
          2: "<bold>The following materials and videos are part of your training</bold> to allow children to discover a language, a culture, a passion and share enriching moments together!",
          3: "Take the time to watch them, before you start your contract!",
        },
      },
      creative: {
        title: "If you are a Creative childcare nanny",
        text: "As a Mômji Creative nanny, your mission is to accompany the development of children through Mômji's pedagogy and activities.",
        section: {
          title: "Creative childcare  training",
          "good-start": "How to start my contract with the family ?",
          safety: "I learn all about child safety",
          authority: "How to find the right authority with the children?",
          activities: "Ideas for activities I can do with the children",
          videos: {
            creative: "What’s the Mômji Creative Childcare?",
            activities: "The activities",
            pedagogy: "The Mômji pedagogy",
          },
        },
      },
      initiation: {
        title: "If you are an Initiation childcare nanny ",
        text: "As a Mômji Bilingual nanny in Initiation, your mission is to provide an introduction to English for a minimum of 30 minutes at each childcare session.",
        section: {
          title: "Initiation childcare training",
          "good-start": "How to start my contract with the family ?",
          safety: "I learn all about child safety",
          authority: "How to find the right authority with the children?",
          activities: "Ideas for activities I can do with the children",
          teach: "How to teach a new language to children?",
          videos: {
            pedagogy: "The Mômji bilingual pedagogy",
            behaviour: "The attitude to have in Initiation",
            vocabulary: "The new vocabulary",
            organization: "Your organization",
            adaptability: "Be flexible",
          },
        },
      },
      immersion: {
        title: "If you are an Immersion childcare nanny",
        text: "As a Mômji Bilingual Immersion nanny, your mission is to provide a 100% childcare in the language chosen by the parents.",
        section: {
          title: "Immersion childcare training",
          "good-start": "How to start my contract with the family ?",
          safety: "I learn all about child safety",
          authority: "How to find the right authority with the children?",
          activities: "Ideas for activities I can do with the children",
          teach: "How to teach a new language to children?",
          videos: {
            pedagogy: "The Mômji bilingual pedagogy",
            approach: "An implicit approach",
            resort: "French uses",
            reassure: "Reassure the child",
            rituals: "Set up rituals",
          },
        },
      },
      button: "I discover",
      videos: "Videos",
      documents: "PDF",
      download: "Download",
    },
  },
};

export { translations };

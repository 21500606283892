import React, { createContext, useEffect, useReducer } from "react";

import axios from "axios";
import { apiConfig } from "../config.js";

import { LOCALSTORAGE_ITEM_KEYS } from "../constants.js";

const INITIALIZE = "INITIALIZE";
const SET_API_TOKENS = "SET_API_TOKENS";
const DELETE_API_TOKENS = "DELETE_API_TOKENS";

const initialState = {
  isInitialized: false,
  tokens: null,
};

const XSRFReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isInitialized: true,
        tokens: action.payload.tokens,
      };
    case SET_API_TOKENS:
      return {
        ...state,
        tokens: action.payload.tokens,
      };
    case DELETE_API_TOKENS:
      return {
        ...state,
        tokens: null,
      };

    default:
      return state;
  }
};

const XSRFContext = createContext(initialState);

/**
 * XSRF provider
 */
function XSRFProvider({ children }) {
  const [state, dispatch] = useReducer(XSRFReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const tokens = window.localStorage.getItem(LOCALSTORAGE_ITEM_KEYS.XSRF);

        if (tokens) {
          dispatch({
            type: INITIALIZE,
            payload: {
              tokens: JSON.parse(tokens),
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              tokens: null,
            },
          });
        }
      } catch (error) {
        console.error("[XSRFProvider] initialize", error);
        dispatch({
          type: INITIALIZE,
          payload: {
            tokens: null,
          },
        });
      }
    };

    initialize();
  }, []);

  /**
   * Set Api Tokens
   * @returns void
   */
  const setApiTokens = async () => {
    const tokens = {};

    for (const key in apiConfig) {
      const api = apiConfig[key];
      // console.log("[XSRFProvider] setApiTokens - api:", api);

      if (api.XSRFToken) {
        try {
          const response = await axios.get(api.XSRFToken);
          tokens[api.code] = response.data["csrf-token"];
        } catch (error) {
          console.error("[XSRFProvider] setApiTokens", error);
        }
      }
    }

    // store to localStorage
    window.localStorage.setItem(
      LOCALSTORAGE_ITEM_KEYS.XSRF,
      JSON.stringify(tokens)
    );

    dispatch({
      type: SET_API_TOKENS,
      payload: {
        tokens,
      },
    });
  };

  /**
   * Return Api Token
   * @param {String} apiKey
   * @returns void
   */
  const getApiToken = (apiKey) => {
    // console.info("[XSRFProvider] getApiToken", apiKey);
    try {
      let tokens = window.localStorage.getItem(LOCALSTORAGE_ITEM_KEYS.XSRF);

      if (!tokens) {
        throw new Error("No stored xsrfTokens ...");
      }
      tokens = JSON.parse(tokens);
      return typeof tokens[apiKey] !== "undefined" ? tokens[apiKey] : null;
    } catch (error) {
      console.error("[XSRFProvider] getApiToken", error);
    }
  };

  /**
   * Remove Api Tokens
   * @returns void
   */
  const removeApiTokens = async () => {
    // remove tokens from localStorage
    window.localStorage.removeItem(LOCALSTORAGE_ITEM_KEYS.XSRF);
    dispatch({ type: DELETE_API_TOKENS });
  };

  return (
    <XSRFContext.Provider
      value={{
        ...state,
        method: "xsrf",
        setApiTokens,
        removeApiTokens,
        getApiToken,
      }}
    >
      {children}
    </XSRFContext.Provider>
  );
}

export { XSRFContext, XSRFProvider };
